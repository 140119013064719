import React from 'react';
import { Spin } from 'antd';
import './LoadingScreen.css'; // CSS externo para estilizar o loading

const LoadingScreen = () => {
  return (
    <div className="loading-container">
      <Spin size="large" tip="Carregando..." />
    </div>
  );
};

export default LoadingScreen;
