// PlayerRankingScreen.jsx
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Card,
  Table,
  Typography,
  message,
  Avatar,
  Spin,
  Button,
  Menu,
  Drawer,
  Select,
  Row,
  Col,
} from 'antd';
import {
  CarOutlined,
  ShoppingFilled,
  ShopOutlined,
  UserOutlined,
  CrownOutlined,
  DollarOutlined,
  GoldOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import moment from 'moment';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const Rank2 = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [filters, setFilters] = useState({
    level: 'All',
    group: 'All',
    pack: 'All',
    gold: 'All',
    carsBought: 'All',
    balance: 'All',
  });
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlayersData = async () => {
      const user = auth.currentUser;
      if (user) {
        setCurrentUserId(user.uid);
        try {
          const playersCollectionRef = collection(firestore, 'players');
          const playersSnapshot = await getDocs(playersCollectionRef);

          if (playersSnapshot.empty) {
            console.log('Nenhum jogador encontrado na coleção "players".');
            message.info('Nenhum jogador encontrado.');
            setPlayers([]);
          } else {
            const playersData = playersSnapshot.docs.map((doc) => {
              const data = doc.data();
              return {
                key: doc.id,
                id: doc.id,
                email: data.email || 'Sem Email',
                level: data.level ?? 'N/A',
                totalProfit: data.metrics?.totalProfit ?? 'N/A',
                totalRevenue: data.metrics?.totalRevenue ?? 'N/A',
                totalSales: data.metrics?.totalSales ?? 'N/A',
                balance: data.balance ?? 'N/A',
                carsBought: data.metrics?.carrosComprados ?? 'N/A',
                gold: data.gold ?? 'N/A',
                createdAt:
                  data.createdAt instanceof Date
                    ? data.createdAt
                    : data.createdAt
                    ? moment(data.createdAt).toDate()
                    : 'N/A',
                group: data.group ?? 'N/A',
                pack: data.pack !== undefined ? (data.pack ? 'Sim' : 'Não') : 'N/A',
                avatarUrl: data.avatarUrl || null,
              };
            });

            console.log('Dados dos jogadores:', playersData);
            setPlayers(playersData);
          }
        } catch (error) {
          message.error('Erro ao carregar os dados dos jogadores.');
          console.error('Erro ao carregar os dados dos jogadores:', error);
        } finally {
          setLoading(false);
        }
      } else {
        message.error('Usuário não autenticado.');
        console.log('Nenhum usuário autenticado.');
        setLoading(false);
      }
    };

    fetchPlayersData();
  }, [auth]);

  // Função para gerenciar a mudança de filtros
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      level: 'All',
      group: 'All',
      pack: 'All',
      gold: 'All',
      carsBought: 'All',
      balance: 'All',
      [field]: value,
    }));
  };

  // Aplicar o filtro ativo
  const filteredPlayers = players.filter((player) => {
    const { level, group, pack, gold, carsBought, balance } = filters;

    if (level !== 'All') {
      return player.level === Number(level);
    }

    if (group !== 'All') {
      return player.group === Number(group);
    }

    if (pack !== 'All') {
      return pack === 'Sim' ? player.pack === 'Sim' : player.pack === 'Não';
    }

    if (gold !== 'All') {
      return player.gold === Number(gold);
    }

    if (carsBought !== 'All') {
      return player.carsBought === Number(carsBought);
    }

    if (balance !== 'All') {
      return player.balance === Number(balance);
    }

    return true; // Se nenhum filtro estiver ativo
  });

  const columns = [
    {
      title: 'Nível',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 100,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={record.avatarUrl}
            icon={!record.avatarUrl && <UserOutlined />}
            style={{ marginRight: '10px' }}
          />
          {record.group === '2' && <CrownOutlined style={{ color: '#fadb14', marginRight: '5px' }} />}
          <Text strong>{text}</Text>
        </div>
      ),
    },
    {
      title: 'Lucro Total',
      dataIndex: 'totalProfit',
      key: 'totalProfit',
      align: 'right',
      width: 200,
      render: (value) =>
        value !== 'N/A' ? (
          <span>
            <DollarOutlined style={{ color: '#52c41a', marginRight: '4px' }} />
            {Number(value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      title: 'Receita Total',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      align: 'right',
      width: 200,
      render: (value) =>
        value !== 'N/A' ? (
          <span>
            <DollarOutlined style={{ color: '#1890ff', marginRight: '4px' }} />
            {Number(value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      title: 'Vendas Totais',
      dataIndex: 'totalSales',
      key: 'totalSales',
      align: 'right',
      width: 150,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      align: 'right',
      width: 200,
      render: (value) =>
        value !== 'N/A' ? (
          <span>
            <DollarOutlined style={{ color: '#faad14', marginRight: '4px' }} />
            {Number(value).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      title: 'Carros Comprados',
      dataIndex: 'carsBought',
      key: 'carsBought',
      align: 'right',
      width: 150,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Ouro',
      dataIndex: 'gold',
      key: 'gold',
      align: 'right',
      width: 100,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 180,
      render: (value) =>
        value !== 'N/A' ? (
          <Text>{moment(value).format('DD/MM/YYYY HH:mm')}</Text>
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      title: 'Grupo',
      dataIndex: 'group',
      key: 'group',
      align: 'center',
      width: 100,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: 'Pacote',
      dataIndex: 'pack',
      key: 'pack',
      align: 'center',
      width: 150,
      render: (value) => <Text>{value}</Text>,
    },
  ];

  // Função para lidar com o logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logout bem-sucedido');
        navigate('/auth'); // Redireciona para a página de login
      })
      .catch((error) => {
        console.error('Erro ao sair:', error);
        message.error('Erro ao realizar logout.');
      });
  };

  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '23px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '23px' }} />} title="Showroom">
        <Link to="/showroom">Showroom</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '23px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '23px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '23px' }} />} title="Ranking">
        <Link to="/ranking">Ranking</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<ShoppingFilled style={{ fontSize: '23px' }} />} title="Loja">
        <Link to="/shop">Loja</Link>
      </Menu.Item>
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Header */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <Title level={3} style={{ color: '#fff', margin: 0 }}>
          Análise de Jogadores
        </Title>

        {/* Menu de Navegação */}
        <Button
          type="text"
          icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
          onClick={() => setIsDrawerVisible(true)}
          className="drawer-toggle-button"
        />
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">{renderMenuItems()}</Menu>
      </Drawer>

      {/* Content */}
      <Content
        style={{
          padding: '24px',
          backgroundColor: '#f0f2f5',
          flex: '1 0 auto',
        }}
      >
        <Card
          title={<Title level={3}>Tabela de Análise de Jogadores</Title>}
          style={{
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
          }}
        >
          {/* Seção de Filtros */}
          <Row gutter={[16, 16]} style={{ marginBottom: '16px' }}>
            {/* Filtro para Nível */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text style={{ marginBottom: '8px', display: 'block' }}>Nível</Text>
              <Select
                value={filters.level}
                onChange={(value) => handleFilterChange('level', value)}
                style={{ width: '100%' }}
              >
                <Option value="All">Todos</Option>
                {/* Substitua os valores abaixo pelos níveis reais existentes */}
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </Col>

            {/* Filtro para Grupo */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text style={{ marginBottom: '8px', display: 'block' }}>Grupo</Text>
              <Select
                value={filters.group}
                onChange={(value) => handleFilterChange('group', value)}
                style={{ width: '100%' }}
              >
                <Option value="All">Todos</Option>
                {/* Substitua os valores abaixo pelos grupos reais existentes */}
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </Col>

            {/* Filtro para Pacote */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text style={{ marginBottom: '8px', display: 'block' }}>Pacote</Text>
              <Select
                value={filters.pack}
                onChange={(value) => handleFilterChange('pack', value)}
                style={{ width: '100%' }}
              >
                <Option value="All">Todos</Option>
                <Option value="Sim">Sim</Option>
                <Option value="Não">Não</Option>
              </Select>
            </Col>

            {/* Filtro para Ouro */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text style={{ marginBottom: '8px', display: 'block' }}>Ouro</Text>
              <Select
                value={filters.gold}
                onChange={(value) => handleFilterChange('gold', value)}
                style={{ width: '100%' }}
              >
                <Option value="All">Todos</Option>
                {/* Substitua os valores abaixo pelos níveis reais de ouro */}
                <Option value="100">100</Option>
                <Option value="200">200</Option>
                <Option value="300">300</Option>
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </Col>

            {/* Filtro para Carros Comprados */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text style={{ marginBottom: '8px', display: 'block' }}>Carros Comprados</Text>
              <Select
                value={filters.carsBought}
                onChange={(value) => handleFilterChange('carsBought', value)}
                style={{ width: '100%' }}
              >
                <Option value="All">Todos</Option>
                {/* Substitua os valores abaixo pelos números reais de carros comprados */}
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </Col>

            {/* Filtro para Saldo */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Text style={{ marginBottom: '8px', display: 'block' }}>Saldo</Text>
              <Select
                value={filters.balance}
                onChange={(value) => handleFilterChange('balance', value)}
                style={{ width: '100%' }}
              >
                <Option value="All">Todos</Option>
                {/* Substitua os valores abaixo pelos níveis reais de saldo */}
                <Option value="1000000">1.000.000</Option>
                <Option value="2000000">2.000.000</Option>
                <Option value="3000000">3.000.000</Option>
                {/* Adicione mais opções conforme necessário */}
              </Select>
            </Col>
          </Row>

          {loading ? (
            <div style={{ textAlign: 'center', padding: '50px 0' }}>
              <Spin size="large" tip="Carregando Dados..." />
            </div>
          ) : (
            <Table
              dataSource={filteredPlayers}
              columns={columns}
              pagination={{ pageSize: 10 }}
              rowClassName={(record) =>
                record.id === currentUserId ? 'highlight-row' : ''
              }
              bordered
              scroll={{ x: 'max-content' }}
              locale={{
                emptyText: 'Nenhum dado disponível.',
              }}
              style={{ backgroundColor: '#ffffff' }}
            />
          )}
        </Card>
      </Content>

      {/* Custom Styles */}
      <style jsx="true">{`
        .highlight-row {
          background-color: #e6f7ff !important;
        }
      `}</style>
    </Layout>
  );
};

export default Rank2;
