import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Carousel,
  message,
  Menu,
  Drawer,
  Grid,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import ThinCard from './ThinCard';
import {
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
  writeBatch,
  query,
  limit,
  startAfter,
  orderBy,
} from 'firebase/firestore';
import {
  AppstoreOutlined,
  CarOutlined,
  ShoppingFilled,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  CompassOutlined,
  NotificationOutlined,
  FundOutlined,
  StarOutlined,
  LogoutOutlined,
  DollarOutlined,
  CrownOutlined,
  ShoppingOutlined,
  GoldOutlined,
  MenuOutlined,
  RocketOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { getAuth, signOut } from 'firebase/auth';
import VipBanner2 from './VipBanner2';

const { Header, Content } = Layout;
const { Title, Text, Paragraph, Link: TypographyLink } = Typography; // Desestruturando Paragraph e renomeando Link
const { useBreakpoint } = Grid;

const ColecaoPessoal = () => {
  const navigate = useNavigate();
  const [cars, setCars] = useState([]);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [lastVisible, setLastVisible] = useState(null); // Para paginação
  const [hasMore, setHasMore] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(
    'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/IconWhite.png?alt=media&token=f950af84-1644-4748-b894-09c2c8ce3ddb'
  );
  const auth = getAuth();
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(false);

  const screens = useBreakpoint(); // Hook para detectar o tamanho da tela

  // Estado e funções para o Drawer (menu mobile)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const showDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  // Função para renderizar os itens do menu
  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      
      
      <Menu.Item
        key="10"
        icon={<LogoutOutlined style={{ fontSize: '23px' }} />}
        onClick={() => {
          signOut(auth)
            .then(() => {
              console.log('Logout bem-sucedido');
              navigate('/auth'); // Redireciona para a página de login
            })
            .catch((error) => {
              console.error('Erro ao sair:', error);
            });
        }}
      >
        <span>Logout</span>
      </Menu.Item>
    </>
  );

  const fetchPlayerData = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerDoc = await getDoc(doc(firestore, 'players', user.uid));
        if (playerDoc.exists()) {
          const data = playerDoc.data();
          setPlayerData(data);
          if (data.avatar) {
            setSelectedAvatar(data.avatar);
          }
        }
      } else {
        console.error('Usuário não autenticado');
        navigate('/auth');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
    }
  }, [auth, navigate]);

  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  const fetchCars = useCallback(
    async (isInitialLoad = false) => {
      try {
        setLoading(true);
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado');
          return;
        }

        const playerId = user.uid;
        const carsRef = collection(firestore, 'players', playerId, 'colecaopessoal');

        let carsQuery = query(carsRef, orderBy('modelo'), limit(20));

        if (!isInitialLoad && lastVisible) {
          carsQuery = query(carsRef, orderBy('modelo'), startAfter(lastVisible), limit(20));
        }

        const carsSnapshot = await getDocs(carsQuery);
        const carsList = carsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (isInitialLoad) {
          setCars(carsList);
        } else {
          setCars((prevCars) => [...prevCars, ...carsList]);
        }

        if (carsSnapshot.docs.length < 20) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setLastVisible(carsSnapshot.docs[carsSnapshot.docs.length - 1]);
        }
      } catch (error) {
        console.error('Erro ao buscar carros:', error);
      } finally {
        setLoading(false);
      }
    },
    [auth, lastVisible]
  );

  useEffect(() => {
    fetchCars(true);
  }, [fetchCars]);

  const handleLoadMore = () => {
    if (hasMore) {
      fetchCars();
    }
  };

  const handleCarClick = (car) => {
    setSelectedCar(car);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedCar(null);
  };

  const handleAddToShowroom = async () => {
    if (!selectedCar) return;

    try {
      const user = auth.currentUser;

      if (!user) {
        message.error('Usuário não autenticado');
        return;
      }

      const playerId = user.uid;
      const carId = selectedCar.carID;

      const personalCollectionRef = doc(firestore, 'players', playerId, 'colecaopessoal', carId);
      const showroomRef = doc(firestore, 'players', playerId, 'showroom', carId);

      const carSnapshot = await getDoc(personalCollectionRef);

      if (carSnapshot.exists()) {
        const carData = carSnapshot.data();

        const batch = writeBatch(firestore);

        batch.set(showroomRef, carData);
        batch.delete(personalCollectionRef);

        await batch.commit();

        message.success('Carro movido para o Showroom com sucesso!');
        closeModal();

        setCars((prevCars) => prevCars.filter((car) => car.carID !== carId));
      } else {
        message.error('Carro não encontrado na coleção pessoal.');
      }
    } catch (error) {
      console.error('Erro ao mover carro para o Showroom:', error);
      message.error('Erro ao mover carro para o Showroom.');
    }
  };

  const openAvatarModal = () => {
    setAvatarModalVisible(true);
  };

  const closeAvatarModal = () => {
    setAvatarModalVisible(false);
  };

  const selectAvatar = async (avatarUrl) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado.');
        return;
      }

      setSelectedAvatar(avatarUrl);

      const playerDocRef = doc(firestore, 'players', user.uid);
      await setDoc(playerDocRef, { avatar: avatarUrl }, { merge: true });

      closeAvatarModal();
      message.success('Avatar atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar o avatar:', error);
      message.error('Erro ao atualizar o avatar.');
    }
  };

  // Funções para controlar a visibilidade do modal de ajuda
  const showHelpModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleHelpModalOk = () => {
    setIsHelpModalVisible(false);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalVisible(false);
  };

  // Estado e funções para o modal de adicionar carro real
  const [isAddRealCarModalVisible, setIsAddRealCarModalVisible] = useState(false);

  const showAddRealCarModal = () => {
    setIsAddRealCarModalVisible(true);
  };

  const handleAddRealCarModalClose = () => {
    setIsAddRealCarModalVisible(false);
  };

  const pixKey = 'kevnmotors@gmail.com'; // Substitua pela sua chave PIX
  const whatsappNumber = '5511912192520'; // Número sem o '+' para o link do WhatsApp

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        {/* Seção da Esquerda: Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px' }}
            />
            <Button
  type="text"
  icon={<GoldOutlined style={{ fontSize: '24px', color: '#ffcf40' }} />}
  onClick={() => navigate('/shop')} // Navega para a tela loja
  title="Upgrade"
  style={{ color: '#fff', marginLeft: '16px' }} // Estilo para o texto
>
Upgrades
</Button>
          </div>
        </div>

        {/* Menu de Navegação */}
        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px' }}>
            {renderMenuItems()}
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
            className="drawer-toggle-button"
          />
        )}
      </Header>

      {/* Drawer para navegação mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        open={isDrawerVisible} // Atualizado de 'visible' para 'open'
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">
          {/* Itens do menu (mesmos do menu desktop) */}
          <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      
      
          {/* Outros itens do menu */}
          <Menu.Item
            key="10"
            icon={<LogoutOutlined />}
            onClick={() => {
              signOut(auth)
                .then(() => {
                  console.log('Logout bem-sucedido');
                  navigate('/auth'); // Redireciona para a página de login
                })
                .catch((error) => {
                  console.error('Erro ao sair:', error);
                });
            }}
          >
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Drawer>

      {/* Conteúdo Principal */}
      <Layout>
        <div
          style={{
            display: 'flex',
            flexDirection: screens.md ? 'row' : 'column',
            alignItems: 'center',
            marginBottom: '24px',
            paddingLeft: '16px',
            marginTop: '24px',
          }}
        >
          <div
            style={{
              marginRight: screens.md ? '24px' : '0',
              marginBottom: screens.md ? '0' : '16px',
              textAlign: 'center',
            }}
          >
            <img
              src={selectedAvatar}
              alt="User Avatar"
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                border: '4px solid white',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              onClick={openAvatarModal}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: screens.md ? 'left' : 'center',
            }}
          >
            <Title level={3} style={{ margin: 0 }}>
              {playerData?.nameplayer || 'Nome não disponível'}
            </Title>
            <Title level={5} style={{ margin: 0, color: '#b5b5b5' }}>
              {playerData?.email || 'Email não disponível'}
            </Title>
            <Title level={5} style={{ margin: 0, color: '#b5b5b5' }}>
              Clique na foto para trocar seu avatar.
            </Title>
          </div>
        </div>

        {/* Modal de seleção de avatar */}
        <Modal
          open={avatarModalVisible}
          onCancel={closeAvatarModal}
          footer={null}
          title="Escolha um Avatar"
          centered
        >
          <Row gutter={[16, 16]}>
            {[
              // Lista de URLs de avatares
              'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Avatar%2FDALL%C2%B7E%202024-09-17%2008.52.08%20-%20A%20surreal%20and%20exaggerated%20portrait%20of%20a%20person%20obsessed%20with%20cars%2C%20surrounded%20by%20many%20luxury%20vehicles.%20The%20person%20looks%20joyful%20and%20ecstatic%2C%20not%20scary.webp?alt=media&token=556879bd-b433-48f7-9baa-e5fcf51ef306',
              'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Avatar%2FDALL%C2%B7E%202024-09-17%2008.52.12%20-%20A%20surreal%20and%20exaggerated%20portrait%20of%20a%20person%20in%20a%20business%20suit%2C%20with%20an%20intense%20expression%20of%20obsession%20for%20money.%20The%20eyes%20have%20a%20creative%20and%20sur.webp?alt=media&token=988f0c6a-b146-4c20-bfaa-c52518c837b4',
              'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Avatar%2FDALL%C2%B7E%202024-09-17%2008.52.15%20-%20A%20highly%20realistic%204K%20photo%20of%20a%20luxury%20sports%20car%20in%20a%20showroom%2C%20featuring%20a%20sleek%20and%20modern%20design%20with%20metallic%20finishes%2C%20low%20profile%2C%20and%20aerodyn.webp?alt=media&token=e83e99f5-ebeb-4ec8-89f6-3b4e5cb4b918',
              'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Avatar%2FDALL%C2%B7E%202024-09-17%2008.52.17%20-%20A%20highly%20realistic%204K%20photo%20of%20a%20luxury%20sports%20car%20in%20a%20showroom%20setting%2C%20sleek%20and%20modern%20design%20with%20shining%20black%20paint%2C%20sharp%20contours%2C%20and%20high-e.webp?alt=media&token=3cc5fc2d-d12d-46ab-aa8e-8ea12301cf08',
              'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Avatar%2FDALL%C2%B7E%202024-09-17%2008.52.23%20-%20A%20highly%20realistic%204K%20photo%20of%20a%20very%20wealthy%20woman.%20She%20has%20a%20confident%20and%20professional%20look%2C%20wearing%20elegant%20business%20attire.%20The%20background%20is%20a%20s.webp?alt=media&token=efde47eb-88bd-48eb-bbce-546097085229',
              'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Avatar%2FDALL%C2%B7E%202024-09-17%2008.52.26%20-%20A%20highly%20realistic%204K%20photo%20of%20a%20very%20wealthy%20Asian%20businessman%2C%20in%20a%20professional%20setting%20similar%20to%20a%20LinkedIn%20profile%20picture.%20The%20man%20is%20wearing%20a.webp?alt=media&token=a6c84645-6e15-4e2d-89a9-3fceb50d1ef2',
            ].map((avatarUrl, index) => (
              <Col key={index} xs={12} sm={8} md={6} lg={4}>
                <img
                  src={avatarUrl}
                  alt={`Avatar ${index + 1}`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    border:
                      selectedAvatar === avatarUrl ? '4px solid #1890ff' : '4px solid transparent',
                    objectFit: 'cover',
                  }}
                  onClick={() => selectAvatar(avatarUrl)}
                />
              </Col>
            ))}
          </Row>
        </Modal>

        <Row justify="start" style={{ marginLeft: '16px' }}>
          <Col xs={24}>
            <Title level={3} style={{ marginBottom: '16px' }}>
              Meus Carros
            </Title>
            <Text style={{ fontSize: '18px', color: '#999', marginBottom: '15px' }}>
              Conquiste os carros que você sempre quis e monte sua garagem dos sonhos nesse espaço.
              Outros jogadores poderão ver a sua coleção.
            </Text>
          </Col>
        </Row>

        <VipBanner2/>

        <Content style={{ padding: '16px' }}>
          <Row gutter={[16, 16]}>
            {cars.map((car) => (
              <Col
                key={car.id}
                xs={12}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                style={{ textAlign: 'center' }}
              >
                <img
                  alt={`${car.modelo}`}
                  src={car.imagens?.[0] || 'https://via.placeholder.com/150'}
                  style={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '1 / 1',
                    borderRadius: '4px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleCarClick(car)}
                />
              </Col>
            ))}

           
          </Row>

          {hasMore && (
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Button
                type="primary"
                onClick={handleLoadMore}
                loading={loading}
                style={{ borderRadius: '4px' }}
              >
                Carregar Mais
              </Button>
            </Row>
          )}

          {selectedCar && (
            <Modal
              open={modalVisible}
              onCancel={closeModal}
              footer={null}
              width={screens.xs ? '90%' : 800}
              style={{ padding: '24px' }}
              centered
            >
              <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col xs={24} sm={24} md={12}>
                  {selectedCar.imagens && selectedCar.imagens.length > 0 ? (
                    <Carousel>
                      {selectedCar.imagens.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`${selectedCar.marca} ${selectedCar.modelo}`}
                            style={{
                              width: '100%',
                              height: 'auto',
                              borderRadius: '4px',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '300px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography.Text>Imagem Indisponível</Typography.Text>
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Title level={4}>
                    {selectedCar.marca} {selectedCar.modelo}
                  </Title>
                  <Text>Ano: {selectedCar.ano || 'N/A'}</Text>
                  <br />
                  <Text>Km: {selectedCar.km || 'N/A'}</Text>
                  <br />
                  <Text>Potência: {selectedCar.potencia || 'N/A'}</Text>
                  <br />
                  <Text>
                    Valor:{' '}
                    {selectedCar.valor
                      ? `R$ ${selectedCar.valor.toLocaleString('pt-BR')}`
                      : 'Indisponível'}
                  </Text>
                  <br />
                  <br />
                  <Button
                    type="primary"
                    onClick={handleAddToShowroom}
                    style={{ marginRight: '8px' }}
                  >
                    Adicionar ao Showroom
                  </Button>
                  <Button onClick={closeModal}>Cancelar</Button>
                </Col>
              </Row>
            </Modal>
          )}

          {/* Modal para adicionar carro da vida real */}
          <Modal
            title=""
            open={isAddRealCarModalVisible}
            onCancel={handleAddRealCarModalClose}
            footer={[
              <Button key="fechar" type="primary" onClick={handleAddRealCarModalClose}>
                Fechar
              </Button>,
            ]}
            centered
          >
            <Typography>
              <Title level={4}>Como Adicionar seu Carro da Vida Real</Title>
              <Paragraph>
                Para adicionar seu carro da vida real à sua coleção, siga os passos abaixo:
              </Paragraph>
              <ol>
                <li>
                  Realize um pagamento de <strong>R$5</strong> via PIX para a chave:
                  <br />
                  <strong>{pixKey}</strong>
                </li>
                <li>
                  Envie o comprovante do pagamento para o nosso WhatsApp:
                  <br />
                  <TypographyLink
                    href={`https://wa.me/${whatsappNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {whatsappNumber}
                  </TypographyLink>
                </li>
                <li>
                  Envie as informações do carro: Marca, modelo, ano, potência, km e 5 fotos no WhatsApp.
                </li>
              </ol>
              <Paragraph>
                Após a confirmação do pagamento, seu carro será adicionado à sua coleção pessoal.
              </Paragraph>
            </Typography>
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ColecaoPessoal;
