import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image as KonvaImage, Group } from 'react-konva';
import useImage from 'use-image';

// Função para calcular a posição isométrica
const calcularPosicaoIsometrica = (i, j, blockWidth, blockHeight, offsetX, offsetY) => {
  const x = (i - j) * (blockWidth / 2) + offsetX;
  const y = (i + j) * (blockHeight / 4) + offsetY;
  return { x, y };
};

// Componente de Carro
const Carro = ({ imageUrl, x, y }) => {
  const [image] = useImage(imageUrl);
  return (
    <KonvaImage
      image={image}
      x={x}
      y={y}
      width={80} // Ajuste o tamanho conforme necessário
      height={40}
    />
  );
};

const GarageIsometric = () => {
  const stageRef = useRef();
  const [scale, setScale] = useState(1);
  
  // Placeholder para slots e carros
  const blockWidth = 120;
  const blockHeight = 120;
  const offsetX = 200;
  const offsetY = 100;

  const carros = [
    { id: 1, tipo: 'ferrari', imageUrl: 'https://i.imgur.com/8Qp7X4F.png', i: 0, j: 0 },
    { id: 2, tipo: 'lamborghini', imageUrl: 'https://i.imgur.com/6cJbR5Z.png', i: 1, j: 0 },
    { id: 3, tipo: 'porsche', imageUrl: 'https://i.imgur.com/zL4Krbz.png', i: 0, j: 1 },
  ];

  // Função para aplicar zoom in/out
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const scaleBy = 1.05;
    const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
    setScale(newScale);
    stage.scale({ x: newScale, y: newScale });
    stage.batchDraw();
  };

  return (
    <div>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onWheel={handleWheel}
        ref={stageRef}
        scaleX={scale}
        scaleY={scale}
        draggable
      >
        <Layer>
          {carros.map((carro) => {
            const { x, y } = calcularPosicaoIsometrica(carro.i, carro.j, blockWidth, blockHeight, offsetX, offsetY);
            return <Carro key={carro.id} imageUrl={carro.imageUrl} x={x} y={y} />;
          })}
        </Layer>
      </Stage>
    </div>
  );
};

export default GarageIsometric;
