import React from 'react';
import { Layout, Steps, Button, Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Step } = Steps;
const { Title, Text } = Typography;

const tutorialSteps = [
  {
    title: 'Entendendo o menu',
    description:
      '1. Painel | 2. Showroom | 3. Mercado | 4. Banco | 5. Coleção | 6. Ranking | 7. Loja - Você vai entender como cada área influencia sua loja.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20120622.png?alt=media&token=75c0b828-84e2-4a46-81a9-8ba8fcef3f05',
  },
  {
    title: 'Painel',
    description:
      'Mostra o relatório financeiro da sua empresa, além do seu saldo atual perto do seu nome. Fique atento se suas estratégias estão realmente sendo lucrativas.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20113144.png?alt=media&token=097e4008-ac1e-4db9-8210-a1721ebe4d84',
  },
  {
    title: 'Painel',
    description:
      'Você pode turbinar o lucro da sua loja por curtos periodos de tempo e acompanhar o seu progresso até o próximo nível.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20113628.png?alt=media&token=4d2be340-cfd8-47d1-9849-7fe8ebf35ad0',
  },
  {
    title: 'Ouro',
    description:
      'Utilize as barras de ouro para impulsionar o desempenho da sua loja ou comprar ítens especiais.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-21%20123635.png?alt=media&token=c6326d1c-82af-48bb-8c77-2b83ece620c7',
  },

  
  {
    title: 'Showroom',
    description:
      'A tela é dividida entre seu estoque (esquerda) e pedidos de clientes (direita). Suas vagas são limitadas, compre mais para expandir a loja.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20113903.png?alt=media&token=39a5f75a-addd-49ec-bf62-e67de3f6575d',
  },
  {
    title: 'Showroom - Atendendo os clientes',
    description:
      'Já tem o carro do cliente? Selecione o cliente, em seguida selecione o carro e clique em "vender". Você também pode guardar seus carros favoritos na sua COLEÇÃO. ',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20114017.png?alt=media&token=22ba3f6e-c9d9-4c38-afbd-2099e17a03b1',
  },
  {
    title: 'Showroom - Compatibilidade é essencial',
    description:
      'Se o carro oferecido não for compatível a venda não será fechada, fique atento na hora de comprar. Você pode se livrar do carro com a venda rápida mas terá prejuízo.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20114046.png?alt=media&token=710e2d74-7c87-4975-9163-1fabc61229a7',
  },
  {
    title: 'Mercado - Comprando carros',
    description:
      'Onde você compra os carros para revender de acordo com os pedidos dos clientes. Use os filtros para encontrar carros compatíveis. ',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20114420.png?alt=media&token=d1abaa76-f4bb-48b0-88fd-120e5d6cd346',
  },
  {
    title: 'Mercado - Agilizando sua operação',
    description:
      'No canto inferior do mercado você pode ver o cliente selecionado em Showroom para facilitar sua busca.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20114202.png?alt=media&token=9202d6f7-cbbf-483c-88c8-52c6c390535e',
  },
  {
    title: 'Banco - Emprestando dinheiro',
    description:
      'Empreste até R$1.000.000 a juros de 10% ao dia. Você tem 7 dias para pagar, caso contrário seu patrimônio será confiscado.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20114635.png?alt=media&token=435c224d-f352-4547-accf-2a3db84a80e9',
  },
  {
    title: 'Coleção - Monte sua garagem dos sonhos',
    description:
      'Guarde os carros que são especiais para você e exponha sua garagem milionária para outros jogadores. Você pode adicionar seu carro da vida real nessa área.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20115041.png?alt=media&token=25eed4fd-e599-464d-9a33-4c815c0ccd1e',
  },
  {
    title: 'Loja do jogo',
    description:
      'Consiga super vantagens no jogo para evoluir muito mais rápido e se tornar um bilionário.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/tutorial%2FScreenshot%202024-10-12%20115148.png?alt=media&token=0c01741b-d60c-4300-bc40-63c38883ddfb',
  },
];

const TutorialScreen = () => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const navigate = useNavigate();

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
        {/* Botão de Voltar */}
        <Button
          type="link"
          icon={<LeftOutlined />}
          onClick={goToDashboard}
          style={{ marginBottom: '16px' }}
        >
          Voltar ao Dashboard
        </Button>

        {/* Passos do Tutorial */}
        <Steps
          current={currentStep}
          onChange={(current) => setCurrentStep(current)}
          direction="horizontal"
          style={{ marginBottom: '24px' }}
        >
          {tutorialSteps.map((item) => (
            <Step key={item.title} />
          ))}
        </Steps>

        {/* Conteúdo do Passo Atual */}
        <div
          style={{
            padding: '24px',
            backgroundColor: '#fff',
            borderRadius: '8px',
          }}
        >
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={24} md={12}>
              <img
                src={tutorialSteps[currentStep].image}
                alt={tutorialSteps[currentStep].title}
                style={{ width: '100%', borderRadius: '8px' }}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Title level={3}>{tutorialSteps[currentStep].title}</Title>
              <Text style={{ fontSize: '16px' }}>
                {tutorialSteps[currentStep].description}
              </Text>
            </Col>
          </Row>
        </div>

        {/* Botões de Navegação */}
        <div
          style={{
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {currentStep > 0 && (
            <Button onClick={() => prev()}>Anterior</Button>
          )}
          {currentStep < tutorialSteps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Próximo
            </Button>
          )}
          {currentStep === tutorialSteps.length - 1 && (
            <Button type="primary" onClick={goToDashboard}>
              Finalizar Tutorial
            </Button>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default TutorialScreen;
