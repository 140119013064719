import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Image, Typography, Card, Button, Modal, Carousel, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { firestore } from './firebaseConfig';
import { doc, getDoc, collection, getDocs, updateDoc, addDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const { Title, Text } = Typography;

const PerfilLoja = () => {
  const { storeId } = useParams(); // Obtém o storeId da URL
  const [store, setStore] = useState(null);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchStoreAndCars = async () => {
      try {
        // Buscar detalhes da loja
        const storeDoc = await getDoc(doc(firestore, 'stores', storeId));
        if (storeDoc.exists()) {
          setStore(storeDoc.data());
        } else {
          message.error('Loja não encontrada.');
        }

        // Buscar carros da loja
        const carsCollection = await getDocs(collection(firestore, 'stores', storeId, 'cars'));
        const carsList = carsCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCars(carsList);
      } catch (error) {
        console.error('Erro ao buscar detalhes da loja e carros:', error);
        message.error('Erro ao carregar os dados da loja.');
      } finally {
        setLoading(false);
      }
    };

    fetchStoreAndCars();
  }, [storeId]);

  const handleCarClick = (car) => {
    setSelectedCar(car);
    setModalVisible(true); // Abre o modal de compra
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedCar(null);
  };

  const handlePurchase = async () => {
    if (!selectedCar) return;

    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado');
        return;
      }

      const playerId = user.uid;
      const playerRef = doc(firestore, 'players', playerId);
      const playerSnap = await getDoc(playerRef);

      if (playerSnap.exists()) {
        const playerData = playerSnap.data();
        if (playerData.balance >= selectedCar.valor) {
          await addDoc(collection(playerRef, 'showroom'), {
            ...selectedCar,
            carId: selectedCar.id,
          });

          await updateDoc(playerRef, {
            balance: playerData.balance - selectedCar.valor,
            'metrics.carnumber': (playerData.metrics.carnumber || 0) + 1,
            'metrics.showroomValue': (playerData.metrics.showroomValue || 0) + selectedCar.valor,
          });

          await addDoc(collection(playerRef, 'transactions'), {
            carId: selectedCar.id,
            marca: selectedCar.marca,
            modelo: selectedCar.modelo,
            valor: selectedCar.valor,
            data: new Date(),
            tipo: 'compra',
          });

          message.success('Compra realizada com sucesso');
          closeModal();
        } else {
          message.error('Saldo insuficiente para a compra.');
        }
      }
    } catch (error) {
      console.error('Erro ao confirmar compra:', error);
      message.error('Erro ao realizar a compra.');
    }
  };

  return (
    <Layout style={{ padding: '20px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
      {/* Botão de voltar */}
      <Row justify="start">
        <Col>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/market')}>
            Voltar
          </Button>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={24} style={{ textAlign: 'center', marginBottom: '20px' }}>
          {store && (
            <>
              <Image
                src={store.logoUrl}
                alt="Logo da loja"
                width={150}
                height={150}
                style={{ borderRadius: '50%', marginBottom: '20px' }}
              />
              <Title level={2} style={{ color: '#040611' }}>{store.name}</Title>
              <Text style={{ fontSize: '18px', color: '#999' }}>
                Imagens e informações pertencem às lojas, usadas para fins lúdicos. Solicite a remoção se necessário.
              </Text>
            </>
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {cars.map((car) => (
          <Col xs={24} sm={12} md={8} lg={6} key={car.id}>
            <Card
              hoverable
              cover={
                <Image
                  alt={car.modelo}
                  src={car.imagens && car.imagens[0]}
                  style={{ height: '200px', objectFit: 'cover', borderRadius: '8px' }}
                />
              }
              onClick={() => handleCarClick(car)}
              style={{ borderRadius: '12px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <Card.Meta
                title={`${car.marca} ${car.modelo}`}
                description={`Ano: ${car.ano}`}
              />
            </Card>
          </Col>
        ))}
      </Row>

      {selectedCar && (
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          width={800}
          bodyStyle={{ padding: '24px' }}
          centered
        >
          <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={12}>
              {selectedCar.imagens && selectedCar.imagens.length > 0 ? (
                <Carousel>
                  {selectedCar.imagens.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`${selectedCar.marca} ${selectedCar.modelo}`}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '300px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography.Text>Imagem Indisponível</Typography.Text>
                </div>
              )}
            </Col>
            <Col span={12}>
              <Title level={4}>
                {selectedCar.marca} {selectedCar.modelo}
              </Title>
              <Text>Ano: {selectedCar.ano || 'N/A'}</Text>
              <br />
              <Text>Km: {selectedCar.km || 'N/A'}</Text>
              <br />
              <Text>Potência: {selectedCar.potencia || 'N/A'}</Text>
              <br />
              <Text>Valor: {selectedCar.valor ? `R$ ${selectedCar.valor.toLocaleString('pt-BR')}` : 'Indisponível'}</Text>
              <br />
              <br />
              <Button type="primary" onClick={handlePurchase} style={{ marginRight: '8px' }}>
                Comprar
              </Button>
              <Button onClick={closeModal}>Cancelar</Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Layout>
  );
};

export default PerfilLoja;
