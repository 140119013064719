import React from 'react';
import { Layout, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const Maintenance = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/auth'); // Redireciona para a tela de login
  };

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <Title level={2}>Manutenção em Andamento</Title>
        <Paragraph>
          O acesso ao jogo está temporariamente desativado. Estamos realizando manutenções para melhorar sua experiência.
        </Paragraph>
        <Button type="primary" onClick={handleBackToLogin}>
          Voltar ao Login
        </Button>
      </div>
    </Layout>
  );
};

export default Maintenance;
