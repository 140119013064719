import React, { useState, useEffect } from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Carousel,
  message,
  Menu,
  Tooltip,
  Tag,
  Input,
  Select,
  Form,
  Card,
  Affix,
  Pagination,
  Grid,
  Drawer,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  ShoppingFilled,
  CompassOutlined,
  NotificationOutlined,
  RocketOutlined,
  FundOutlined,
  StarOutlined,
  DollarOutlined,
  CrownOutlined,
  ShoppingOutlined,
  GoldOutlined,
  MenuOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import OffersSection2 from './OffersSection2';
import OffersSection from './OffersSection';

import {
  collection,
  writeBatch,
  getDocs,
  doc,
  updateDoc,
  where,
  query,
  deleteDoc,
  setDoc,
  getDoc,
  onSnapshot,
  limit,
  startAfter,
  increment,    
  Timestamp,     
} from 'firebase/firestore';

const { Content, Header } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Showroom = () => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [playerLevel, setPlayerLevel] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [cars, setCars] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientesData, setClientesData] = useState([]);
  const [showroomData, setShowroomData] = useState([]);
  const [storeName, setStoreName] = useState('');
  const [visibleClients, setVisibleClients] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [profitPercentage, setProfitPercentage] = useState(0.03); // Default to 6%
  const [subscription, setSubscription] = useState({
    active: false,
    type: 'None',
  });

  const [filters, setFilters] = useState({
    marca: '',
    tipo: '',
    potenciaMax: '',
    kmMax: '',
    anoMax: '',
  });
  const [lastVisibleCar, setLastVisibleCar] = useState(null);
  const [hasMoreCars, setHasMoreCars] = useState(true);
  const [loadingCars, setLoadingCars] = useState(false);
  const [playerSlots, setPlayerSlots] = useState(4);
  const [balance, setBalance] = useState(0);
  const [marcaFilter, setMarcaFilter] = useState('');
  const [tipoFilter, setTipoFilter] = useState('');
  const [nivelFilter, setNivelFilter] = useState('');
  const salePrice = selectedCar ? selectedCar.valor * (1 + profitPercentage) : 0;
  const profit = selectedCar ? selectedCar.valor * profitPercentage : 0;

  // Estados para Paginação dos Clientes
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  // Filtrando clientes com base nos filtros aplicados
  const filteredClients = visibleClients.filter((client) => {
    const matchesMarca = !marcaFilter || client.marca === marcaFilter;
    const matchesNivel = nivelFilter === '' || client.nivel === nivelFilter;
    const matchesTipo = !tipoFilter || client.tipo === tipoFilter;
    return matchesMarca && matchesNivel && matchesTipo;
  });

  // Calculando os clientes a serem exibidos na página atual
  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClients = filteredClients.slice(
    indexOfFirstClient,
    indexOfLastClient
  );

  const auth = getAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  useEffect(() => {
    fetchStoreName();
    fetchPlayerData();
    fetchPlayerLevelAndClients();

    let unsubscribe;
    const fetchClientes = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const clientsCollection = collection(
            firestore,
            'players',
            user.uid,
            'activeclients'
          );
          unsubscribe = onSnapshot(clientsCollection, (snapshot) => {
            const clientsList = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setClientesData(clientsList);
            if (!selectedClient && clientsList.length > 0) {
              setSelectedClient(clientsList[0]);
            }
          });
        } else {
          console.error('Usuário não autenticado');
          navigate('/auth');
        }
      } catch (error) {
        console.error('Erro ao buscar clientes ativos:', error);
      }
    };

    fetchClientes();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (clientesData.length > 0) {
      updateVisibleClients();
    }
  }, [clientesData]);

  useEffect(() => {
    setLastVisibleCar(null);
    setHasMoreCars(true);
    fetchShowroom(true);
  }, [filters]);

  useEffect(() => {
    const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [filteredClients, currentPage, itemsPerPage]);

  const fetchStoreName = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(firestore, 'players', user.uid));
        if (userDoc.exists()) {
          setStoreName(userDoc.data().name);
        }
      } else {
        console.error('Usuário não autenticado');
        navigate('/auth');
      }
    } catch (error) {
      console.error('Erro ao buscar o nome da loja:', error);
    }
  };

  const fetchPlayerData = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerDoc = await getDoc(doc(firestore, 'players', user.uid));
        if (playerDoc.exists()) {
          const playerData = playerDoc.data();

          setPlayerLevel(playerData.level || 1);
          setPlayerSlots(playerData.slots || 4);
          setBalance(playerData.balance || 0);
          setProfitPercentage((playerData.profit || 3) / 100); // Default to 6%
          setSubscription(playerData.subscription || {
            active: false,
            type: 'None',
          });
        } else {
          throw new Error('Dados do jogador não encontrados.');
        }
      } else {
        throw new Error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      message.error('Erro ao buscar dados do jogador.');
    }
  };

  const showHelpModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleHelpModalOk = () => {
    setIsHelpModalVisible(false);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalVisible(false);
  };

  const fetchShowroom = async (reset = false) => {
    if (loadingCars) return;

    setLoadingCars(true);

    try {
      const user = auth.currentUser;
      if (user) {
        const playerId = user.uid;
        let collectionRef = collection(
          doc(firestore, 'players', playerId),
          'showroom'
        );

        const constraints = [];

        if (filters.marca) {
          constraints.push(where('marca', '==', filters.marca));
        }
        if (filters.tipo) {
          constraints.push(where('tipo', '==', filters.tipo));
        }
        if (filters.potenciaMin) {
          constraints.push(where('potencia', '<=', parseInt(filters.potenciaMin)));
        }
        if (filters.km) {
          constraints.push(where('km', '<=', parseInt(filters.kmMax)));
        }
        if (filters.anoMin) {
          constraints.push(where('ano', '<=', parseInt(filters.anoMin)));
        }

        let q = query(collectionRef, ...constraints);

        let carQuery;
        if (reset || !lastVisibleCar) {
          carQuery = query(q, limit(24));
        } else {
          carQuery = query(q, startAfter(lastVisibleCar), limit(24));
        }

        const carDocs = await getDocs(carQuery);

        const cars = carDocs.docs.map((doc) => ({
          carID: doc.id,
          ...doc.data(),
        }));

        if (reset) {
          setShowroomData(cars);
        } else {
          setShowroomData((prevCars) => [...prevCars, ...cars]);
        }

        if (carDocs.docs.length < 24) {
          setHasMoreCars(false);
        } else {
          setHasMoreCars(true);
        }

        if (carDocs.docs.length > 0) {
          setLastVisibleCar(carDocs.docs[carDocs.docs.length - 1]);
        }
      } else {
        console.error('Usuário não autenticado');
        navigate('/auth');
      }
    } catch (error) {
      console.error('Erro ao buscar showroom: ', error);
    } finally {
      setLoadingCars(false);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setFilters({
      marca: '',
      tipo: '',
      potenciaMax: '',
      kmMax: '',
      anoMax: '',
    });
    setCurrentPage(1);
  };

  const handleClientSelect = (clientId) => {
    const selected = visibleClients.find((client) => client.id === clientId);
    setSelectedClient(selected);

    localStorage.setItem('selectedClient', JSON.stringify(selected));
  };

  const handleCarClick = (car) => {
    setSelectedCar(car);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedCar(null);
  };

  const showConfirmDialog = () => {
    setModalVisible(false);
    setConfirmVisible(true);
  };

  const hideConfirmDialog = () => setConfirmVisible(false);

  const closeFeedbackModal = () => {
    setFeedbackVisible(false);
  };

  const confirmSale = async () => {
    if (isProcessing) return;
  
    try {
      setIsProcessing(true);
  
      const user = auth.currentUser;
      if (!user || !selectedCar || !selectedClient) {
        message.error('Usuário, carro ou cliente selecionado não está disponível.');
        setIsProcessing(false);
        return;
      }
  
      if (!selectedCar.valor) {
        message.error('O valor do carro não está definido.');
        setIsProcessing(false);
        return;
      }
  
      const playerId = user.uid;
      const playerRef = doc(firestore, 'players', playerId);
      const carRefInShowroom = doc(playerRef, 'showroom', selectedCar.carID);
  
      const compatibility = calculateCompatibility(
        selectedClient,
        selectedCar,
        selectedCar.valor
      );
  
      if (selectedCar.marca !== selectedClient.marca) {
        message.error('A marca do carro não corresponde ao que o cliente deseja.');
        setIsProcessing(false);
        return;
      }
  
      if (selectedCar.valor > selectedClient.valorMax * 1.1) {
        message.error('O valor do carro excede o valor máximo que o cliente está disposto a pagar.');
        setIsProcessing(false);
        return;
      }
  
      if (compatibility < 70) {
        message.error('O carro não é compatível o suficiente com o cliente.');
        setIsProcessing(false);
        return;
      }
  
      const playerSnap = await getDoc(playerRef);
      if (!playerSnap.exists()) {
        message.error('Dados do jogador não encontrados.');
        setIsProcessing(false);
        return;
      }
  
      const playerData = playerSnap.data();
      const playerLevel = playerData.level || 1;
  
      const getExtraCost = (level) => {
        if (level === 1) return 40000;
        if (level === 2) return 60000;
        if (level === 3) return 120000;
        if (level === 4) return 140000;
        if (level === 5) return 200000;
        if (level > 5) return 200000 + (level - 5) * 100000;
        return 30000;
      };
  
      const EXTRA_COST = getExtraCost(playerLevel);
  
      let profitPercentage =
        typeof playerData.profit === 'number' && playerData.profit > 0
          ? playerData.profit / 100
          : 0.03; // Default to 6%
  
  
      const batch = writeBatch(firestore);
  
      const costPrice = selectedCar.valor;
      const salePrice = costPrice * (1 + profitPercentage);
      const profit = salePrice - costPrice;
  
      const today = new Date();
      const todayDateString = today.toISOString().split('T')[0];
  
      let dailySales = playerData.dailySales || 0;
      const lastSaleDate = playerData.lastSaleDate
        ? playerData.lastSaleDate.toDate().toISOString().split('T')[0]
        : null;
  
      let needToPayExtra = false;
  
      if (lastSaleDate !== todayDateString) {
        dailySales = 0;
        playerData.dailySales = 0;
        playerData.extraCostPaidDate = null;
      }
  
      if (dailySales >= 40) {
        needToPayExtra = true;
      }
  
      if (needToPayExtra) {
        const extraCostPaidDate = playerData.extraCostPaidDate
          ? playerData.extraCostPaidDate.toDate().toISOString().split('T')[0]
          : null;
  
        if (extraCostPaidDate !== todayDateString) {
          Modal.confirm({
            title: 'Limite de Vendas Diárias Atingido',
            content: `Você atingiu o limite de vendas diárias. Pague a taxa de R$${EXTRA_COST.toLocaleString(
              'pt-BR'
            )} para continuar vendendo pelo resto do dia.`,
            onOk: async () => {
              try {
                if (playerData.balance < EXTRA_COST) {
                  message.error('Saldo insuficiente para pagar a taxa.');
                  setIsProcessing(false);
                  return;
                }
  
                batch.update(playerRef, {
                  balance: (playerData.balance || 0) - EXTRA_COST + salePrice,
                  'metrics.carnumber': Math.max(
                    (playerData.metrics.carnumber || 0) - 1,
                    0
                  ),
                  'metrics.showroomValue':
                    (playerData.metrics.showroomValue || 0) - costPrice,
                  'metrics.totalSales': (playerData.metrics.totalSales || 0) + 1,
                  'metrics.totalRevenue':
                    (playerData.metrics.totalRevenue || 0) + salePrice,
                  'metrics.totalProfit':
                    (playerData.metrics.totalProfit || 0) + profit,
                  dailySales: dailySales + 1,
                  lastSaleDate: today,
                  extraCostPaidDate: today,
                  // **Atualização das métricas semanais**
                  'metrics.weeklySales': increment(1),             // Incrementa weeklySales
                  'metrics.lastSalesUpdate': Timestamp.fromDate(today), // Atualiza lastSalesUpdate
                });
  
                const transactionRef = doc(collection(playerRef, 'transactions'));
                batch.set(transactionRef, {
                  carID: selectedCar.carID,
                  marca: selectedCar.marca,
                  modelo: selectedCar.modelo,
                  valor: salePrice,
                  lucro: profit,
                  compatibilidade: compatibility,
                  data: new Date(),
                  tipo: 'venda',
                });
  
                batch.delete(carRefInShowroom);
  
                const inactiveRef = doc(
                  firestore,
                  'players',
                  playerId,
                  'inactiveclients',
                  selectedClient.id
                );
                batch.set(inactiveRef, {
                  ...selectedClient,
                  removedAt: new Date(),
                });
  
                const activeRef = doc(
                  firestore,
                  'players',
                  playerId,
                  'activeclients',
                  selectedClient.id
                );
                batch.delete(activeRef);
  
                await batch.commit();
  
                setClientesData((prev) =>
                  prev.filter((client) => client.id !== selectedClient.id)
                );
                setSelectedClient(null);
  
                await fetchShowroom(true);
                setConfirmVisible(false);
                await saveRemovedClients();
                message.success('Venda realizada com sucesso!');
  
                await checkPlayerLevel(playerId, playerData);
              } catch (error) {
                console.error('Erro ao realizar venda com custo extra:', error);
                message.error('Erro ao realizar a venda com custo extra.');
                setIsProcessing(false);
              }
            },
            onCancel: () => {
              message.info('Venda cancelada.');
              setIsProcessing(false);
            },
          });
  
          return;
        } else {
          // Continua a venda sem taxa extra
          batch.update(playerRef, {
            balance: (playerData.balance || 0) + salePrice,
            'metrics.carnumber': Math.max(
              (playerData.metrics.carnumber || 0) - 1,
              0
            ),
            'metrics.showroomValue':
              (playerData.metrics.showroomValue || 0) - costPrice,
            'metrics.totalSales': (playerData.metrics.totalSales || 0) + 1,
            'metrics.totalRevenue':
              (playerData.metrics.totalRevenue || 0) + salePrice,
            'metrics.totalProfit':
              (playerData.metrics.totalProfit || 0) + profit,
            dailySales: dailySales + 1,
            lastSaleDate: today,
            // **Atualização das métricas semanais**
            'metrics.weeklySales': increment(1),             // Incrementa weeklySales
            'metrics.lastSalesUpdate': Timestamp.fromDate(today), // Atualiza lastSalesUpdate
          });
  
          batch.set(doc(collection(playerRef, 'transactions')), {
            carID: selectedCar.carID,
            marca: selectedCar.marca,
            modelo: selectedCar.modelo,
            valor: salePrice,
            lucro: profit,
            compatibilidade: compatibility,
            data: new Date(),
            tipo: 'venda',
          });
  
          batch.delete(carRefInShowroom);
  
          batch.set(
            doc(
              firestore,
              'players',
              playerId,
              'inactiveclients',
              selectedClient.id
            ),
            {
              ...selectedClient,
              removedAt: new Date(),
            }
          );
  
          batch.delete(
            doc(
              firestore,
              'players',
              playerId,
              'activeclients',
              selectedClient.id
            )
          );
  
          await batch.commit();
  
          setClientesData((prev) =>
            prev.filter((client) => client.id !== selectedClient.id)
          );
          setSelectedClient(null);
  
          await fetchShowroom(true);
          await fetchClientes();
          setConfirmVisible(false);
          await saveRemovedClients();
          message.success('Venda realizada com sucesso!');
  
          await checkPlayerLevel(playerId, playerData);
        }
      } else {
        // Processa a venda normalmente sem taxa extra
        batch.update(playerRef, {
          balance: (playerData.balance || 0) + salePrice,
          'metrics.carnumber': Math.max(
            (playerData.metrics.carnumber || 0) - 1,
            0
          ),
          'metrics.showroomValue':
            (playerData.metrics.showroomValue || 0) - costPrice,
          'metrics.totalSales': (playerData.metrics.totalSales || 0) + 1,
          'metrics.totalRevenue':
            (playerData.metrics.totalRevenue || 0) + salePrice,
          'metrics.totalProfit':
            (playerData.metrics.totalProfit || 0) + profit,
          dailySales: dailySales + 1,
          lastSaleDate: today,
          // **Atualização das métricas semanais**
          'metrics.weeklySales': increment(1),
          'metrics.lastSalesUpdate': Timestamp.fromDate(today),
        });
  
        batch.set(doc(collection(playerRef, 'transactions')), {
          carID: selectedCar.carID,
          marca: selectedCar.marca,
          modelo: selectedCar.modelo,
          valor: salePrice,
          lucro: profit,
          compatibilidade: compatibility,
          data: new Date(),
          tipo: 'venda',
        });
  
        batch.delete(carRefInShowroom);
  
        batch.set(
          doc(
            firestore,
            'players',
            playerId,
            'inactiveclients',
            selectedClient.id
          ),
          {
            ...selectedClient,
            removedAt: new Date(),
          }
        );
  
        batch.delete(
          doc(
            firestore,
            'players',
            playerId,
            'activeclients',
            selectedClient.id
          )
        );
  
        await batch.commit();
  
        setClientesData((prev) =>
          prev.filter((client) => client.id !== selectedClient.id)
        );
        setSelectedClient(null);
  
        await fetchShowroom(true);
        await fetchClientes();
        setConfirmVisible(false);
        await saveRemovedClients();
        message.success('Venda realizada com sucesso!');
  
        await checkPlayerLevel(playerId, playerData);
      }
    } catch (error) {
      console.error('Erro ao confirmar venda:', error);
      message.error('Erro ao realizar a venda.');
    } finally {
      setIsProcessing(false);
    }
  
    setFeedbackVisible(true);
  };

  
  
  
  

  
  

  const saveRemovedClients = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerRef = doc(firestore, 'players', user.uid);
        const playerSnap = await getDoc(playerRef);
        if (!playerSnap.exists()) {
          console.error('Dados do jogador não encontrados.');
          return;
        }
        const playerData = playerSnap.data();
        const currentLevel = playerData.level || 1;
        const levelsProcessed = playerData.levelsProcessed || [];

        const levelsBeforeCurrent = levelsProcessed
          .filter((level) => level < currentLevel)
          .sort((a, b) => b - a);
        const lastThreeLevels = levelsBeforeCurrent.slice(0, 3);

        const levelsToConsider = lastThreeLevels;

        const inactiveClientsRef = collection(
          firestore,
          'players',
          user.uid,
          'inactiveclients'
        );
        const snapshot = await getDocs(inactiveClientsRef);

        const currentTime = new Date();

        snapshot.forEach(async (docSnapshot) => {
          const clientData = docSnapshot.data();

          if (clientData.removedAt) {
            const removedAt = clientData.removedAt.toDate();

            const timeDifference =
              (currentTime - removedAt) / (1000 * 60 * 60 * 24);

            if (
              levelsToConsider.includes(clientData.nivel) &&
              timeDifference >= 3
            ) {
              const activeRef = doc(
                firestore,
                'players',
                user.uid,
                'activeclients',
                docSnapshot.id
              );
              await setDoc(activeRef, clientData);

              await deleteDoc(docSnapshot.ref);

              console.log(`Cliente ${clientData.nome} foi reativado.`);
            }
          } else {
            console.error(
              `Cliente ${clientData.nome} não possui campo removedAt.`
            );
          }
        });
      } else {
        console.error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Erro ao salvar clientes removidos:', error);
    }
  };

  const checkPlayerLevel = async (playerId, playerData) => {
    try {
      const currentLevel = playerData.level || 0;
      const totalSales = playerData.metrics.totalSales || 0;
      const totalProfit = playerData.metrics.totalProfit || 0;
      const totalRevenue = playerData.metrics.totalRevenue || 0;

      const levelRules = [
        { level: 2, salesRequired: 10,     totalProfitRequired: 100_000,            revenueRequired: 2_000_000 },
        { level: 3, salesRequired: 15,     totalProfitRequired: 160_000,            revenueRequired: 3_400_000 },
        { level: 4, salesRequired: 23,     totalProfitRequired: 256_000,            revenueRequired: 5_780_000 },
        { level: 5, salesRequired: 35,     totalProfitRequired: 409_600,            revenueRequired: 9_826_000 },
        { level: 6, salesRequired: 53,     totalProfitRequired: 655_360,            revenueRequired: 16_704_200 },
        { level: 7, salesRequired: 80,     totalProfitRequired: 1_048_576,          revenueRequired: 28_397_140 },
        { level: 8, salesRequired: 120,    totalProfitRequired: 1_677_722,          revenueRequired: 48_275_000 },
        { level: 9, salesRequired: 180,    totalProfitRequired: 2_684_355,          revenueRequired: 82_067_500 },
        { level: 10, salesRequired: 270,   totalProfitRequired: 4_294_968,          revenueRequired: 139_514_750 },
        { level: 11, salesRequired: 405,   totalProfitRequired: 6_871_949,          revenueRequired: 237_175_000 },
        { level: 12, salesRequired: 608,   totalProfitRequired: 10_995_118,         revenueRequired: 403_197_500 },
        { level: 13, salesRequired: 912,   totalProfitRequired: 17_592_189,         revenueRequired: 685_435_750 },
        { level: 14, salesRequired: 1_368, totalProfitRequired: 28_147_502,         revenueRequired: 1_165_240_775 },
        { level: 15, salesRequired: 2_052, totalProfitRequired: 45_036_003,         revenueRequired: 1_980_909_319 },
        { level: 16, salesRequired: 3_078, totalProfitRequired: 72_057_604,         revenueRequired: 3_367_545_842 },
        { level: 17, salesRequired: 4_617, totalProfitRequired: 115_292_166,        revenueRequired: 5_724_827_931 },
        { level: 18, salesRequired: 6_926, totalProfitRequired: 184_467_466,        revenueRequired: 9_732_207_483 },
        { level: 19, salesRequired: 10_389,totalProfitRequired: 295_147_946,        revenueRequired: 16_544_752_721 },
        { level: 20, salesRequired: 15_584,totalProfitRequired: 472_236_713,        revenueRequired: 28_126_079_626 },
        { level: 21, salesRequired: 23_376,totalProfitRequired: 755_578_741,        revenueRequired: 47_814_335_365 },
        { level: 22, salesRequired: 35_064,totalProfitRequired: 1_208_925_986,      revenueRequired: 81_284_370_120 },
        { level: 23, salesRequired: 52_596,totalProfitRequired: 1_934_281_578,      revenueRequired: 138_182_629_204 },
        { level: 24, salesRequired: 78_894,totalProfitRequired: 3_094_850_525,      revenueRequired: 234_910_469_646 },
        { level: 25, salesRequired: 118_341,totalProfitRequired: 4_951_760_840,     revenueRequired: 399_347_798_398 },
        { level: 26, salesRequired: 177_512,totalProfitRequired: 7_922_817_344,     revenueRequired: 678_891_257_276 },
        { level: 27, salesRequired: 266_268,totalProfitRequired: 12_684_507_750,    revenueRequired: 1_154_115_137_369 },
        { level: 28, salesRequired: 399_402,totalProfitRequired: 20_295_212_401,    revenueRequired: 1_962_995_733_527 },
        { level: 29, salesRequired: 599_103,totalProfitRequired: 32_472_339_841,    revenueRequired: 3_336_092_746_996 },
        { level: 30, salesRequired: 898_655,totalProfitRequired: 51_955_743_746,    revenueRequired: 5_671_357_669_893 },


        
      ];

      const nextLevel = levelRules.find((rule) => rule.level === currentLevel + 1);

      if (nextLevel) {
        if (
          totalSales >= nextLevel.salesRequired &&
          totalProfit >= nextLevel.totalProfitRequired &&
          totalRevenue >= nextLevel.revenueRequired
        ) {
          await updateDoc(doc(firestore, 'players', playerId), {
            level: currentLevel + 1,
          });
          message.success(`Parabéns! Você subiu para o nível ${currentLevel + 1}`);
        }
      }
    } catch (error) {
      console.error('Erro ao verificar o nível do jogador:', error);
      message.error('Erro ao verificar o nível.');
    }
  };

  const calculateCompatibility = (client, car, sellingPrice) => {
    let compatibility = 0;
    let compatibilityReport = {
      tipo: false,
      marca: false,
      anoMin: false,
      km: false,
      potenciaMin: false,
      valor: false,
    };

    if (client.tipo === car.tipo) {
      compatibility += 20;
      compatibilityReport.tipo = true;
    }

    if (client.marca === car.marca) {
      compatibility += 20;
      compatibilityReport.marca = true;
    }

    if (car.ano >= client.anoMin) {
      compatibility += 20;
      compatibilityReport.ano = true;
    }

    if (car.km <= client.km || (car.km - client.km) / client.km <= 0.3) {
      compatibility += 15;
      compatibilityReport.km = true;
    }

    const potenciaDifference = car.potencia - client.potencia;
    if (potenciaDifference >= -50 && potenciaDifference <= 150) {
      compatibility += 20;
      compatibilityReport.potencia = true;
    }

    if (sellingPrice <= client.valorMax * 5) {
      compatibilityReport.valor = true;
    } else {
      compatibility = 0;
    }

    if (compatibility < 60) {
      Modal.warning({
        title: 'Compatibilidade insuficiente',
        content: (
          <div>
            <p>
              O carro não é compatível o suficiente com o cliente. Veja abaixo os
              detalhes:
            </p>
            <ul>
              <li>
                Tipo de carro: {compatibilityReport.tipo ? 'Compatível' : 'Incompatível'}
              </li>
              <li>
                Marca: {compatibilityReport.marca ? 'Compatível' : 'Incompatível'}
              </li>
              <li>
                Ano: {compatibilityReport.ano ? 'Compatível' : 'Incompatível'}
              </li>
              <li>
                Quilometragem: {compatibilityReport.km ? 'Compatível' : 'Incompatível'}
              </li>
              <li>
                Potência: {compatibilityReport.potenciaMin ? 'Compatível' : 'Incompatível'}
              </li>
              <li>
                Valor: {compatibilityReport.valor ? 'Compatível' : 'Incompatível'}
              </li>
            </ul>
            <p>A compatibilidade mínima exigida é 60 pontos. A oferta foi negada.</p>
          </div>
        ),
      });
      return 0;
    }

    return compatibility;
  };

  const handleAddToCollection = async () => {
    if (!selectedCar) return;

    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado');
        return;
      }

      const playerId = user.uid;
      const playerRef = doc(firestore, 'players', playerId);
      const carRefInShowroom = doc(playerRef, 'showroom', selectedCar.carID);
      const carRefInCollection = doc(
        playerRef,
        'colecaopessoal',
        selectedCar.carID
      );

      const batch = writeBatch(firestore);

      batch.set(carRefInCollection, selectedCar);
      batch.delete(carRefInShowroom);

      await batch.commit();

      message.success('Carro movido com sucesso!');
      closeModal();
      fetchShowroom(true);
    } catch (error) {
      console.error('Erro ao mover carro para a Coleção Pessoal:', error);
      message.error('Erro ao mover carro para a Coleção Pessoal.');
    }
  };

  const openQuickSellModal = (car) => {
    const valorVendaRapida = car.valor * 0.8;

    Modal.confirm({
      title: 'Confirmar Venda Rápida',
      content: `Você receberá R$${valorVendaRapida.toLocaleString(
        'pt-BR'
      )} (20% de prejuízo). Deseja continuar?`,
      onOk: async () => {
        await realizarVendaRapida(car, valorVendaRapida);
      },
    });
  };

  const realizarVendaRapida = async (car, valorVendaRapida) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado');
        return;
      }

      const playerId = user.uid;
      const playerRef = doc(firestore, 'players', playerId);
      const carRefInShowroom = doc(playerRef, 'showroom', car.carID);

      const playerSnap = await getDoc(playerRef);
      if (playerSnap.exists()) {
        const playerData = playerSnap.data();

        const batch = writeBatch(firestore);

        batch.update(playerRef, {
          balance: playerData.balance + valorVendaRapida,
          'metrics.carnumber': (playerData.metrics.carnumber || 0) - 1,
          'metrics.showroomValue':
            (playerData.metrics.showroomValue || 0) - car.valor,
          'metrics.totalSales': (playerData.metrics.totalSales || 0) + 1,
          'metrics.totalRevenue':
            (playerData.metrics.totalRevenue || 0) + valorVendaRapida,
        });

        const transactionRef = doc(collection(playerRef, 'transactions'));
        batch.set(transactionRef, {
          carID: car.carID,
          marca: car.marca,
          modelo: car.modelo,
          valor: valorVendaRapida,
          lucro: 0,
          data: new Date(),
          tipo: 'venda rápida',
        });

        batch.delete(carRefInShowroom);

        await batch.commit();

        message.success('Venda rápida realizada.');
        fetchShowroom(true);
        closeModal();
      }
    } catch (error) {
      message.error('Erro ao realizar a venda rápida: ' + error.message);
    }
  };

  // Função para comprar slot
  const calculateSlotPrice = (currentSlots) => 120000 + (currentSlots - 4) * 100000;


  const comprarSlot = async () => {
    const user = auth.currentUser;
    if (!user) {
      message.error('Usuário não autenticado');
      return;
    }
  
    const slotPrice = calculateSlotPrice(playerSlots);
    console.log('Preço do Slot:', slotPrice);
  
    Modal.confirm({
      title: 'Confirmar Compra de Vaga',
      content: `Você está prestes a comprar uma vaga por R$${slotPrice.toLocaleString('pt-BR')}. Deseja confirmar?`,
      onOk: async () => {
        if (balance >= slotPrice) {
          try {
            const playerRef = doc(firestore, 'players', user.uid);
            await updateDoc(playerRef, {
              slots: playerSlots + 1,
              balance: balance - slotPrice,
            });
            setPlayerSlots(playerSlots + 1);
            setBalance(balance - slotPrice);
            message.success('Vaga comprada com sucesso!');
          } catch (error) {
            console.error('Erro ao comprar vaga:', error);
            message.error('Erro ao comprar vaga.');
          }
        } else {
          message.error('Saldo insuficiente.');
        }
      },
    });
  };

  /***********************************
   * Lógica de clientes - Gerenciamento completo
   ***********************************/

  // Função principal que verifica o nível do jogador e carrega clientes correspondentes
  const fetchPlayerLevelAndClients = async () => {
    try {
      const user = auth.currentUser;

      if (!user) {
        message.error('Usuário não autenticado.');
        return;
      }

      const playerId = user.uid;
      const playerDocRef = doc(firestore, 'players', playerId);
      const clientsCollectionRef = collection(firestore, 'clientglobal');

      const [playerDoc, inactiveClientsSnapshot, activeClientsSnapshot] =
        await Promise.all([
          getDoc(playerDocRef),
          getDocs(collection(firestore, 'players', playerId, 'inactiveclients')),
          getDocs(collection(firestore, 'players', playerId, 'activeclients')),
        ]);

      if (!playerDoc.exists()) {
        console.error('Jogador não encontrado.');
        return;
      }

      const playerData = playerDoc.data();
      const nivelAtual = playerData.level || 1;

      setPlayerLevel(nivelAtual);

      const inactiveClientIds = inactiveClientsSnapshot.docs.map((doc) => doc.id);
      const activeClientIds = activeClientsSnapshot.docs.map((doc) => doc.id);

      const clientsQuery = query(
        clientsCollectionRef,
        where('nivel', '==', nivelAtual)
      );
      const clientsSnapshot = await getDocs(clientsQuery);

      if (clientsSnapshot.empty) {
        console.log(`Nenhum cliente encontrado para o nível ${nivelAtual}`);
        return;
      }

      const clientsList = clientsSnapshot.docs
        .filter(
          (doc) =>
            !inactiveClientIds.includes(doc.id) &&
            !activeClientIds.includes(doc.id)
        )
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

      if (
        !playerData.levelsProcessed ||
        !playerData.levelsProcessed.includes(nivelAtual)
      ) {
        if (clientsList.length > 0) {
          const activeClientsRef = collection(
            firestore,
            'players',
            playerId,
            'activeclients'
          );
          const batch = writeBatch(firestore);

          clientsList.forEach((client) => {
            const clientRef = doc(activeClientsRef, client.id);
            batch.set(clientRef, client);
          });

          const updatedLevelsProcessed = playerData.levelsProcessed
            ? [...playerData.levelsProcessed, nivelAtual]
            : [nivelAtual];

          batch.update(playerDocRef, {
            levelsProcessed: updatedLevelsProcessed,
          });

          await batch.commit();
          console.log(
            `Novos clientes copiados para 'activeclients' do nível ${nivelAtual}.`
          );
        }
      }

      
    } catch (error) {
      console.error('Erro ao buscar nível e clientes do jogador:', error);
      message.error('Erro ao carregar informações do jogador.');
    }
  };

  // Função para buscar e atualizar clientes ativos
  const fetchClientes = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const clientsCollection = collection(
          firestore,
          'players',
          user.uid,
          'activeclients'
        );
        const unsubscribe = onSnapshot(clientsCollection, (snapshot) => {
          const clientsList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setClientesData(clientsList);
          if (!selectedClient) {
            setSelectedClient(clientsList[0]);
          }
        });

        return () => unsubscribe();
      } else {
        console.error('Usuário não autenticado');
        navigate('/auth');
      }
    } catch (error) {
      console.error('Erro ao buscar clientes ativos:', error);
    }
  };

  // Função para atualizar todos os clientes visíveis
  const updateVisibleClients = () => {
    setVisibleClients(clientesData);
  };

  const handleMarcaChange = (value) => {
    setMarcaFilter(value);
    setCurrentPage(1); // Resetar a página atual ao alterar o filtro
  };

  const handleNivelChange = (value) => {
    setNivelFilter(value !== '' ? Number(value) : '');
    setCurrentPage(1);
  };

  const handleTipoChange = (value) => {
    setTipoFilter(value);
    setCurrentPage(1); // Resetar a página atual ao alterar o filtro
  };

  // Função para lidar com a mudança de página na paginação
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px' }}
            />
            <Button
  type="text"
  icon={<GoldOutlined style={{ fontSize: '24px', color: '#ffcf40' }} />}
  onClick={() => navigate('/shop')} // Navega para a tela loja
  title="Mina de Ouro"
  style={{ color: '#fff', marginLeft: '16px' }} // Estilo para o texto
>
Upgrades
</Button>
          </div>
          {/* Botão "Como jogar" */}
          
        </div>
  
        {/* Menu de Navegação */}
        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px' }}>
            {/* Itens do menu */}
            <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
     
      
     
            
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={() => setIsDrawerVisible(true)}
            className="drawer-toggle-button"
          />
        )}
  
        {/* Drawer para navegação mobile */}
        <Drawer
          title="Menu de Navegação"
          placement="right"
          onClose={() => setIsDrawerVisible(false)}
          visible={isDrawerVisible}
          className="mobile-menu-drawer"
        >
          <Menu mode="vertical">
            {/* Itens do menu (mesmos do menu desktop) */}
            <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<RocketOutlined style={{ fontSize: '18px', color: 'yellow' }} />} title="Ranking">
        <Link to="/shop">Upgrade</Link>
      </Menu.Item>
     
            
            
            {/* Outros itens do menu */}
          </Menu>
        </Drawer>
      </Header>
  
      {/* Conteúdo Principal */}
      <Layout>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            {/* Seção da Esquerda - Carros e Filtros */}
            <Content style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
              {/* Filtros */}
              <div
                style={{
                  marginBottom: '24px',
                  backgroundColor: '#fff',
                  padding: '24px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Form
                  layout="inline"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <Form.Item label="Marca" style={{ marginBottom: '8px' }}>
                    <Select
                      placeholder="Selecione a marca"
                      style={{ width: 200 }}
                      value={filters.marca}
                      onChange={(value) => handleFilterChange('marca', value)}
                    >
                      <Option value="">Todas</Option>
                      {[...new Set(showroomData.map((car) => car.marca))].map((marca) => (
                        <Option key={marca} value={marca}>
                          {marca}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Tipo" style={{ marginBottom: '8px' }}>
                    <Select
                      placeholder="Selecione o tipo"
                      style={{ width: 200 }}
                      value={filters.tipo}
                      onChange={(value) => handleFilterChange('tipo', value)}
                    >
                      <Option value="">Todos</Option>
                      {[...new Set(showroomData.map((car) => car.tipo))].map((tipo) => (
                        <Option key={tipo} value={tipo}>
                          {tipo}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </div>
  
              {/* Grid de Carros */}
            
              <div>
                <Row gutter={[16, 16]} justify="start">
                  {showroomData.map((car) => (
                    <Col key={car.carID} xs={12} sm={12} md={8} lg={6}>
                      <Tooltip
                        title={
                          <div>
                            <p><strong>Ano:</strong> {car.ano}</p>
                            <p><strong>Valor:</strong> R${car.valor.toLocaleString()}</p>
                            <p><strong>Km:</strong> {car.km.toLocaleString()}</p>
                            <p><strong>Tipo:</strong> {car.tipo}</p>
                            <p><strong>Potência:</strong> {car.potencia}cv</p>
                          </div>
                        }
                      >
                        <div
                          style={{
                            position: 'relative',
                            paddingBottom: '100%',
                            overflow: 'hidden',
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow =
                              '0 4px 12px rgba(21, 80, 240, 0.8)';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow =
                              '0 2px 8px rgba(0, 0, 0, 0.1)';
                          }}
                          onClick={() => handleCarClick(car)}
                        >
                          <img
                            src={
                              car.imagens && car.imagens.length > 0
                                ? car.imagens[0]
                                : 'https://via.placeholder.com/150'
                            }
                            alt={car.modelo}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Col>
                  ))}
  
                  {/* Placeholders para slots vazios */}
                  
                  {Array.from({ length: playerSlots - showroomData.length }).map(
                    (_, index) => (
                      <Col key={`placeholder-${index}`} xs={12} sm={12} md={8} lg={6}>
                        <div
                          style={{
                            position: 'relative',
                            paddingBottom: '50%',
                            overflow: 'hidden',
                            borderRadius: '8px',
                            border: '2px dashed #cccccc',
                            backgroundColor: '#f9f9f9',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '175px',
                          }}
                        >
                          <div style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '16px', color: '#d5d5d5' }}>
                              Vaga Disponível
                            </p>
                          </div>
                        </div>
                      </Col>
                    )
                  )}
  
                  
                </Row>
  
                {hasMoreCars && (
                  <div style={{ textAlign: 'center', marginTop: '24px' }}>
                    <Button onClick={() => fetchShowroom()} loading={loadingCars}>
                      Carregar Mais
                    </Button>
                  </div>
                )}
              </div>
            </Content>
          </Col>
  
          <Col xs={24} md={8}>
            {/* Seção da Direita - Clientes */}
            <div
              style={{
                padding: '24px',
                backgroundColor: '#fff',
                overflowY: 'auto',
                height: '100%',
              }}
            >
              {/* Filtros de Clientes */}
              <div style={{ marginBottom: '24px' }}>
                <Select
                  placeholder="Filtrar por Marca"
                  style={{ width: '100%', marginBottom: '16px' }}
                  value={marcaFilter}
                  onChange={handleMarcaChange}
                >
                  <Option value="">Marcas</Option>
                  {[...new Set(visibleClients.map((client) => client.marca))].map((marca) => (
                    <Option key={marca} value={marca}>
                      {marca}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Filtrar por Tipo"
                  style={{ width: '100%',  marginBottom: '16px' }}
                  value={tipoFilter}
                  onChange={handleTipoChange}
                >
                  <Option value="">Carrocerias</Option>
                  {[...new Set(visibleClients.map((client) => client.tipo))].map((tipo) => (
                    <Option key={tipo} value={tipo}>
                      {tipo}
                    </Option>
                  ))}
                </Select>
                <Select
  placeholder="Filtrar por Nível"
  style={{ width: '100%' }}
  value={nivelFilter}
  onChange={handleNivelChange}
>
  <Option value="">Nível</Option>
  {[...new Set(visibleClients.map((client) => client.nivel))].map((nivel) => (
    <Option key={nivel} value={nivel}>
      {nivel}
    </Option>
  ))}
</Select>
              </div>
  
              {/* Lista de Clientes Paginados */}
              {currentClients.map((client) => (
                <Card
                  key={client.id}
                  style={{
                    marginBottom: '16px',
                    cursor: 'pointer',
                    border:
                      selectedClient?.id === client.id
                        ? '3px solid #a1a1a1'
                        : '1px solid #f0f0f0',
                    transition: 'border 0.3s ease',
                  }}
                  onClick={() => handleClientSelect(client.id)}
                  hoverable
                >
                  <div>
                    <Tag color="black" style={{ fontSize: '12px' }}>
                      Nível {client.nivel}
                    </Tag>
                  </div>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                      marginBottom: '12px',
                      display: 'block',
                      color: '#202020',
                    }}
                  >
                    {client.nome}
                  </Text>
                  <div style={{ marginBottom: '8px' }}>
                    <Text style={{ fontSize: '14px', color: '#555' }}>
                      Marca preferida: <strong>{client.marca}</strong>
                    </Text>
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <Text style={{ fontSize: '14px', color: '#555' }}>
                      Carroceria: <strong>{client.tipo}</strong>
                    </Text>
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <Text style={{ fontSize: '14px', color: '#555' }}>
                      Ano Mínimo: <strong>{client.anoMin}</strong>
                    </Text>
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <Text style={{ fontSize: '14px', color: '#555' }}>
                      Km: <strong>{client.km}</strong>
                    </Text>
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <Text style={{ fontSize: '14px', color: '#555' }}>
                      Potência Mín.: <strong>{client.potenciaMin}</strong>
                    </Text>
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <Text style={{ fontSize: '14px', color: '#555' }}>
                      Valor: <strong>R$ {client.valorMax}</strong>
                    </Text>
                  </div>
                  {/* Demais informações do cliente */}
                </Card>
              ))}
  
              {/* Componente de Paginação */}
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={filteredClients.length}
                onChange={handlePageChange}
                style={{ textAlign: 'center', marginTop: '24px' }}
                showSizeChanger={false}
                showQuickJumper
              />
            </div>
          </Col>
        </Row>
      </Layout>
  
      {/* Modais e Componentes Flutuantes */}
      {selectedCar && (
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          width={800}
          centered
        >
          <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={12}>
              {selectedCar.imagens && selectedCar.imagens.length > 0 ? (
                <Carousel autoplay>
                  {selectedCar.imagens.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`${selectedCar.marca} ${selectedCar.modelo}`}
                        style={{
                          width: '100%',
                          height: '300px',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '300px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography.Text>Imagem Indisponível</Typography.Text>
                </div>
              )}
            </Col>
            <Col span={12}>
              <Title level={4}>
                {selectedCar ? `${selectedCar.marca} ${selectedCar.modelo}` : 'Vaga Disponível'}
              </Title>
              {selectedCar && (
                <>
                  <Text>Ano: {selectedCar.ano || 'N/A'}</Text>
                  <br />
                  <Text>Km: {selectedCar.km || 'N/A'}</Text>
                  <br />
                  <Text>Tipo: {selectedCar.tipo || 'N/A'}</Text>
                  <br />
                  <Text>Potência: {selectedCar.potencia || 'N/A'}</Text>
                  <br />
                  <Text>
                    Valor:{' '}
                    {selectedCar.valor
                      ? `R$ ${selectedCar.valor.toLocaleString('pt-BR')}`
                      : 'Indisponível'}
                  </Text>
                  <br />
                  <br />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Button
                      type="primary"
                      onClick={showConfirmDialog}
                      style={{ marginRight: '8px', backgroundColor: '#1550F0' }}
                    >
                      Vender
                    </Button>
                    <Button onClick={closeModal} style={{ marginRight: '8px', borderColor: '#d9d9d9' }}>
                      Cancelar
                    </Button>
                    <Button
                      type="default"
                      style={{ borderColor: '#d9d9d9', color: '#555' }}
                      onClick={handleAddToCollection}
                    >
                      Adicionar à Coleção
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={() => openQuickSellModal(selectedCar)}
                    >
                      Venda Rápida
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Modal>
      )}
  
  <Modal
        open={confirmVisible}
        onCancel={hideConfirmDialog}
        onOk={confirmSale}
        centered
        okText="Confirmar"
        cancelText="Cancelar"
        confirmLoading={isProcessing}
      >
        <Title level={4}>Confirmação de Venda</Title>
        <Text>
          Deseja vender {selectedCar?.marca} {selectedCar?.modelo} para{' '}
          {selectedClient?.nome} por{' '}
          <strong>
            R$ {(selectedCar?.valor * (1 + profitPercentage)).toLocaleString('pt-BR')}
          </strong>
          ?
        </Text>
      </Modal>

      <Modal
        open={feedbackVisible}
        onCancel={closeFeedbackModal}
        footer={null}
        centered
      >
        <Title level={4} style={{ color: '#52c41a' }}>
          Parabéns pela venda!
        </Title>
        {selectedCar ? (
          <Text>
            Você vendeu {selectedCar.marca} {selectedCar.modelo} por{' '}
            <strong>
              R$ {salePrice.toLocaleString('pt-BR')}
            </strong>{' '}
            e lucrou{' '}
            <strong>
              R$ {profit.toLocaleString('pt-BR')}
            </strong>{' '}
            nessa transação!
          </Text>
        ) : (
          <Text>Detalhes do carro não disponíveis.</Text>
        )}

        <br />
        <Button
          type="primary"
          onClick={closeFeedbackModal}
          style={{ backgroundColor: '#202020' }}
        >
          Fechar
        </Button>
      </Modal>

    </Layout>
  );
  
}
export default Showroom;
