// WelcomeScreen.jsx
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Modal, Button, message, Tabs } from 'antd';
import {
  WhatsAppOutlined,
  DashboardOutlined,
  VideoCameraOutlined,
  DownloadOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);

  // Função para abrir o modal do tutorial
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Função para fechar o modal
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Funções nomeadas para os event listeners
  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault(); // Impede o prompt automático
    console.log('Evento beforeinstallprompt capturado');
    setDeferredPrompt(e); // Armazena o evento para uso futuro
    message.info('Opção de instalação disponível!');
  };

  const handleAppInstalled = () => {
    console.log('Jogo foi instalado');
    setIsPwaInstalled(true);
    setDeferredPrompt(null);
    message.success('Jogo instalado com sucesso!');
  };

  // Captura o evento beforeinstallprompt e appinstalled
  useEffect(() => {
    console.log('Monitorando eventos beforeinstallprompt e appinstalled');

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    // Detecta se o PWA já está instalado
    const isInStandaloneMode = () => {
      return (
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone === true
      );
    };

    const installed = isInStandaloneMode();
    setIsPwaInstalled(installed);
    if (installed) {
      console.log('PWA já está instalado');
    } else {
      console.log('PWA não está instalado');
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  // Função para acionar o prompt de instalação
  const handleInstallClick = async () => {
    if (deferredPrompt) {
      console.log('Exibindo o prompt de instalação');
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('Usuário aceitou a instalação');
        message.success('Instalação iniciada!');
      } else {
        console.log('Usuário recusou a instalação');
        message.info('Instalação cancelada.');
      }
      setDeferredPrompt(null); // Reseta o prompt após a interação
    } else {
      console.log('Deferred prompt não está disponível no momento');
      message.error('Recarregue a página e tente novamente');
    }
  };

  // Dados dos cards para a aba "O jogo"
  const gameCards = [
    {
      key: 'whatsapp',
      icon: <WhatsAppOutlined style={{ fontSize: '20px', color: '#25D366' }} />, // Ícone menor
      title: 'Comunidade no Whatsapp',
      subtitle: 'Junte-se ao grupo no Whatsapp para novidades e suporte!',
      onClick: () => {
        window.open('https://chat.whatsapp.com/HbvHWLm3GohG4gr2d23p2H', '_blank', 'noopener noreferrer');
      },
    },
    {
      key: 'dashboard',
      icon: <DashboardOutlined style={{ fontSize: '20px', color: '#1890ff' }} />,
      title: 'Jogar agora',
      subtitle: 'Lucre bilhões, domine o rank e monte a garagem mais absurda do jogo.',
      onClick: () => {
        navigate('/dashboard');
      },
    },
    {
      key: 'tutorial',
      icon: <VideoCameraOutlined style={{ fontSize: '20px', color: '#FF4D4F' }} />,
      title: 'Tutorial de 2 minutos',
      subtitle: 'Assista a um tutorial rápido para aprender como maximizar seu desempenho no jogo.',
      onClick: showModal,
    },
    // Novo card para instalação
    {
      key: 'install',
      icon: <DownloadOutlined style={{ fontSize: '20px', color: '#52c41a' }} />,
      title: 'Instalar o Jogo',
      subtitle: 'Instale o jogo no PC ou celular para uma experiência superior. (6 minutos)',
      onClick: handleInstallClick,
      disabled: isPwaInstalled, // Apenas desativa se o PWA já estiver instalado
    },
  ];

  // Dados para a aba "YouTube"
  const youtubeVideos = [
    {
      id: 'kDxmePHrXo8LBTaw',
      thumbnail: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/R.jpg?alt=media&token=6adc18c3-ace9-40c8-b8d3-933228c623f4',
      title: 'A Bugatti fez um relógio que vale 5 FERRARIS!',
      description: 'Descubra o Bugatti Tourbillon Chiron de $2 milhões criado para Manny Khoshbin: um relógio de luxo com motor W16 em miniatura, uma obra-prima em cada detalhe!',
      link: 'https://youtu.be/HVQ6gbVZ1h4?si=kDxmePHrXo8LBTaw',
    },
   
  ];

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f0f2f5',
      padding: '20px',
      boxSizing: 'border-box',
      fontFamily: "'Plus Jakarta Sans', sans-serif", // Aplicando a fonte
    }}>
      <div style={{ width: '100%', maxWidth: '1200px' }}>
        {/* Logo do Jogo */}
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <a href="/" rel="noopener noreferrer">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl5.png?alt=media&token=2fd15d80-371a-43a4-93a6-3b73409f7d68"
              alt="Logo do Jogo"
              style={{
                width: '30%',
                maxWidth: '400px',
                height: 'auto',
              }}
            />
          </a>
        </div>

        {/* Abas */}
        <Tabs defaultActiveKey="1" centered size="large">
          {/* Aba "O jogo" */}
          <TabPane
            tab={
              <span>
                <DashboardOutlined />
                Jogar
              </span>
            }
            key="1"
          >
            {/* Cards da aba "O jogo" */}
            <Row gutter={[16, 16]} justify="center"> {/* Gutter menor para cards menores */}
              {gameCards.map((card) => (
                <Col key={card.key} xs={24} sm={12} md={6}>
                  <Card
                    hoverable={!card.disabled}
                    onClick={!card.disabled ? card.onClick : null}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: card.disabled ? 'not-allowed' : 'pointer',
                      borderRadius: '8px', // Bordas levemente menores
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Sombra mais sutil
                      padding: '12px', // Padding reduzido
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      opacity: card.disabled ? 0.6 : 1,
                      backgroundColor: '#fff',
                    }}
                    bodyStyle={{ padding: 0 }}
                    onMouseEnter={(e) => {
                      if (!card.disabled) {
                        e.currentTarget.style.transform = 'translateY(-3px)'; // Movimento menor
                        e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.15)';
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (!card.disabled) {
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.1)';
                      }
                    }}
                  >
                    {/* Ícone e Texto alinhados horizontalmente */}
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: '1',
                      gap: '12px', // Espaçamento entre ícone e texto
                    }}>
                      {/* Ícone */}
                      <div style={{
                        flexShrink: 0, // Impede que o ícone encolha
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        {card.icon}
                      </div>
                      {/* Título e Subtítulo */}
                      <div>
                        <Title level={5} style={{ margin: 0, fontWeight: 600 }}>
                          {card.title}
                        </Title>
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                          {card.subtitle}
                        </Text>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </TabPane>

          
        </Tabs>
      </div>

      {/* Modal do Tutorial */}
      <Modal
        title="Tutorial"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            Fechar
          </Button>,
        ]}
        centered
        width={800}
      >
        <div style={{ position: 'relative', paddingBottom: '56.25%', paddingTop: '25px', height: 0 }}>
          <iframe
            title="Tutorial de 2 Minutos"
            src="https://www.youtube.com/embed/kaXhJui1oPU" // Substitua pelo ID do seu vídeo
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};

export default WelcomeScreen;
