import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { collection, setDoc, getDocs, doc } from 'firebase/firestore';
import { firestore, storage } from './firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const { Option } = Select;

const CarForm = () => {
  const [form] = Form.useForm();
  const [stores, setStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [interiorFiles, setInteriorFiles] = useState([]); // Fotos do interior
  const [coverFile, setCoverFile] = useState(null); // Input para a foto de capa
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    setLoadingStores(true);
    try {
      const storesCollection = await getDocs(collection(firestore, 'stores'));
      const storesList = storesCollection.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setStores(storesList);
      setLoadingStores(false);
    } catch (error) {
      message.error('Erro ao buscar lojas');
      setLoadingStores(false);
    }
  };

  const uploadImagesAndGetURLs = async (marca, modelo, coverFile, fileList, interiorFiles) => {
    const imageUrls = [];
    try {
      // Upload da capa
      if (coverFile) {
        const coverRef = ref(storage, `Brands/${marca}/${modelo}/cover_${coverFile.name}`);
        const fileToUpload = coverFile.originFileObj || coverFile; 
        await uploadBytes(coverRef, fileToUpload);
        const coverURL = await getDownloadURL(coverRef);
        imageUrls.push(coverURL); 
      }

      // Upload das fotos do interior, garantindo a ordem correta nas posições 1 e 2
      if (interiorFiles.length >= 2) {
        for (let i = 0; i < 2; i++) {
          const interiorRef = ref(storage, `Brands/${marca}/${modelo}/interior_${i + 1}_${interiorFiles[i].name}`);
          const fileToUpload = interiorFiles[i].originFileObj || interiorFiles[i]; 
          await uploadBytes(interiorRef, fileToUpload);
          const downloadURL = await getDownloadURL(interiorRef);
          imageUrls.push(downloadURL); 
        }
      } else {
        throw new Error("São necessárias pelo menos 2 imagens de interior.");
      }

      // Upload das outras imagens
      const otherImagesPromises = fileList.map(async (file) => {
        const storageRef = ref(storage, `Brands/${marca}/${modelo}/${file.name}`);
        const fileToUpload = file.originFileObj || file; 
        await uploadBytes(storageRef, fileToUpload);
        const downloadURL = await getDownloadURL(storageRef);
        imageUrls.push(downloadURL);
      });

      await Promise.all(otherImagesPromises);
    } catch (error) {
      console.error("Erro ao carregar imagens: ", error);
      throw new Error("Falha no upload das imagens");
    }

    return imageUrls;
  };

  const generateRandomId = (modelo) => {
    const randomId = Math.floor(10000 + Math.random() * 90000); // Gera um número aleatório de 5 dígitos
    return `${modelo}-${randomId}`; // Concatena o modelo com o número aleatório
  };

  const onFinish = async (values) => {
    const { storeId, marca, modelo, ano, km, potencia, valor, tipo } = values;
    const carData = {
      marca,
      modelo,
      ano: parseInt(ano),
      km: parseInt(km),
      potencia: parseInt(potencia),
      valor: parseFloat(valor),
      tipo,
      storeId, 
    };
  
    if (!coverFile) {
      message.error('Por favor, adicione a imagem de capa.');
      return;
    }
  
    setUploading(true);
    try {
      // Upload das imagens e obtenção dos URLs
      const imageUrls = await uploadImagesAndGetURLs(marca, modelo, coverFile, fileList, interiorFiles);
      carData.imagens = imageUrls;
  
      // Gerar um ID aleatório para evitar duplicidade de modelos iguais
      const randomId = generateRandomId(modelo);
  
      // Adicionar o carro ao Firestore na coleção 'market_cars' com o 'modelo + ID' como ID do documento
      const carDocRef = doc(firestore, 'market_cars', randomId);
      await setDoc(carDocRef, carData);
  
      message.success('Carro adicionado com sucesso!');
      form.resetFields();
      setFileList([]);  
      setInteriorFiles([]); 
      setCoverFile(null);  
    } catch (error) {
      message.error('Erro ao adicionar carro: ' + error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleCoverChange = ({ file }) => {
    setCoverFile(file.originFileObj || file); 
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleInteriorChange = ({ fileList }) => {
    setInteriorFiles(fileList);
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '24px', background: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '24px' }}>Adicionar Novo Carro</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="storeId"
          label="Selecionar Loja"
          rules={[{ required: true, message: 'Por favor, selecione uma loja' }]}
        >
          <Select placeholder="Selecione a loja" loading={loadingStores}>
            {stores.map(store => (
              <Option key={store.id} value={store.id}>
                {store.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="marca"
          label="Marca"
          rules={[{ required: true, message: 'Por favor, insira a marca' }]}
        >
          <Input placeholder="Ex: Ferrari" />
        </Form.Item>

        <Form.Item
          name="modelo"
          label="Modelo"
          rules={[{ required: true, message: 'Por favor, insira o modelo' }]}
        >
          <Input placeholder="Ex: F8 Tributo" />
        </Form.Item>

        <Form.Item
          name="ano"
          label="Ano"
          rules={[{ required: true, message: 'Por favor, insira o ano' }]}
        >
          <Input placeholder="Ex: 2020" />
        </Form.Item>

        <Form.Item
          name="km"
          label="Quilometragem"
          rules={[{ required: true, message: 'Por favor, insira a quilometragem' }]}
        >
          <Input placeholder="Ex: 12000" />
        </Form.Item>

        <Form.Item
          name="potencia"
          label="Potência"
          rules={[{ required: true, message: 'Por favor, insira a potência (cv)' }]}
        >
          <Input placeholder="Ex: 720" />
        </Form.Item>

        <Form.Item
          name="valor"
          label="Preço (Valor)"
          rules={[{ required: true, message: 'Por favor, insira o valor do carro' }]}
        >
          <Input placeholder="Ex: 1200000.50" />
        </Form.Item>

        <Form.Item
          name="tipo"
          label="Carroceria"
          rules={[{ required: true, message: 'Por favor, selecione o tipo de carroceria' }]}
        >
          <Select placeholder="Selecione o tipo de carroceria">
            <Option value="SUV">SUV</Option>
            <Option value="Coupe">Coupe</Option>
            <Option value="Conversível">Conversível</Option>
            <Option value="Pickup">Pickup</Option>
            <Option value="Hatch">Hatch</Option>
            <Option value="Hatchback">Hatchback</Option>
            <Option value="Perua">Perua</Option>
            <Option value="Sedan">Sedan</Option>
            <Option value="Barchetta">Barchetta</Option>
          </Select>
        </Form.Item>
        {/* Upload exclusivo para a foto de capa */}
        <Form.Item
          label="Imagem de Capa"
          rules={[{ required: true, message: 'Por favor, insira a imagem de capa' }]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={handleCoverChange}
            maxCount={1}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Selecionar Capa</Button>
          </Upload>
        </Form.Item>

        {/* Upload das fotos do interior */}
        <Form.Item
          label="Fotos do Interior"
          rules={[{ required: true, message: 'Por favor, insira pelo menos duas imagens do interior' }]}
        >
          <Upload
            fileList={interiorFiles}
            beforeUpload={() => false}
            onChange={handleInteriorChange}
            multiple
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Selecionar Fotos do Interior</Button>
          </Upload>
        </Form.Item>

        {/* Upload das demais imagens */}
        <Form.Item
          label="Outras Imagens do carro"
          rules={[{ required: true, message: 'Por favor, insira pelo menos uma imagem' }]}
        >
          <Upload
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleUploadChange}
            multiple
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Selecionar Outras Imagens</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={uploading} block>
            Adicionar Carro
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CarForm;