// src/components/LuxuryCarDetail.js

import React, { useState, useMemo } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  Carousel,
  Divider,
  Descriptions,
  message,
} from 'antd';
import { GoldOutlined } from '@ant-design/icons';
import {
  getFirestore,
  doc,
  collection,
  runTransaction,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import PropTypes from 'prop-types';

const { Title, Text, Paragraph } = Typography;

const LuxuryCarDetail = ({ car }) => {
  const [buying, setBuying] = useState(false);
  const db = getFirestore();
  const auth = getAuth();

  // Função de conversão memoizada
  const convertGoldToReal = useMemo(
    () => (goldValue) => goldValue * 10000,
    []
  );

  // Função para converter URL tradicional do YouTube para URL de embed
  const getYouTubeEmbedURL = useMemo(() => {
    return (url) => {
      if (!url) return null;
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/;
      const match = url.match(regex);
      if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=0`;
      }
      return null;
    };
  }, []);

  // Validação inicial dos dados do carro
  if (
    !car ||
    !car.marca ||
    !car.modelo ||
    typeof car.goldvalue !== 'number' ||
    !car.imagens ||
    !Array.isArray(car.imagens)
  ) {
    return <div>Dados do carro indisponíveis.</div>;
  }

  const realValue = convertGoldToReal(car.goldvalue);
  const resellValue = realValue * 5; 

  const embedURL = getYouTubeEmbedURL(car.videoURL);

  const handleBuy = async () => {
    const user = auth.currentUser;
    if (!user) {
      message.error('Você precisa estar autenticado para comprar um carro VIP.');
      return;
    }

    setBuying(true);

    const playerDocRef = doc(db, 'players', user.uid);
    const colecaoVipCollectionRef = collection(
      db,
      'players',
      user.uid,
      'colecaovip'
    );

    try {
      await runTransaction(db, async (transaction) => {
        const playerDoc = await transaction.get(playerDocRef);
        if (!playerDoc.exists()) {
          throw new Error('Jogador não encontrado.');
        }

        const playerData = playerDoc.data();
        const currentGold = playerData.gold || 0;

        if (currentGold < car.goldvalue) {
          throw new Error('Saldo insuficiente para comprar este carro VIP.');
        }

        // Subtrai o valor do ouro e atualiza o documento do jogador
        transaction.update(playerDocRef, {
          gold: currentGold - car.goldvalue,
        });

        // Gera um novo ID de documento para cada compra
        const newCarDocRef = doc(colecaoVipCollectionRef);

        // Adiciona o carro à subcoleção "colecaopessoal"
        transaction.set(newCarDocRef, {
          ...car,
          purchasedAt: new Date().toISOString(), // Adiciona data de compra
        });
      });

      message.success(
        `Você comprou o carro VIP ${car.marca} ${car.modelo} com sucesso!`
      );
      // Opcional: Atualizar dados do usuário ou redirecionar
    } catch (error) {
      console.error('Erro ao comprar carro VIP:', error);
      if (error.message.includes('Saldo insuficiente')) {
        message.error(error.message);
      } else {
        message.error(
          'Erro ao comprar carro VIP. Tente novamente mais tarde.'
        );
      }
    } finally {
      setBuying(false);
    }
  };

  return (
    <div style={styles.container}>
      {/* Seção de Imagens e Descrição */}
      <Row gutter={[24, 24]}>
        {/* Coluna das Imagens */}
        <Col xs={24} sm={24} md={24}>
          {car.imagens && car.imagens.length > 0 ? (
            <Carousel autoplay dotPosition="bottom">
              {car.imagens.map((image, index) => (
                <div key={index} style={styles.carouselItem}>
                  <img
                    loading="lazy"
                    src={image}
                    alt={`Imagem ${index + 1} do ${car.marca} ${car.modelo}`}
                    style={styles.carImage}
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <div style={styles.imagePlaceholder}>
              <Text>Imagem Indisponível</Text>
            </div>
          )}
        </Col>

        {/* Coluna das Informações do Carro */}
        <Col xs={24} sm={24} md={24}>
          <div style={styles.introduction}>
            <Title level={2} style={styles.carTitle}>
              {car.marca} {car.modelo}
            </Title>

            <Divider style={styles.divider} />
            <Paragraph style={styles.description}>{car.desc1}</Paragraph>
            <Button
              type="primary"
              icon={<GoldOutlined />}
              onClick={handleBuy}
              disabled={buying}
              loading={buying}
              block
              style={{
                ...styles.buyButton,
                backgroundColor: '#FFD700',
                borderColor: '#FFD700',
                color: '#000',
              }}
              aria-label={`Comprar ${car.marca} ${car.modelo} por ${car.goldvalue.toLocaleString(
                'pt-BR'
              )} Ouro`}
            >
              Comprar por {car.goldvalue.toLocaleString('pt-BR')} Ouro
            </Button>
          </div>
        </Col>
      </Row>

      {/* Seção do Vídeo do YouTube */}
      {embedURL && (
        <>
          <Divider orientation="left" style={styles.divider}>
            Vídeo do Carro
          </Divider>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <div style={styles.videoContainer}>
                <iframe
                  src={embedURL}
                  title={`${car.marca} ${car.modelo} Vídeo`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={styles.videoIframe}
                ></iframe>
              </div>
            </Col>
          </Row>
        </>
      )}

      {/* Seção da Ficha Técnica */}
      <Divider orientation="left" style={styles.divider}>
        Ficha Técnica
      </Divider>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12}>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Marca">{car.marca}</Descriptions.Item>
            <Descriptions.Item label="Modelo">{car.modelo}</Descriptions.Item>
            <Descriptions.Item label="Ano">{car.ano}</Descriptions.Item>
            <Descriptions.Item label="Km">
              {car.km.toLocaleString('pt-BR')} km
            </Descriptions.Item>
            <Descriptions.Item label="Carroceria">{car.tipo}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} sm={12}>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Potência">
              {car.potencia} cv
            </Descriptions.Item>
            <Descriptions.Item label="Valor">
              💰 {car.goldvalue.toLocaleString('pt-BR')} Ouro
            </Descriptions.Item>
            <Descriptions.Item label="Influência">
              {car.influence}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      {/* Seção de História e Exclusividade */}
      <Divider orientation="left" style={styles.divider}>
        História e Exclusividade
      </Divider>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Paragraph style={styles.carStory}>{car.desc2}</Paragraph>
          <Paragraph style={styles.resellMessage}>
            Você poderá revender esse carro por até{' '}
            <strong>{resellValue.toLocaleString('pt-BR')} milhões de reais</strong> para
            outro jogador.
          </Paragraph>
        </Col>
      </Row>

      {/* Seção de Depoimentos */}
      {car.testimonials && car.testimonials.length > 0 && (
        <>
          <Divider orientation="left" style={styles.divider}>
            O que dizem sobre este carro
          </Divider>
          <Row gutter={[24, 24]}>
            {car.testimonials.map((testimonial, index) => (
              <Col key={index} xs={24} sm={12}>
                <div style={styles.testimonial}>
                  <Text strong>"{testimonial.quote}"</Text>
                  <br />
                  <Text>- {testimonial.author}</Text>
                </div>
              </Col>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: `'Helvetica Neue', Helvetica, Arial, sans-serif`,
    color: '#333',
    padding: '16px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  carouselItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    height: '400px', // Altura ajustada para melhor visualização
    position: 'relative',
    margin: '0 16px', // Margens horizontais
  },
  carImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  imagePlaceholder: {
    width: '100%',
    height: '400px', // Altura consistente com o carrossel
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#777',
    fontSize: '18px',
    margin: '0 16px', // Margens horizontais
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    paddingTop: '25px',
    height: 0,
    width: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  introduction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0 16px',
  },
  carTitle: {
    fontSize: '28px',
    marginBottom: '8px',
    color: '#1c1c1c',
    wordBreak: 'break-word',
  },
  divider: {
    borderTop: '2px solid #eee',
    margin: '24px 0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
    wordBreak: 'break-word',
  },
  buyButton: {
    transition: 'background-color 0.3s, border-color 0.3s',
    marginTop: '16px',
    width: '200px',
  },
  carStory: {
    fontSize: '16px',
    color: '#555',
    wordBreak: 'break-word',
  },
  resellMessage: {
    fontSize: '16px',
    color: '#555',
    marginTop: '16px',
    wordBreak: 'break-word',
  },
  testimonial: {
    backgroundColor: '#fafafa',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

LuxuryCarDetail.propTypes = {
  car: PropTypes.shape({
    id: PropTypes.string.isRequired, // ID único do carro
    marca: PropTypes.string.isRequired,
    modelo: PropTypes.string.isRequired,
    goldvalue: PropTypes.number.isRequired,
    imagens: PropTypes.arrayOf(PropTypes.string).isRequired,
    desc1: PropTypes.string,
    desc2: PropTypes.string,
    videoURL: PropTypes.string,
    ano: PropTypes.number,
    km: PropTypes.number,
    tipo: PropTypes.string,
    potencia: PropTypes.number,
    influence: PropTypes.number,
    testimonials: PropTypes.arrayOf(
      PropTypes.shape({
        quote: PropTypes.string,
        author: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default LuxuryCarDetail;
