import React, { createContext, useState, useEffect, useContext } from 'react';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { message } from 'antd';
import { getAuth } from 'firebase/auth';

// Contexto de Nível
const LevelContext = createContext();

// Provedor de Nível
export const LevelProvider = ({ children }) => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);

  const auth = getAuth();

  useEffect(() => {
    const fetchPlayerData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const playerDoc = await getDoc(doc(firestore, 'players', user.uid));
          if (playerDoc.exists()) {
            setPlayerData(playerDoc.data());
          } else {
            message.error('Não foi possível carregar os dados do jogador.');
          }
        } catch (error) {
          message.error('Erro ao buscar dados do jogador.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPlayerData();
  }, [auth]);

  const updatePlayerLevel = async () => {
    if (!playerData || !playerData.metrics) return;

    const currentLevel = playerData.level || 1;
    const totalSales = playerData.metrics.totalSales || 0;
    const totalProfit = playerData.metrics.totalProfit || 0;
    const totalRevenue = playerData.metrics.totalRevenue || 0;

    // Regras de Nível
    const levelRules = [
      { level: 2, salesRequired: 3, totalProfitRequired: 50000, revenueRequired: 450000 },
      { level: 3, salesRequired: 6, totalProfitRequired: 100000, revenueRequired: 900000 },
      { level: 4, salesRequired: 10, totalProfitRequired: 200000, revenueRequired: 1500000 },
      { level: 5, salesRequired: 15, totalProfitRequired: 350000, revenueRequired: 3000000 },
      { level: 6, salesRequired: 13, totalProfitRequired: 350, revenueRequired: 300 },
    ];

    const nextLevelRule = levelRules.find((rule) => rule.level === currentLevel + 1);

    if (nextLevelRule && 
      totalSales >= nextLevelRule.salesRequired && 
      totalProfit >= nextLevelRule.totalProfitRequired && 
      totalRevenue >= nextLevelRule.revenueRequired) {
      try {
        await updateDoc(doc(firestore, 'players', auth.currentUser.uid), {
          level: currentLevel + 1,
        });
        message.success(`Você subiu para o nível ${currentLevel + 1}!`);
        setPlayerData({ ...playerData, level: currentLevel + 1 });
      } catch (error) {
        message.error('Erro ao atualizar o nível.');
      }
    }
  };

  return (
    <LevelContext.Provider value={{ playerData, updatePlayerLevel, loading }}>
      {children}
    </LevelContext.Provider>
  );
};

// Hook personalizado para acessar o contexto
export const useLevel = () => {
  return useContext(LevelContext);
};
