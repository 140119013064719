import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const WarningCard = ({ message = "Atualização grátis em 05/01/2025 adicionará mais 100 novos carros e milhares de  novos clientes. " }) => {
  const cardStyle = {
    margin: '10px',
    padding: '10px',
    borderRadius: '8px',
    background: '#FFF8E1',
    border: '1px solid #FFD740',
    display: 'flex',
    alignItems: 'center',
    fontFamily: '"Plus Jakarta Sans", sans-serif',
    maxWidth: '100%',
    boxSizing: 'border-box',
  };

  const iconStyle = {
    fontSize: '20px',
    color: '#FFC107',
    marginRight: '8px',
  };

  const textStyle = {
    fontSize: '16px',
    color: '#333',
    lineHeight: '1.4',
    wordWrap: 'break-word',
  };

  return (
    <div style={cardStyle}>
      <ExclamationCircleOutlined style={iconStyle} />
      <span style={textStyle}>{message}</span>
    </div>
  );
};

export default WarningCard;
