import React, { useState } from 'react';
import {
  Layout,
  Menu,
  Button,
  Typography,
  Row,
  Col,
  Card,
  Carousel,
  Input,
  Divider,
  Grid,
  Drawer,
} from 'antd';
import {
  MenuOutlined,
} from '@ant-design/icons';
import { color, motion } from 'framer-motion';

const { Header, Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

// Estilos personalizados
const styles = {
  header: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    background: '#ffffff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '0 50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px',
    boxSizing: 'border-box',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImage: {
    height: '40px',
    marginRight: '20px',
  },
  menu: {
    flex: 1,
    marginLeft: '50px',
  },
  menuItem: {
    fontSize: '16px',
    marginRight: '20px',
    fontFamily: "'Inter', sans-serif",
  },
  hero: {
    backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2Fe213211313.jpg?alt=media&token=b9d16c6f-ec11-4f33-800d-c4d408151fcf")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '200px 50px',
    textAlign: 'center',
    color: '#ffffff',
  },
  heroTitle: {
    fontSize: '50px',
    fontWeight: '800',
    marginBottom: '20px',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
    color: '#fff',
  },
  heroParagraph: {
    fontSize: '20px',
    marginBottom: '60px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: "'Inter', sans-serif",
    color: '#fff',
  },
  heroButton: {
    padding: '15px 40px',
    fontSize: '18px',
    borderRadius: '30px',
    fontFamily: "'Inter', sans-serif",
  },
  section: {
    padding: '100px 50px',
    background: '#ffffff',
  },
  sectionAlternate: {
    padding: '100px 50px',
    background: '#f9f9f9',
  },
  sectionTitle: {
    textAlign: 'center',
    marginBottom: '50px',
    fontSize: '40px',
    fontWeight: '800',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  featureCard: {
    border: 'none',
    textAlign: 'center',
    background: 'transparent',
  },
  featureIcon: {
    fontSize: '60px',
    marginBottom: '20px',
    color: '#1890ff',
  },
  galleryImage: {
    width: '100%', // largura padrão para telas grandes
    height: '450px',
    width: '300px',
    objectFit: 'cover',
    marginBottom: '20px',
    borderRadius: '12px',
    '@media (max-width: 900px)': { // Media query para telas menores que 768px (mobile)
      width: '450px', // largura fixa de 400px para mobile
      height: '600px', // altura ajustada para manter a proporção da imagem
    }
  },
  testimonial: {
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px',
  },
  testimonialParagraph: {
    fontSize: '20px',
    fontStyle: 'italic',
    marginBottom: '20px',
    lineHeight: '1.6',
    fontFamily: "'Inter', sans-serif",
  },
  testimonialAuthor: {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif",
  },
  videoSection: {
    textAlign: 'center',
    padding: '100px 50px',
    background: '#ffffff',
  },
  videoTitle: {
    marginBottom: '50px',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '25px',
    height: '0',
  },
  videoIframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '8px',
  },
  ctaSection: {
    textAlign: 'center',
    padding: '100px 50px',
    background: '#ffffff',
  },
  ctaTitle: {
    fontSize: '40px',
    fontWeight: '800',
    marginBottom: '20px',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  ctaParagraph: {
    fontSize: '20px',
    marginBottom: '40px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: "'Inter', sans-serif",
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    flexWrap: 'wrap',
  },
  faqSection: {
    padding: '100px 50px',
    background: '#f9f9f9',
  },
  faqTitle: {
    textAlign: 'center',
    marginBottom: '50px',
    fontSize: '40px',
    fontWeight: '800',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  faqItem: {
    marginBottom: '20px',
  },
  faqQuestion: {
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  faqAnswer: {
    fontSize: '18px',
    fontFamily: "'Inter', sans-serif",
  },
  futureUpdatesSection: {
    padding: '100px 50px',
    background: '#ffffff',
  },
  futureUpdatesTitle: {
    textAlign: 'center',
    marginBottom: '50px',
    fontSize: '40px',
    fontWeight: '800',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
  },
  futureUpdatesCard: {
    border: 'none',
    textAlign: 'center',
    background: 'transparent',
  },
  futureUpdatesIcon: {
    fontSize: '60px',
    marginBottom: '20px',
    color: '#1890ff',
  },
};

const LandingPage = () => {
  const screens = useBreakpoint();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const renderMenuItems = () => (
    <Menu mode="vertical" style={styles.drawerMenu}>
      <Menu.Item key="1" style={styles.menuItem}>
        <a href="#home" onClick={closeDrawer}>Início</a>
      </Menu.Item>
      <Menu.Item key="2" style={styles.menuItem}>
        <a href="#features" onClick={closeDrawer}>Recursos</a>
      </Menu.Item>
      <Menu.Item key="3" style={styles.menuItem}>
        <a href="#gallery" onClick={closeDrawer}>Galeria</a>
      </Menu.Item>
      
      <Menu.Item key="5" style={styles.menuItem}>
        <a href="#future-updates" onClick={closeDrawer}>Futuras Atualizações</a>
      </Menu.Item>
      <Menu.Item key="6" style={styles.menuItem}>
        <a href="#faq" onClick={closeDrawer}>FAQ</a>
      </Menu.Item>
      <Menu.Item key="7" style={styles.menuItem}>
        <a href="#download" onClick={closeDrawer}>Jogar Agora</a>
      </Menu.Item>
    </Menu>
  );

  const renderDesktopMenu = () => (
    <Menu mode="horizontal" style={styles.menu}>
      <Menu.Item key="1" style={styles.menuItem}>
        <a href="#home">Início</a>
      </Menu.Item>
      <Menu.Item key="2" style={styles.menuItem}>
        <a href="#features">Recursos</a>
      </Menu.Item>
      <Menu.Item key="3" style={styles.menuItem}>
        <a href="#gallery">Galeria</a>
      </Menu.Item>
      
      <Menu.Item key="5" style={styles.menuItem}>
        <a href="#future-updates">Futuras Atualizações</a>
      </Menu.Item>
      <Menu.Item key="6" style={styles.menuItem}>
        <a href="#faq">FAQ</a>
      </Menu.Item>
      <Menu.Item key="7" style={styles.menuItem}>
        <a href="#download">Jogar Agora</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Importação de Fontes */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Plus+Jakarta+Sans:wght@600;800&display=swap');

          body {
            margin: 0;
            padding: 0;
          }
        `}
      </style>

      {/* Cabeçalho */}
      <Header style={styles.header}>
        <div style={styles.logo}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FLogoBlackNew.png?alt=media&token=ef25adea-f153-4704-8b62-6d119049bacf"
            alt="Logo"
            style={styles.logoImage}
          />
        </div>
        {screens.md ? (
          renderDesktopMenu()
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#000000' }} />}
            onClick={showDrawer}
            className="drawer-toggle-button"
          />
        )}
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
      >
        {renderMenuItems()}
      </Drawer>

      {/* Conteúdo Principal */}
      <Content style={{ marginTop: '80px' }}>
        {/* Seção Hero */}
        <motion.div
          style={styles.hero}
          id="home"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={styles.heroTitle}>Domine o Mundo dos Carros de Luxo</Title>
          <Paragraph style={styles.heroParagraph}>
         Compre e venda super carros enquanto gerencia todos os aspectos do seu negócio.
          </Paragraph>
          <div style={styles.ctaButtons}>
  <a href="https://bit.ly/iclandingpage" target="_blank" rel="noopener noreferrer">
    <Button type="primary" size="large" style={{ marginTop: '20px', padding: '0 40px', borderRadius: '30px', fontFamily: "'Inter', sans-serif" }}>
    Jogue grátis
    </Button>
  </a>
</div>
        </motion.div>

        {/* Seção Recursos */}
        <motion.div
          style={styles.section}
          id="features"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={styles.sectionTitle}>Recursos Incríveis</Title>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={12} md={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Card style={styles.featureCard}>
                  <div style={styles.featureIcon}>🪙</div>
                  <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Mercado</Title>
                  <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
                  Adquira carros retirados de estoques reais de lojas de luxo, com informações específicas e várias fotos de cada veículo.
                  </Paragraph>
                </Card>
              </motion.div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <Card style={styles.featureCard}>
                  <div style={styles.featureIcon}>🏎️</div>
                  <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Showroom</Title>
                  <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
                  Atenda uma lista crescente de clientes exigentes. Quanto mais sua loja cresce, mais desafiadores e lucrativos são os clientes que você recebe.
                  </Paragraph>
                </Card>
              </motion.div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <Card style={styles.featureCard}>
                  <div style={styles.featureIcon}>⭐</div>
                  <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Coleção Pessoal</Title>
                  <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
                  Guarde, colecione e exiba seus carros favoritos na sua coleção pessoal, interagindo e competindo com outros jogadores.
                  </Paragraph>
                </Card>
              </motion.div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <Card style={styles.featureCard}>
                  <div style={styles.featureIcon}>📈</div>
                  <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Gestão Completa</Title>
                  <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
                  Gerencie finanças, marketing, atendimento ao cliente e logística para construir um império de sucesso.
                  </Paragraph>
                </Card>
              </motion.div>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1.0 }}
              >
                <Card style={styles.featureCard}>
                  <div style={styles.featureIcon}>📱</div>
                  <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Jogue em qualquer lugar</Title>
                  <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
                  
                  Disponível em todos os navegadores, uma experiência fluida no computador e celular.
                  </Paragraph>
                </Card>
              </motion.div>
            </Col>
            
          </Row>
        </motion.div>

        

        {/* Seção Galeria */}
        <motion.div
          style={styles.sectionAlternate}
          id="gallery"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={styles.sectionTitle}>Galeria de Imagens</Title>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={12} md={8}>
              <motion.img
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2F3123.png?alt=media&token=54f684cc-5697-4693-b2a2-1625245c948f"
                alt="Galeria 1"
                style={styles.galleryImage}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.img
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2F3.png?alt=media&token=2c5c88e3-4ef4-4149-9d7a-546e1046f8f9"
                alt="Galeria 2"
                style={styles.galleryImage}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.img
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2F1.png?alt=media&token=ef790c87-119b-4905-9f6e-a492757aaa6c"
                alt="Galeria 3"
                style={styles.galleryImage}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.img
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2F4.png?alt=media&token=3501f638-46ad-4dc3-a00a-6cffe2739101"
                alt="Galeria 4"
                style={styles.galleryImage}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.6 }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.img
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2F5.png?alt=media&token=ea208454-2777-4726-8199-e9378f49a24c"
                alt="Galeria 5"
                style={styles.galleryImage}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <motion.img
                src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LP%2Fc2.png?alt=media&token=a39f4a85-8b1b-4531-b1e6-0f95500bd97f"
                alt="Galeria 6"
                style={styles.galleryImage}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1.0 }}
              />
            </Col>
          </Row>
        </motion.div>

       {/* Seção Futuras Atualizações */}
<motion.div
  style={styles.futureUpdatesSection}
  id="future-updates"
  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }}
  viewport={{ once: true }}
  transition={{ duration: 1 }}
>
  <Title style={styles.futureUpdatesTitle}>Futuras Atualizações</Title>
  <Row gutter={[32, 32]}>
   
    
    {/* Nova Funcionalidade 1 */}
    <Col xs={24} sm={12} md={8}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <Card style={styles.featureCard}>
          <div style={styles.featureIcon}>🧾</div>
          <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Gerenciamento de Despesas</Title>
          <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
            Mantenha o controle total das finanças da sua loja. Gerencie contas fixas e variáveis, otimize os custos operacionais e administre sua equipe de funcionários para maximizar os lucros e garantir o sucesso contínuo do seu império automobilístico.
          </Paragraph>
        </Card>
      </motion.div>
    </Col>
    
    {/* Nova Funcionalidade 2 */}
    <Col xs={24} sm={12} md={8}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 1.0 }}
      >
        <Card style={styles.featureCard}>
          <div style={styles.featureIcon}>💰</div>
          <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Empréstimos e Investimentos</Title>
          <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
            Expanda seu negócio com facilidade através de empréstimos estratégicos e investimentos inteligentes. Utilize nossos serviços bancários integrados para financiar novas aquisições, melhorar a infraestrutura da sua loja e aumentar seu portfólio de supercarros exclusivos.
          </Paragraph>
        </Card>
      </motion.div>
    </Col>
    
    {/* Nova Funcionalidade 3 */}
    <Col xs={24} sm={12} md={8}>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 1.2 }}
      >
        <Card style={styles.featureCard}>
          <div style={styles.featureIcon}>🚚</div>
          <Title level={4} style={{ fontFamily: "'Plus Jakarta Sans', sans-serif" }}>Importações Exclusivas</Title>
          <Paragraph style={{ fontFamily: "'Inter', sans-serif" }}>
            Amplie seu catálogo com modelos raros e exclusivos importados de diferentes partes do mundo. Gerencie o tempo de logística e os custos associados para garantir que você sempre tenha os carros mais desejados disponíveis para seus clientes exigentes.
          </Paragraph>
        </Card>
      </motion.div>
    </Col>
  </Row>
</motion.div>

        {/* Seção Testemunhos
        <motion.div
          style={styles.section}
          id="testimonials"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={styles.sectionTitle}>O que dizem nossos jogadores</Title>
          <Carousel autoplay>
            <div>
              <motion.div
                style={styles.testimonial}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph style={styles.testimonialParagraph}>
                  "Game Title transformou minha experiência de jogo. Os gráficos são impressionantes e a jogabilidade é envolvente!"
                </Paragraph>
                <Text style={styles.testimonialAuthor}>- João Silva</Text>
              </motion.div>
            </div>
            <div>
              <motion.div
                style={styles.testimonial}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Paragraph style={styles.testimonialParagraph}>
                  "Nunca joguei algo tão realista. Cada detalhe parece pensado para proporcionar a melhor experiência."
                </Paragraph>
                <Text style={styles.testimonialAuthor}>- Maria Oliveira</Text>
              </motion.div>
            </div>
            <div>
              <motion.div
                style={styles.testimonial}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <Paragraph style={styles.testimonialParagraph}>
                  "Os mundos expansivos e os desafios estratégicos me mantiveram entretido por horas a fio."
                </Paragraph>
                <Text style={styles.testimonialAuthor}>- Carlos Pereira</Text>
              </motion.div>
            </div>
          </Carousel>
        </motion.div> */}

        {/* Seção Vídeo 
        <motion.div
          style={styles.videoSection}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={{ ...styles.sectionTitle, ...styles.videoTitle }}>Assista ao Trailer</Title>
          <div style={styles.videoWrapper}>
            <iframe
              title="Trailer do Game Title"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              style={styles.videoIframe}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </motion.div>*/}

        {/* Seção Chamada para Ação */}
        <motion.div
          style={styles.ctaSection}
          id="download"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={styles.ctaTitle}>Pronto para Jogar?</Title>
          <Paragraph style={styles.ctaParagraph}>
            Acesse agora e construa seu império automotivo!
          </Paragraph>
          <div style={styles.ctaButtons}>
  <a href="https://bit.ly/iclandingpage" target="_blank" rel="noopener noreferrer">
    <Button type="primary" size="large" style={{ backgroundColor:'#202020',marginTop: '20px', padding: '0 40px', borderRadius: '30px', fontFamily: "'Inter', sans-serif" }}>
      Jogue grátis
    </Button>
  </a>
</div>
        </motion.div>

        {/* Seção FAQ */}
        <motion.div
          style={styles.faqSection}
          id="faq"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          <Title style={styles.faqTitle}>Perguntas Frequentes</Title>
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <div style={styles.faqItem}>
                <Title level={4} style={styles.faqQuestion}>Como acessar o Game Title?</Title>
                <Paragraph style={styles.faqAnswer}>
                 Ao finalizar o pagamento você será redirecionado pro cadastro do jogo.
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div style={styles.faqItem}>
                <Title level={4} style={styles.faqQuestion}>Quais são os requisitos do sistema?</Title>
                <Paragraph style={styles.faqAnswer}>
                  Qualquer computador ou celular roda liso o Luxury Gears.
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div style={styles.faqItem}>
                <Title level={4} style={styles.faqQuestion}>Como posso entrar em contato com o suporte?</Title>
                <Paragraph style={styles.faqAnswer}>
                  Você pode entrar em contato conosco através do Whatsapp 11912192520.
                  </Paragraph>
              </div>
            </Col>
            
          </Row>
        </motion.div>
      </Content>
    </Layout>
  );
};

export default LandingPage;
