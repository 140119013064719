import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Dashboard from './Dashboard';
import Showroom from './Showroom';
import Market from './Market';
import CarDetail from './CarDetail';
import AuthScreen from './AuthScreen';
import ColecaoPessoal from './ColecaoPessoal';
import Extrato from './Extrato';
import AchievementsScreen from './AchievementsScreen';
import YoutubeVideos from './YoutubeVideos';
import Shop from './Shop';
import LoadingScreen from './LoadingScreen';
import CarForm from './Form';
import PerfilLoja from './PerfilLoja';
import VIPClientCRM from './VIPClientCRM';
import Staff from './Staff';
import MarketingPanel from './MarketingPanel';
import DevelopmentBoard from './DevBoad';
import { LevelProvider } from './LevelProvider';
import PlayerRankingScreen from './ranking';
import GlobalAchievementsScreen from './GlobalConquer';
import Maintenance from './maintenance'; 
import AccessControl from './AccessControl';
import LandingPage from './LandingPage';
import Garage from './Garage';
import BankLoan from './BankLoan';
import BonusCard from './BonusCard';
import TutorialScreen from './TutorialScreen'
import AdminScreen from './AdminScreen';
import CarImportMap from './CarImportMap';
import Isca from './Isca';
import Rank2 from './Rank2';
import WelcomeScreen from './WelcomeScreen';
import VipMarket from './VipMarket';
import Form2 from './Form2';
import NewProfit from './NewProfit'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ColecaoVip from './ColecaoVip';



const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [accessEnabled, setAccessEnabled] = useState(true);

  // Implementação do Google Analytics
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-Z9E04RLRW6', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  // Verificar estado de acesso no Firestore
  useEffect(() => {
    const fetchAccessState = async () => {
      const docRef = doc(firestore, 'config', 'access');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAccessEnabled(docSnap.data().enabled);
      }
    };

    fetchAccessState();
  }, []);

  // Verificar estado de autenticação
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!accessEnabled) {
    return <Navigate to="/maintenance" />;
  }

  return (
    <Routes>
      {/* Rota para a página de manutenção, acessível a todos */}
      <Route path="/maintenance" element={<Maintenance />} />

      {/* Rota para a página de Landing Page (não exige autenticação) */}
      <Route path="/landingpage" element={<LandingPage />} />

      {/* Rota para a tela de autenticação */}
      <Route path="/login" element={<AuthScreen />} />

      {/* Rotas protegidas */}
      {user ? (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/conquistas" element={<GlobalAchievementsScreen />} />
          <Route path="/block" element={<AccessControl />} />
          <Route path="/ranking" element={<PlayerRankingScreen />} />
          <Route path="/tutorial" element={<TutorialScreen />} />
          <Route path="/teste" element={<AdminScreen />} />
          <Route path="/maps" element={<CarImportMap />} />
          <Route path="/bonus" element={<Isca />} />
          <Route path="/rank2" element={<Rank2 />} />
          <Route path="/boasvindas" element={<WelcomeScreen />} />
          <Route path="/vipmarket" element={<VipMarket />} />
          <Route path="/lucro" element={<NewProfit />} />
          <Route path="/mina" element={<BonusCard />} />
          <Route path="/colvip" element={<ColecaoVip />} />



          



          <Route path="/showroom" element={<Showroom />} />
          <Route path="/bank" element={<BankLoan />} />
          <Route path="/garage" element={<Garage />} />
          <Route path="/market" element={<Market />} />
          <Route path="/perfil-loja/:storeId" element={<PerfilLoja />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/marketing" element={<MarketingPanel />} />
          <Route path="/dev" element={<DevelopmentBoard />} />
          <Route path="/LevelProvider" element={<LevelProvider />} />
          <Route path="/extrato" element={<Extrato />} />
          <Route path="/vip" element={<VIPClientCRM />} />
          <Route path="/youtube" element={<YoutubeVideos />} />
          <Route path="/form" element={<CarForm />} />
          <Route path="/form2" element={<Form2 />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/AchievementsScreen" element={<AchievementsScreen />} />
          <Route path="/cardetail/:carId/:storeId" element={<CarDetail />} />
          <Route path="/colecaopessoal" element={<ColecaoPessoal />} />

          {/* Redireciona qualquer outra rota para o dashboard */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </>
      ) : (
        <>
          {/* Redireciona usuários não autenticados para a tela de login */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
