// src/components/ProfitUpgradeCard.js

import React, { useState, useEffect } from 'react';
import { Card, Button, message, Typography } from 'antd';
import { firestore } from './firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const { Title, Text } = Typography;

const ProfitUpgradeCard = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [currentProfit, setCurrentProfit] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    // Verifica o valor inicial de 'profit' ao carregar o componente
    const fetchPlayerData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado.');
          return;
        }

        const playerRef = doc(firestore, 'players', user.uid);
        const playerSnap = await getDoc(playerRef);

        if (playerSnap.exists()) {
          const playerData = playerSnap.data();
          setCurrentProfit(playerData.profit || 0);

          // Desativa o botão se o 'profit' já for 6
          if (playerData.profit === 6) {
            setIsButtonDisabled(true);
          }
        } else {
          message.error('Dados do jogador não encontrados.');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do jogador:', error);
      }
    };

    fetchPlayerData();
  }, [auth]);

  // Função para atualizar o 'profit' para 6
  const handleProfitUpgrade = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado.');
        return;
      }

      const playerRef = doc(firestore, 'players', user.uid);
      await updateDoc(playerRef, { profit: 6 });
      
      message.success('Lucro corrigido!');
      setCurrentProfit(6);
      setIsButtonDisabled(true);
    } catch (error) {
      console.error('Erro ao atualizar o profit:', error);
      message.error('Erro ao atualizar o profit.');
    }
  };

  return (
    <Card
      style={{
        maxWidth: 400,
        margin: '0 auto',
        textAlign: 'center',
        borderRadius: '10px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      }}
    >
      <Title level={3}>Correção de Lucro</Title>
      
      
      <Button
        type="primary"
        disabled={isButtonDisabled}
        onClick={handleProfitUpgrade}
        block
      >
        {isButtonDisabled ? 'Já atualizado' : 'Atualizar Lucro'}
      </Button>
    </Card>
  );
};

export default ProfitUpgradeCard;
