// Importar as funções necessárias do SDK do Firebase
import { initializeApp } from "firebase/app";
import { getFirestore, CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Configuração do Firebase para o seu aplicativo web
const firebaseConfig = {
  apiKey: "AIzaSyAeYpI_Sg9uAnB4bSghtqK8BjsAiVVQNcE",
  authDomain: "grandgarage-52123.firebaseapp.com",
  projectId: "grandgarage-52123",
  storageBucket: "grandgarage-52123.appspot.com",
  messagingSenderId: "531969517049",
  appId: "1:531969517049:web:5c28028805a29846f44928",
  measurementId: "G-Z9E04RLRW6"
};

// Inicializar o Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Firestore e Storage
const firestore = getFirestore(app);
const storage = getStorage(app);

// Inicializar Auth (padrão para web)
const auth = getAuth(app);

// Configurar o cache e persistência offline no Firestore
const enableFirestoreCache = async () => {
  try {
    // Configura o Firestore para usar cache ilimitado
    await firestore.settings({
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    });

    // Habilita persistência offline com IndexedDB
    await enableIndexedDbPersistence(firestore);
    
    console.log("Persistência offline e cache ilimitado habilitados.");
  } catch (error) {
    if (error.code === 'failed-precondition') {
      console.error("Persistência não habilitada: múltiplas abas abertas.");
    } else if (error.code === 'unimplemented') {
      console.error("Persistência não suportada neste navegador.");
    } else {
      console.error("Erro ao habilitar a persistência:", error);
    }
  }
};

// Chamar a função para habilitar cache e persistência offline
enableFirestoreCache();

export { firestore, storage, auth };
