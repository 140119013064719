import React from 'react';
import { Layout, Menu, Row, Col, Typography } from 'antd';
import { FilePdfOutlined,CodeOutlined,UserOutlined,AppstoreOutlined,YoutubeOutlined, CarOutlined, ShopOutlined, LineChartOutlined, LogoutOutlined,ShoppingOutlined } from '@ant-design/icons';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { auth } from './firebaseConfig'; // Certifique-se de que o caminho está correto

const { Sider, Content } = Layout;
const { Title, Text } = Typography;

const YoutubeVideos = () => {
  const videoIds = [
    'dQw4w9WgXcQ', // Exemplo de vídeo do YouTube 1
    '9bZkp7q19f0', // Exemplo de vídeo do YouTube 2
    'LXb3EKWsInQ', // Exemplo de vídeo do YouTube 3
    'wZZ7oFKsKzY', // Exemplo de vídeo do YouTube 4
    'e-ORhEE9VVg', // Exemplo de vídeo do YouTube 5
    'YQHsXMglC9A', // Exemplo de vídeo do YouTube 6
    'iS1g8G_njx8', // Exemplo de vídeo do YouTube 7
    'A_MjCqQoLLA', // Exemplo de vídeo do YouTube 8
  ];

  const videoTitles = [
    'Como escolher o carro certo para sua coleção',
    'Top 10 carros de luxo mais vendidos em 2023',
    'Dicas de manutenção para carros exóticos',
    'O futuro dos carros elétricos no mercado de luxo',
    'Os carros mais rápidos já testados',
    'Como negociar o preço de um carro de luxo',
    'Melhores acessórios para customizar seu carro',
    'Carros raros: vale a pena o investimento?',
  ];

  const routeToKeyMap = {
    '/dashboard': '1',
    '/showroom': '2',
    '/market': '3',
    '/colecaopessoal': '4',
    '/shop': '7',
    '/youtube': '8'
  };

  // Utilize o hook useLocation para pegar a localização atual
  const location = useLocation();

  const { Header } = Layout;
  
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#040611', padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="logo" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LGlogo.png?alt=media&token=654f4181-620b-4528-8b66-3064165c818a"
            alt="Logo"
            style={{ height: '30px', marginRight: '16px' }}
          />
        </div>

        {/* Menu sem seleção de abas */}
        <Menu theme="dark" mode="horizontal" style={{ background: '#040611', lineHeight: '64px' }}>
          <Menu.Item key="1" icon={<AppstoreOutlined style={{ fontSize: '20px' }} />} title="Dashboard">
            <Link to="/dashboard" />
          </Menu.Item>
          <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '20px' }} />} title="Showroom">
            <Link to="/showroom" />
          </Menu.Item>
          <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '20px' }} />} title="Mercado">
            <Link to="/market" />
          </Menu.Item>
          
          
          
          <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '20px' }} />} title="Coleção Pessoal">
            <Link to="/colecaopessoal" />
          </Menu.Item>
          <Menu.Item key="7" icon={<ShoppingOutlined style={{ fontSize: '20px' }} />} title="Loja">
            <Link to="/shop" />
          </Menu.Item>
          
          <Menu.Item key="4" icon={<CodeOutlined style={{ fontSize: '20px' }} />} title="Coleção Pessoal">
            <Link to="/dev" />
          </Menu.Item>
          <Menu.Item key="10" icon={<LogoutOutlined style={{ fontSize: '20px' }} />} title="Logout" onClick={() => console.log('Logout clicked')} />
        </Menu>
      </Header>
      <Layout>
        <Content style={{ margin: '24px 16px 0', padding: 24, background: '#fff', minHeight: 360 }}>
          <Title level={2}>Meus Conteúdos no YouTube</Title>
          <Row gutter={[24, 24]}>
            {videoIds.map((videoId, index) => (
              <Col key={index} xs={24} sm={12} lg={8}>
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                  <iframe
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title={videoTitles[index]}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  />
                </div>
                {/* Título individual abaixo de cada vídeo */}
                <Text strong style={{ display: 'block', marginTop: '12px', fontSize: '16px', textAlign: 'center' }}>
                  {videoTitles[index]}
                </Text>
              </Col>
            ))}
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default YoutubeVideos;
