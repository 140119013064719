import React from 'react';
import { Card, Button, Row, Col, Layout } from 'antd';
import { GiftOutlined } from '@ant-design/icons';

const { Content } = Layout;

const RecompensaProximoNivel = () => {
  const cardStyle = {
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Sombra suave para destaque
    border: '1px solid #f0f0f0', // Borda sutil
    borderRadius: '10px', // Bordas arredondadas
  };

  const buttonStyle = {
    height: '60px', // Altura fixa para o botão
    border: 'none', // Sem borda
    borderRadius: '0 0 10px 10px', // Arredondamento apenas na parte inferior
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#000', // Cor do texto
    backgroundColor: '#ffffff', // Cor de fundo do botão
  };

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      <Content style={{ padding: '40px 0', display: 'flex', justifyContent: 'center' }}>
        <Row gutter={[16, 16]} style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
          <Col xs={24} md={12} lg={12}>
            <Card style={cardStyle}>
              <h2 style={{ margin: '20px 16px', fontSize: '18px', color: '#000' }}>
                Recompensa do Próximo Nível
              </h2>
              <Button style={buttonStyle} icon={<GiftOutlined style={{ marginRight: '10px', fontSize: '20px', color: 'rgba(0, 0, 0, 0.65)' }} />}>
                Recompensa: R$200.000
              </Button>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default RecompensaProximoNivel;
