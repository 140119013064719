import React, { useState } from 'react';
import { Layout, Menu, Card, Progress, Modal, Button, Row, Col, Typography } from 'antd';
import { getAuth,signOut } from 'firebase/auth';
import { useNavigate,Link } from 'react-router-dom';


import {
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  CodeOutlined,
  LogoutOutlined,
  ShoppingOutlined
} from '@ant-design/icons';

const auth = getAuth();

const { Header } = Layout;
const { Title, Text } = Typography;

// Dados fictícios de features
const features = [
  {
    title: 'Clientes VIP',
    description: 'Desenvolva o relacionamento com clientes VIP com níveis de confiança e benefícios exclusivos.',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/10',
  },
  {
    title: 'Banco Virtual',
    description: 'Peça empréstimos e invista em ativos conforme avança na gestão da loja.',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/10',
  },
  {
    title: 'Campanhas de Marketing',
    description: 'Crie campanhas de marketing para aumentar a receita.',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/10',
  },
  {
    title: 'Gestão de Despesas',
    description: 'Controle os custos fixos e variáveis da sua loja para maximizar seus lucros e eficiência.',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/10',
  },
  {
    title: 'Multiplayer',
    description: 'Negocie com outros jogadores e faça acordos comerciais muito lucrativos.',
    status: 'Gratuito',
    progress: 0,
    estimatedCompletion: '30/11',
  },
  {
    title: 'Funcionários',
    description: 'Gerencie seus funcionários, ajustando salários e melhorando sua eficiência para aumentar suas vendas.',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/10',
  },
  {
    title: 'Ranking de Lojas',
    description: 'Compare sua loja com outros jogadores e suba no ranking vendendo mais carros.',
    status: 'Gratuito',
    progress: 0,
    estimatedCompletion: '30/11',
  },
  {
    title: 'Desafios Diários/Semanais',
    description: 'Complete desafios únicos para ganhar prêmios exclusivos e melhorar sua loja.',
    status: 'Gratuito',
    progress: 0,
    estimatedCompletion: '30/11',
  },
  {
    title: 'Versão Mobile',
    description: 'A intenção principal é colocar na Google Play e em seguida no IOS, mas isso pode ser impedido por direito de imagem das marcas',
    status: 'Gratuito',
    progress: 0,
    estimatedCompletion: '30/11',
  },
  {
    title: 'Estética Automotiva',
    description: '',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/11',
  },
  {
    title: 'Performance dos Carros',
    description: '',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/11',
  },
  {
    title: 'Contrato com as marcas',
    description: '',
    status: 'Membros PRO',
    progress: 0,
    estimatedCompletion: '30/11',
  },
   
];

const DevelopmentBoard = () => {
  const [visible, setVisible] = useState(false);
  const [currentFeature, setCurrentFeature] = useState(null);
  const navigate = useNavigate();

  // Função para abrir modal de detalhes
  const showModal = (feature) => {
    setCurrentFeature(feature);
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#040611', padding: '0 16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="logo" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LGlogo.png?alt=media&token=654f4181-620b-4528-8b66-3064165c818a"
            alt="Logo"
            style={{ height: '30px', marginRight: '16px' }}
          />
        </div>

        {/* Menu sem seleção de abas */}
        <Menu theme="dark" mode="horizontal" style={{ background: '#040611', lineHeight: '64px' }}>
          <Menu.Item key="1" icon={<AppstoreOutlined style={{ fontSize: '20px' }} />} title="Dashboard">
            <Link to="/dashboard" />
          </Menu.Item>
          <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '20px' }} />} title="Showroom">
            <Link to="/showroom" />
          </Menu.Item>
          <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '20px' }} />} title="Mercado">
            <Link to="/market" />
          </Menu.Item>
          
          
          
          <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '20px' }} />} title="Coleção Pessoal">
            <Link to="/colecaopessoal" />
          </Menu.Item>
          <Menu.Item key="7" icon={<ShoppingOutlined style={{ fontSize: '20px' }} />} title="Loja">
            <Link to="/shop" />
          </Menu.Item>
          
          <Menu.Item key="8" icon={<CodeOutlined style={{ fontSize: '20px' }} />} title="Coleção Pessoal">
            <Link to="/dev" />
          </Menu.Item>
          <Menu.Item 
              key="10" 
              icon={<LogoutOutlined style={{ fontSize: '20px' }} />} 
              title="Logout" 
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    console.log('Logout bem-sucedido');
                    navigate('/auth'); // Redireciona para a página de login
                  })
                  .catch((error) => {
                    console.error('Erro ao sair:', error);
                  });
              }}
            />
        </Menu>
      </Header>

      <Layout>
        <div style={{ padding: '40px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '40px' }}>
            Desenvolvimento do Jogo
          </Title>
        
          <Row gutter={[16, 16]} justify="center">
            {features.map((feature) => (
              <Col key={feature.title} xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  bordered={false}
                  style={{
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  
                >
                  <Text type="secondary" style={{ marginTop: '10px', display: 'block' }}>
                    <strong>{feature.status}</strong>
                  </Text>
                  <Title level={4}>{feature.title}</Title>
                  <Text>{feature.description}</Text>
                  
                  
                  <Text type="secondary"style={{ marginTop: '10px', display: 'block' }}>Conclusão Estimada: {feature.estimatedCompletion}</Text>
                </Card>
              </Col>
            ))}
          </Row>

          <Modal
            title={currentFeature?.title}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="ok" type="primary" onClick={handleOk} style={{ borderRadius: '4px' }}>
                Fechar
              </Button>,
            ]}
          >
            <Title level={4}>{currentFeature?.title}</Title>
            <Text>{currentFeature?.description}</Text>
            <Progress percent={currentFeature?.progress} style={{ margin: '15px 0' }} />
            <Text>Status: {currentFeature?.status}</Text>
            <br />
            <Text>Conclusão Estimada: {currentFeature?.estimatedCompletion}</Text>
          </Modal>
        </div>
      </Layout>
    </Layout>
  );
};

export default DevelopmentBoard;
