// HeaderProfileMetrics.js

import React, { useState } from 'react';
import {
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Typography,
  Button,
  Drawer,
  Grid,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  CompassOutlined,
  NotificationOutlined,
  FundOutlined,
  StarOutlined,
  DollarOutlined,
  CrownOutlined,
  ShoppingFilled,
  GoldOutlined,
  MenuOutlined,
} from '@ant-design/icons';

const { Header } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const HeaderProfileMetrics = ({
  playerData = {},
  metrics = {},
  balance = 0,
  gold = 0,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const getLogoUrl = () => {
    if (playerData.sub === undefined || playerData.sub === null) {
      return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FNewLogoTopbar.png?alt=media&token=0814912f-cda2-41d9-a50c-4627af7000d5';
    }

    switch (playerData.sub) {
      case 1:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FLogoVip2.png?alt=media&token=fee8d840-f927-4987-af10-3b58ef00e87c';
      case 2:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FBillionLogo.png?alt=media&token=54c16bf7-b006-4cba-a961-746370fd7dee';
      default:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2FNewLogoTopbar.png?alt=media&token=0814912f-cda2-41d9-a50c-4627af7000d5';
    }
  };

  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<FundOutlined style={{ fontSize: '23px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<CarOutlined style={{ fontSize: '23px' }} />} title="Showroom">
        <Link to="/showroom">Minha Loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<ShopOutlined style={{ fontSize: '23px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '23px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Garagem</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<StarOutlined style={{ fontSize: '23px' }} />} title="Ranking">
        <Link to="/ranking">Ranking</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<ShoppingFilled style={{ fontSize: '23px' }} />} title="Loja">
        <Link to="/shop">Loja</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<NotificationOutlined style={{ fontSize: '23px' }} />} title="Notificações">
        <Link to="/notifications">Notificações</Link>
      </Menu.Item>
      <Menu.Item key="8" icon={<BankOutlined style={{ fontSize: '23px' }} />} title="Banco">
        <Link to="/bank">Banco</Link>
      </Menu.Item>
      <Menu.Item key="9" icon={<CompassOutlined style={{ fontSize: '23px' }} />} title="Mapa">
        <Link to="/map">Mapa</Link>
      </Menu.Item>
    </>
  );

  return (
    <>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      >
        {/* Seção Esquerda: Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={getLogoUrl()} alt="Logo" style={{ height: '40px', marginRight: '20px' }} />
          {screens.md && (
            <Menu theme="dark" mode="horizontal" style={{ background: '#080808' }}>
              {renderMenuItems()}
            </Menu>
          )}
        </div>

        {/* Seção Direita: Menu Mobile */}
        {!screens.md && (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
          />
        )}
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical" onClick={closeDrawer}>
          {renderMenuItems()}
        </Menu>
      </Drawer>

      {/* Seção de Perfil e Métricas */}
      <div
        style={{
          background: '#080808',
          padding: '34px',
          borderBottom: '1px solid #1f1f1f',
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {/* Linha para Perfil, Saldo e Ouro */}
        <Row
          align="middle"
          gutter={[16, 16]}
          justify={screens.xs ? 'center' : 'right'}
          style={{ width: '80%' }}
        >
          {/* Perfil */}
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/colecaopessoal')}
            >
              <img
                src={
                  playerData?.profileImage ||
                  'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/IconDark.png?alt=media&token=587bc184-edc5-4c02-b7cc-21dc3d770f36'
                }
                alt="Perfil"
                style={{
                  borderRadius: '50%',
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                  border: '2px solid #ffffff',
                  marginRight: '16px',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Title level={4} style={{ color: '#ffffff', marginBottom: '8px' }}>
                  {playerData?.name || 'Minha Loja'}
                </Title>
                <Text style={{ color: '#b3b3b3', fontSize: '18px' }}>
                  Nível: {playerData?.level || '1'}
                </Text>
              </div>
            </div>
          </Col>

          {/* Saldo em Reais */}
          <Col xs={12} sm={12} md={8} lg={6} xl={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 20px',
                background: '#202020',
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'background 0.3s',
                marginBottom: screens.xs ? '16px' : '0', // Espaçamento no mobile
              }}
              onClick={(e) => {
                e.stopPropagation(); // Evita que o clique na div redirecione para /colecaopessoal
                navigate('/extrato');
              }}
              onMouseEnter={(e) => (e.currentTarget.style.background = '#333333')}
              onMouseLeave={(e) => (e.currentTarget.style.background = '#202020')}
            >
              <DollarOutlined style={{ color: '#fff', marginRight: '8px', fontSize: '20px' }} />
              <div>
                <Text style={{ color: '#ffffff', fontSize: '16px' }}>Saldo</Text>
                <Text strong style={{ color: '#ffffff', fontSize: '20px' }}>
                  {balance !== undefined && balance !== null ? `R$${balance.toLocaleString('pt-BR')}` : 'R$0'}
                </Text>
              </div>
            </div>
          </Col>

          {/* Saldo em Ouro */}
          <Col xs={12} sm={12} md={8} lg={6} xl={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 20px',
                background: '#FFD700',
                borderRadius: '8px',
              }}
            >
              <GoldOutlined style={{ color: '#FFD700', marginRight: '8px', fontSize: '20px' }} />
              <div>
                <Text style={{ color: '#ffffff', fontSize: '16px' }}>Ouro</Text>
                <Text strong style={{ color: '#ffffff', fontSize: '20px' }}>
                  {gold !== undefined && gold !== null ? gold.toLocaleString('pt-BR') : '0'}
                </Text>
              </div>
            </div>
          </Col>
        </Row>

        {/* Seção de Métricas */}
        <Row
          gutter={[16, 16]}
          justify="start"
          style={{ marginTop: '24px', width: '100%' }}
        >
          {metrics.totalSales !== undefined && (
            <Col xs={12} sm={12} md={8} lg={4}>
              <Card
                size="small"
                title={<span style={{ color: '#f6f6f6' }}>Vendidos</span>}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #202020',
                  background: '#202020',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
                bodyStyle={{ padding: '8px' }}
              >
                <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                  {metrics.totalSales}
                </Text>
              </Card>
            </Col>
          )}
          {metrics.totalRevenue !== undefined && (
            <Col xs={12} sm={12} md={8} lg={4}>
              <Card
                size="small"
                title={<span style={{ color: '#f6f6f6' }}>Receita</span>}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #202020',
                  background: '#202020',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
                bodyStyle={{ padding: '8px' }}
              >
                <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                  {metrics.totalRevenue !== undefined && metrics.totalRevenue !== null
                    ? `R$${Number(metrics.totalRevenue).toLocaleString('pt-BR')}`
                    : 'R$0'}
                </Text>
              </Card>
            </Col>
          )}
          {metrics.carrosComprados !== undefined && (
            <Col xs={12} sm={12} md={8} lg={4}>
              <Card
                size="small"
                title={<span style={{ color: '#f6f6f6' }}>Carros Comprados</span>}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #202020',
                  background: '#202020',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
                bodyStyle={{ padding: '8px' }}
              >
                <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                  {metrics.carrosComprados !== undefined && metrics.carrosComprados !== null
                    ? Number(metrics.carrosComprados).toLocaleString('pt-BR')
                    : '0'}
                </Text>
              </Card>
            </Col>
          )}
          {metrics.totalProfit !== undefined && (
            <Col xs={12} sm={12} md={8} lg={4}>
              <Card
                size="small"
                title={<span style={{ color: '#f6f6f6' }}>Lucro Total</span>}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #202020',
                  background: '#202020',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
                bodyStyle={{ padding: '8px' }}
              >
                <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                  {metrics.totalProfit !== undefined && metrics.totalProfit !== null
                    ? `R$${Number(metrics.totalProfit).toLocaleString('pt-BR')}`
                    : 'R$0'}
                </Text>
              </Card>
            </Col>
          )}
          {metrics.showroomValue !== undefined && (
            <Col xs={12} sm={12} md={8} lg={4}>
              <Card
                size="small"
                title={<span style={{ color: '#f6f6f6' }}>Valor Showroom</span>}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #202020',
                  background: '#202020',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
                bodyStyle={{ padding: '8px' }}
              >
                <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                  {metrics.showroomValue !== undefined && metrics.showroomValue !== null
                    ? `R$${Number(metrics.showroomValue).toLocaleString('pt-BR')}`
                    : 'R$0'}
                </Text>
              </Card>
            </Col>
          )}
          {metrics.carnumber !== undefined && (
            <Col xs={12} sm={12} md={8} lg={4}>
              <Card
                size="small"
                title={<span style={{ color: '#f6f6f6' }}>Carros na Loja</span>}
                style={{
                  borderRadius: '4px',
                  border: '1px solid #202020',
                  background: '#202020',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
                bodyStyle={{ padding: '8px' }}
              >
                <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                  {metrics.carnumber !== undefined && metrics.carnumber !== null
                    ? metrics.carnumber.toLocaleString('pt-BR')
                    : '0'}
                </Text>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default HeaderProfileMetrics;
