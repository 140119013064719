import React, { useState } from 'react';
import { Layout, Table, Button, Modal, Form, Input, Select, Switch, message, Typography, Progress, Tag, Slider } from 'antd';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

// Dados de campanhas de marketing para teste
const initialCampaigns = [
  {
    key: '1',
    name: 'Campanha Facebook',
    platform: 'Facebook',
    budget: 5000,
    status: 'Ativa',
    reach: 15000,
    engagement: 1200,
    roi: 3.5,
    progress: 70, // Em percentual
  },
  {
    key: '2',
    name: 'Campanha Instagram',
    platform: 'Instagram',
    budget: 3500,
    status: 'Ativa',
    reach: 10000,
    engagement: 900,
    roi: 2.8,
    progress: 50,
  },
  {
    key: '3',
    name: 'Campanha Influenciadores',
    platform: 'Influenciadores',
    budget: 8000,
    status: 'Pausada',
    reach: 30000,
    engagement: 2500,
    roi: 4.0,
    progress: 0,
  },
];

// Component principal para gerenciamento de marketing
const MarketingPanel = () => {
  const [campaigns, setCampaigns] = useState(initialCampaigns); // Estado para as campanhas
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal para criar nova campanha
  const [form] = Form.useForm();
  const [selectedCampaign, setSelectedCampaign] = useState(null); // Campanha selecionada para relatório
  const [budgetSliderValue, setBudgetSliderValue] = useState(5000); // Valor do orçamento no slider
  const [predictedROI, setPredictedROI] = useState(3.0); // ROI previsto pela calculadora

  // Simula o retorno de investimento (ROI) com base no orçamento escolhido
  const calculateROI = (budget) => {
    const estimatedROI = (budget / 1000) * Math.random() * 4; // Simulação simples de ROI
    setPredictedROI(estimatedROI.toFixed(2));
  };

  // Lógica para criar nova campanha
  const handleCreateCampaign = () => {
    form
      .validateFields()
      .then((values) => {
        const newCampaign = {
          key: Math.random().toString(36).substr(2, 9),
          name: values.name,
          platform: values.platform,
          budget: budgetSliderValue,
          status: 'Ativa',
          reach: 0,
          engagement: 0,
          roi: 0,
          progress: 0,
        };
        setCampaigns([...campaigns, newCampaign]);
        form.resetFields();
        setIsModalVisible(false);
        message.success('Campanha criada com sucesso!');
      })
      .catch(() => {
        message.error('Preencha todos os campos corretamente.');
      });
  };

  // Ativar/Desativar campanhas de marketing
  const toggleCampaignStatus = (campaign) => {
    const updatedCampaigns = campaigns.map((c) => {
      if (c.key === campaign.key) {
        c.status = c.status === 'Ativa' ? 'Pausada' : 'Ativa';
      }
      return c;
    });
    setCampaigns(updatedCampaigns);
    message.success(`Campanha ${campaign.status === 'Ativa' ? 'pausada' : 'ativada'}.`);
  };

  // Concluir campanha e mostrar relatório final
  const finishCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    message.success(`A campanha "${campaign.name}" foi finalizada. Relatório gerado!`);
  };

  // Colunas da tabela de campanhas
  const columns = [
    {
      title: 'Nome da Campanha',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Plataforma',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'Orçamento',
      dataIndex: 'budget',
      key: 'budget',
      render: (budget) => `R$ ${budget.toLocaleString('pt-BR')}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, campaign) => (
        <Switch
          checked={status === 'Ativa'}
          onChange={() => toggleCampaignStatus(campaign)}
        />
      ),
    },
    {
      title: 'Progresso',
      dataIndex: 'progress',
      key: 'progress',
      render: (progress, campaign) => (
        <Progress
          percent={progress}
          status={progress === 100 ? 'success' : 'active'}
          onClick={() => finishCampaign(campaign)}
        />
      ),
    },
    {
      title: 'ROI Estimado',
      dataIndex: 'roi',
      key: 'roi',
      render: (roi) => (
        <Tag color={roi > 3 ? 'green' : 'orange'}>{roi.toFixed(2)}</Tag>
      ),
    },
  ];

  return (
    <Layout style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
      <Content>
        <Title level={2}>Painel de Marketing</Title>

        {/* Botões de ações rápidas */}
        <Button
          type="primary"
          onClick={() => setIsModalVisible(true)}
          style={{ marginBottom: '16px' }}
        >
          Criar Nova Campanha
        </Button>

        {/* Tabela de campanhas de marketing */}
        <Table
          columns={columns}
          dataSource={campaigns}
          pagination={false}
          bordered
          style={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}
        />

        {/* Modal para criar nova campanha */}
        <Modal
          title="Criar Nova Campanha de Marketing"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onOk={handleCreateCampaign}
          okText="Criar"
          cancelText="Cancelar"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Nome da Campanha"
              rules={[{ required: true, message: 'Digite o nome da campanha' }]}
            >
              <Input placeholder="Nome da campanha" />
            </Form.Item>
            <Form.Item
              name="platform"
              label="Plataforma"
              rules={[{ required: true, message: 'Selecione a plataforma' }]}
            >
              <Select placeholder="Selecione a plataforma">
                <Option value="Facebook">Facebook</Option>
                <Option value="Instagram">Instagram</Option>
                <Option value="Influenciadores">Influenciadores</Option>
                <Option value="Eventos">Eventos</Option>
              </Select>
            </Form.Item>

            {/* Slider de orçamento com cálculo de ROI */}
            <Form.Item label="Orçamento">
              <Slider
                min={500}
                max={10000}
                step={500}
                onChange={(value) => {
                  setBudgetSliderValue(value);
                  calculateROI(value);
                }}
                value={budgetSliderValue}
                tooltipVisible
              />
              <Text strong>R$ {budgetSliderValue.toLocaleString('pt-BR')}</Text>
            </Form.Item>

            <Form.Item label="ROI Estimado">
              <Tag color="blue">
                O retorno estimado é de {predictedROI}x o valor investido
              </Tag>
            </Form.Item>
          </Form>
        </Modal>

        {selectedCampaign && (
  <Modal
    title={`Relatório Final - ${selectedCampaign.name}`}
    visible={!!selectedCampaign}
    onCancel={() => setSelectedCampaign(null)}
    footer={null}
  >
    <p>
      <strong>Plataforma:</strong> {selectedCampaign.platform}
    </p>
    <p>
      <strong>Alcance Final:</strong> {selectedCampaign.reach.toLocaleString('pt-BR')}
    </p>
    <p>
      <strong>Engajamento Final:</strong> {selectedCampaign.engagement.toLocaleString('pt-BR')}
    </p>
    <p>
      <strong>Orçamento Total Gasto:</strong> R$ {selectedCampaign.budget.toLocaleString('pt-BR')}
    </p>
    <p>
      <strong>ROI Final:</strong> {selectedCampaign.roi.toFixed(2)}x
    </p>
    <p>
      <strong>Comentário Final:</strong> {selectedCampaign.roi > 3 
        ? 'Esta campanha foi altamente eficaz, gerando um grande retorno sobre o investimento.' 
        : 'A campanha gerou resultados moderados. Considere ajustar sua estratégia ou plataforma.'}
    </p>
  </Modal>
)}
</Content>
</Layout>
);
};

export default MarketingPanel;