// src/components/ColecaoPessoal.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Carousel,
  message,
  Menu,
  Drawer,
  Descriptions,
  Divider,
  Spin,
  Tooltip,
  Grid
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { firestore } from './firebaseConfig';
import {
  DollarOutlined,
  ShopOutlined,
  CarOutlined,
  UserOutlined,
  CrownOutlined,
  LogoutOutlined,
  GoldOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { getAuth, signOut } from 'firebase/auth';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  writeBatch,
  query,
  orderBy,
} from 'firebase/firestore';

const { Header, Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

// Função para normalizar dados dos carros VIP
const normalizeVIPCarData = (car) => {
  return {
    id: car.id || 'N/A',
    marca: car.marca || 'N/A',
    modelo: car.modelo || 'N/A',
    ano: typeof car.ano === 'number' ? car.ano : 'N/A',
    km: typeof car.km === 'number' ? car.km : 0,
    tipo: car.tipo || 'N/A',
    potencia: typeof car.potencia === 'number' ? car.potencia : 0,
    valor: typeof car.valor === 'number' ? car.valor : 0,
    goldvalue: typeof car.goldvalue === 'number' ? car.goldvalue : 0, // Campo para diferenciar VIP
    influence: typeof car.influence === 'number' ? car.influence : 0,
    desc1: car.desc1 || 'Descrição não disponível.',
    desc2: car.desc2 || '',
    videoURL: car.videoURL || '',
    imagens:
      Array.isArray(car.imagens) && car.imagens.length > 0
        ? car.imagens
        : ['https://via.placeholder.com/300'],
    testimonials:
      Array.isArray(car.testimonials) && car.testimonials.length > 0
        ? car.testimonials
        : [],
    purchasedAt: car.purchasedAt || new Date().toISOString(),
    // Adicione outros campos VIP se necessário
  };
};

// Função para normalizar dados dos carros Normais
const normalizeNormalCarData = (car) => {
  return {
    id: car.id || 'N/A',
    marca: car.marca || 'N/A',
    modelo: car.modelo || 'N/A',
    ano: typeof car.ano === 'number' ? car.ano : 'N/A',
    km: typeof car.km === 'number' ? car.km : 0,
    tipo: car.tipo || 'N/A',
    potencia: typeof car.potencia === 'number' ? car.potencia : 0,
    valor: typeof car.valor === 'number' ? car.valor : 0,
    imagens:
      Array.isArray(car.imagens) && car.imagens.length > 0
        ? car.imagens
        : ['https://via.placeholder.com/300'],
    // Campos exclusivos de Carros Normais são omitidos
  };
};

const ColecaoVip = () => {
  const navigate = useNavigate();
  const [cars, setCars] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const screens = useBreakpoint();

  // Função para lidar com o logout
  const handleLogout = useCallback(() => {
    signOut(auth)
      .then(() => {
        console.log('Logout bem-sucedido');
        navigate('/auth'); // Redireciona para a página de login
      })
      .catch((error) => {
        console.error('Erro ao sair:', error);
        message.error('Erro ao sair. Tente novamente.');
      });
  }, [auth, navigate]);

  // Função para renderizar itens do menu
  const renderMenuItems = useCallback(
    () => (
      <>
        <Menu.Item
          key="1"
          icon={<DollarOutlined style={{ fontSize: '18px' }} />}
          title="Dashboard"
        >
          <Link to="/dashboard">Painel</Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<ShopOutlined style={{ fontSize: '18px' }} />}
          title="Showroom"
        >
          <Link to="/showroom">Minha loja</Link>
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<CarOutlined style={{ fontSize: '18px' }} />}
          title="Mercado"
        >
          <Link to="/market">Mercado</Link>
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<UserOutlined style={{ fontSize: '18px' }} />}
          title="Coleção Pessoal"
        >
          <Link to="/colecaopessoal">Coleção</Link>
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<CrownOutlined style={{ fontSize: '18px' }} />}
          title="Ranking"
        >
          <Link to="/ranking">Rank</Link>
        </Menu.Item>
        <Menu.Item
          key="10"
          icon={<LogoutOutlined style={{ fontSize: '23px' }} />}
          onClick={handleLogout}
        >
          <span>Logout</span>
        </Menu.Item>
      </>
    ),
    [handleLogout]
  );

  // Função para buscar todos os carros da subcoleção 'colecaovip'
  const fetchAllCars = useCallback(async () => {
    try {
      setLoading(true);
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado');
        navigate('/auth');
        return;
      }

      const playerId = user.uid;
      const carsRef = collection(
        firestore,
        'players',
        playerId,
        'colecaovip'
      );
      const carsQuery = query(carsRef, orderBy('purchasedAt', 'desc'));
      const carsSnapshot = await getDocs(carsQuery);
      const carsList = carsSnapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        // Determinar se o carro é VIP com base em 'goldvalue'
        if (data.goldvalue && data.goldvalue > 0) {
          return normalizeVIPCarData({
            id: docSnap.id,
            ...data,
          });
        } else {
          return normalizeNormalCarData({
            id: docSnap.id,
            ...data,
          });
        }
      });

      console.log('Carros carregados na coleção pessoal:', carsList); // Log para depuração
      setCars(carsList);
    } catch (error) {
      console.error('Erro ao buscar carros:', error);
      message.error('Erro ao buscar carros.');
    } finally {
      setLoading(false);
    }
  }, [auth, navigate]);

  useEffect(() => {
    fetchAllCars();
  }, [fetchAllCars]);

  // Função para lidar com o clique em um carro
  const handleCarClick = useCallback((car) => {
    setSelectedCar(car);
    setModalVisible(true);
  }, []);

  // Função para fechar o modal
  const closeModal = useCallback(() => {
    setModalVisible(false);
    setSelectedCar(null);
  }, []);

  // Função para converter URL tradicional do YouTube para URL de embed
  const getYouTubeEmbedURL = useCallback((url) => {
    if (!url) return null;
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}?autoplay=1&mute=0`;
    }
    return null;
  }, []);

  // Função para calcular o valor de revenda
  const calculateResellValue = useCallback((goldValue) => {
    return goldValue * 10000 * 5; // Ajuste conforme a lógica de negócio
  }, []);

  // Função para adicionar o carro à coleção pessoal (caso necessário)
  const handleAddToCollection = useCallback(async () => {
    if (!selectedCar) return;

    try {
      const user = auth.currentUser;
      if (!user) {
        message.error('Usuário não autenticado');
        return;
      }

      const playerId = user.uid;
      const playerRef = doc(firestore, 'players', playerId);
      const showroomCollectionRef = collection(playerRef, 'showroom');
      const collectionCollectionRef = collection(playerRef, 'colecaovip');

      const carRefInShowroom = doc(showroomCollectionRef, selectedCar.id);
      const carSnap = await getDoc(carRefInShowroom);

      if (!carSnap.exists()) {
        message.error('Carro não encontrado no showroom.');
        return;
      }

      const carData = selectedCar.goldvalue > 0
        ? normalizeVIPCarData({
            id: carSnap.id,
            ...carSnap.data(),
          })
        : normalizeNormalCarData({
            id: carSnap.id,
            ...carSnap.data(),
          });

      const collectionCarRef = doc(collectionCollectionRef, carSnap.id);

      const batch = writeBatch(firestore);
      batch.set(collectionCarRef, carData); // Usa dados normalizados
      batch.delete(carRefInShowroom);

      await batch.commit();

      message.success('Carro movido com sucesso para a Coleção Pessoal!');
      closeModal();
      fetchAllCars(); // Atualiza a coleção pessoal
    } catch (error) {
      console.error('Erro ao mover carro para a Coleção Pessoal:', error);
      message.error('Erro ao mover carro para a Coleção Pessoal.');
    }
  }, [auth, closeModal, selectedCar, fetchAllCars]);

  // Separar carros VIP e Normais
  const vipCars = cars.filter((car) => car.goldvalue > 0);
  const normalCars = cars.filter((car) => car.goldvalue === 0);

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        {/* Seção da Esquerda: Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px' }}
            />
            <Button
              type="text"
              icon={<GoldOutlined style={{ fontSize: '24px', color: '#ffcf40' }} />}
              onClick={() => navigate('/shop')} // Navega para a tela loja
              title="Mina de Ouro"
              style={{ color: '#fff', marginLeft: '16px' }} // Estilo para o texto
            >
              Mina de Ouro
            </Button>
          </div>
        </div>

        {/* Menu de Navegação */}
        <div>
          {screens.md ? (
            <Menu
              theme="dark"
              mode="horizontal"
              style={{ background: '#080808', lineHeight: '64px' }}
            >
              {renderMenuItems()}
            </Menu>
          ) : (
            <Button
              type="text"
              icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
              onClick={() => setIsDrawerVisible(true)}
              className="drawer-toggle-button"
            />
          )}
        </div>
      </Header>

      {/* Drawer para navegação mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={() => setIsDrawerVisible(false)}
        open={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">
          {/* Itens do menu (mesmos do menu desktop) */}
          {renderMenuItems()}
        </Menu>
      </Drawer>

      {/* Conteúdo Principal */}
      <Layout>
        <Content style={{ padding: '24px' }}>
          {/* Título e Descrição */}
          <Row justify="start" style={{ marginBottom: '24px' }}>
            <Col xs={24}>
              <Title level={3} style={{ marginBottom: '16px' }}>
                Meus Carros
              </Title>
              <Text style={{ fontSize: '18px', color: '#999' }}>
                Conquiste os carros que você sempre quis e monte sua garagem dos sonhos nesse espaço.
                Outros jogadores poderão ver a sua coleção.
              </Text>
            </Col>
          </Row>

          {/* Seção de Carros VIP */}
          <div style={{ marginBottom: '40px' }}>
          
            {vipCars.length > 0 ? (
              <Row gutter={[16, 16]}>
                {vipCars.map((car) => (
                  <Col
                    key={car.id}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={6}
                    style={{ textAlign: 'center' }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <p><strong>Ano:</strong> {car.ano}</p>
                          <p><strong>Valor:</strong> {car.valor ? `R$ ${car.valor.toLocaleString('pt-BR')}` : 'N/A'}</p>
                          <p><strong>Km:</strong> {car.km ? `${car.km.toLocaleString('pt-BR')} km` : 'N/A'}</p>
                          <p><strong>Tipo:</strong> {car.tipo}</p>
                          <p><strong>Potência:</strong> {car.potencia ? `${car.potencia} cv` : 'N/A'}</p>
                          <p><strong>Gold Value:</strong> {car.goldvalue ? `${car.goldvalue} Ouro` : 'N/A'}</p>
                          {/* Adicione mais informações VIP se necessário */}
                        </div>
                      }
                    >
                      <div
                        style={{
                          position: 'relative',
                          paddingBottom: '100%',
                          overflow: 'hidden',
                          borderRadius: '8px',
                          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                          cursor: 'pointer',
                          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = 'scale(1.05)';
                          e.currentTarget.style.boxShadow = '0 4px 12px rgba(21, 80, 240, 0.8)';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = 'scale(1)';
                          e.currentTarget.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.1)';
                        }}
                        onClick={() => handleCarClick(car)}
                      >
                        <img
                          src={car.imagens[0]}
                          alt={`${car.marca} ${car.modelo}`}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '8px',
                          }}
                          loading="lazy"
                        />
                      </div>
                    </Tooltip>
                    <Title level={5} style={{ marginTop: '8px' }}>
                      {car.marca} {car.modelo}
                    </Title>
                    
                  </Col>
                ))}
              </Row>
            ) : (
              <Row justify="center">
                <Text type="secondary">Nenhum Carro VIP encontrado na sua coleção pessoal.</Text>
              </Row>
            )}
          </div>

          

          {/* Modal de Detalhes do Carro */}
          {selectedCar && (
            <Modal
              open={modalVisible}
              onCancel={closeModal}
              footer={null}
              width={screens.xs ? '95%' : '80%'}
              centered
              destroyOnClose
              bodyStyle={{ padding: '24px' }}
            >
              {/* Seção de Imagens */}
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <Carousel autoplay dotPosition="bottom">
                    {selectedCar.imagens.map((image, index) => (
                      <div key={index} style={styles.carouselItem}>
                        <img
                          src={image}
                          alt={`Imagem ${index + 1} do ${selectedCar.marca} ${selectedCar.modelo}`}
                          style={styles.carImage}
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </Carousel>
                </Col>
              </Row>

              {/* Seção de Descrição */}
              <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
                <Col xs={24}>
                  <div style={styles.introduction}>
                    <Title level={4} style={styles.carTitle}>
                      {selectedCar.marca} {selectedCar.modelo}
                    </Title>
                    <Divider style={styles.divider} />
                    {/* Exibir descrição conforme tipo do carro */}
                    {selectedCar.goldvalue > 0 ? (
                      <Paragraph style={styles.description}>
                        {selectedCar.desc1 || 'Descrição não disponível para este carro.'}
                      </Paragraph>
                    ) : (
                      <Paragraph style={styles.description}>
                        {selectedCar.desc1 || 'Descrição não disponível para este carro.'}
                      </Paragraph>
                    )}
                    {/* Botões de Ação */}
                  </div>
                </Col>
              </Row>

              {/* Seção do Vídeo do YouTube (Somente VIP se aplicável) */}
              {selectedCar.videoURL && selectedCar.goldvalue > 0 && (
                <>
                  <Divider orientation="left" style={styles.divider}>
                    Vídeo do Carro
                  </Divider>
                  <Row gutter={[24, 24]}>
                    <Col xs={24}>
                      <div style={styles.videoContainer}>
                        <iframe
                          src={getYouTubeEmbedURL(selectedCar.videoURL)}
                          title={`${selectedCar.marca} ${selectedCar.modelo} Vídeo`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          style={styles.videoIframe}
                        ></iframe>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {/* Seção da Ficha Técnica */}
              <Divider orientation="left" style={styles.divider}>
                Ficha Técnica
              </Divider>
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={12}>
                  <Descriptions column={1} bordered>
                    <Descriptions.Item label="Marca">{selectedCar.marca}</Descriptions.Item>
                    <Descriptions.Item label="Modelo">{selectedCar.modelo}</Descriptions.Item>
                    <Descriptions.Item label="Ano">{selectedCar.ano}</Descriptions.Item>
                    <Descriptions.Item label="Km">
                      {selectedCar.km ? `${selectedCar.km.toLocaleString('pt-BR')} km` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Carroceria">{selectedCar.tipo}</Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col xs={24} sm={12}>
                  <Descriptions column={1} bordered>
                    <Descriptions.Item label="Potência">
                      {selectedCar.potencia ? `${selectedCar.potencia} cv` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor">
                      {selectedCar.goldvalue
                        ? `💰 ${selectedCar.goldvalue.toLocaleString('pt-BR')} Ouro`
                        : selectedCar.valor
                        ? `R$ ${selectedCar.valor.toLocaleString('pt-BR')}`
                        : 'Valor Indisponível'}
                    </Descriptions.Item>
                    {/* Campos adicionais VIP */}
                    {selectedCar.goldvalue > 0 && (
                      <Descriptions.Item label="Influência">
                        {selectedCar.influence || 'N/A'}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </Col>
              </Row>

              {/* Seção de História e Exclusividade (Somente VIP) */}
              {selectedCar.goldvalue > 0 && selectedCar.desc2 && (
                <>
                  <Divider orientation="left" style={styles.divider}>
                    História e Exclusividade
                  </Divider>
                  <Row gutter={[24, 24]}>
                    <Col xs={24}>
                      <Paragraph style={styles.carStory}>{selectedCar.desc2}</Paragraph>
                      <Paragraph style={styles.resellMessage}>
                        Você poderá revender esse carro por até{' '}
                        <strong>
                          {calculateResellValue(selectedCar.goldvalue).toLocaleString(
                            'pt-BR'
                          )} milhões de reais
                        </strong>{' '}
                        para outro jogador.
                      </Paragraph>
                    </Col>
                  </Row>
                </>
              )}

              {/* Seção de Depoimentos (Somente VIP) */}
              {selectedCar.goldvalue > 0 && selectedCar.testimonials && selectedCar.testimonials.length > 0 && (
                <>
                  <Divider orientation="left" style={styles.divider}>
                    O que dizem sobre este carro
                  </Divider>
                  <Row gutter={[24, 24]}>
                    {selectedCar.testimonials.map((testimonial, index) => (
                      <Col key={index} xs={24} sm={12}>
                        <div style={styles.testimonial}>
                          <Text strong>"{testimonial.quote}"</Text>
                          <br />
                          <Text>- {testimonial.author}</Text>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </>
              )}

             
            </Modal>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

const styles = {
  carouselItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    height: '400px', // Altura ajustada para melhor visualização
    position: 'relative',
    margin: '0 16px', // Margens horizontais
  },
  carImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
  imagePlaceholder: {
    width: '100%',
    height: '400px', // Altura consistente com o carrossel
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#777',
    fontSize: '18px',
    margin: '0 16px', // Margens horizontais
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    paddingTop: '25px',
    height: 0,
    width: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    marginBottom: '24px',
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  introduction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '0 16px',
  },
  carTitle: {
    fontSize: '28px',
    marginBottom: '8px',
    color: '#1c1c1c',
    wordBreak: 'break-word',
  },
  divider: {
    borderTop: '2px solid #eee',
    margin: '24px 0',
  },
  description: {
    fontSize: '16px',
    color: '#555',
    wordBreak: 'break-word',
  },
  carStory: {
    fontSize: '16px',
    color: '#555',
    wordBreak: 'break-word',
  },
  resellMessage: {
    fontSize: '16px',
    color: '#555',
    marginTop: '16px',
    wordBreak: 'break-word',
  },
  testimonial: {
    backgroundColor: '#fafafa',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default ColecaoVip;
