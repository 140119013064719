import React, { useState, useEffect } from 'react';
import {
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Divider,
  Button,
  Modal,
  Alert,
  Drawer,
  Grid,
  Carousel,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import applySubscriptionBenefits from './applySubscriptionBenefits';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import {
  AppstoreOutlined,
  ShoppingOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  RocketOutlined,
  CompassOutlined,
  NotificationOutlined,
  
  FundOutlined,
  StarOutlined,
  DollarOutlined,
  CrownOutlined,
  ShoppingFilled,
  GoldOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  BellOutlined,
} from '@ant-design/icons';
import AchievementsScreen from './AchievementsScreen';
import OffersSection from './OffersSection';
import OffersSection2 from './OffersSection2';
import WarningCard from './WarningCard';
import PaymentVerificationCard from './PaymentVerificationCard';

import OfertaRelampago from './OfertaRelampago';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const Dashboard = () => {
  const [metrics, setMetrics] = useState({});
  const [balance, setBalance] = useState(0);
  const [gold, setGold] = useState(0); // Estado para o saldo em ouro
  const [loading, setLoading] = useState(true);
  const [playerData, setPlayerData] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [isTutorialModalVisible, setIsTutorialModalVisible] = useState(false); // Novo estado para o modal de tutorial
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState([]); // Adicionada a definição de setHistoryData
  const auth = getAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchPlayerData(user.uid);
    } else {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, navigate]);

  useEffect(() => {
    const fetchAndApplyBenefits = async () => {
      const user = auth.currentUser;
      if (user) {
        console.log(`Tentando aplicar benefícios para playerId: ${user.uid}`);
        await applySubscriptionBenefits(user.uid);
        fetchPlayerData(user.uid);
      } else {
        console.log('Nenhum usuário autenticado encontrado.');
      }
    };

    fetchAndApplyBenefits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const showHelpModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleHelpModalOk = () => {
    setIsHelpModalVisible(false);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalVisible(false);
  };

  const showTutorialModal = () => {
    setIsTutorialModalVisible(true);
  };

  const handleTutorialModalOk = () => {
    setIsTutorialModalVisible(false);
  };

  const handleTutorialModalCancel = () => {
    setIsTutorialModalVisible(false);
  };

  const fetchPlayerData = async (userId) => {
    try {
      const playerDocRef = doc(firestore, 'players', userId);
      const showroomCollectionRef = collection(playerDocRef, 'showroom');

      const [playerDocSnap, showroomSnapshot] = await Promise.all([
        getDoc(playerDocRef),
        getDocs(showroomCollectionRef),
      ]);

      if (playerDocSnap.exists()) {
        const playerData = playerDocSnap.data();
        setBalance(Number(playerData.balance) || 0);
        setGold(Number(playerData.gold) || 0); // Atualiza o ouro
        setPlayerData(playerData);
        const metricsData = playerData.metrics || {};

        let carsInShowroom = 0;
        let totalShowroomValue = 0;

        showroomSnapshot.forEach((doc) => {
          carsInShowroom += 1;
          totalShowroomValue += doc.data().valor || 0;
        });

        setMetrics({
          ...metricsData,
          carnumber: carsInShowroom,
          showroomValue: totalShowroomValue,
        });
      } else {
        console.log('Documento do jogador não encontrado.');
        setError('Dados do jogador não encontrados.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      setError('Erro ao buscar dados do jogador.');
    } finally {
      setLoading(false);
    }
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Spin size="large" tip="Carregando..." />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Alert message="Erro" description={error} type="error" showIcon />
      </div>
    );
  }

  const getLogoUrl = () => {
    if (!playerData || playerData.sub === undefined || playerData.sub === null) {
      return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb';
    }

    switch (playerData.sub) {
      case 1:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb';
      case 2:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb';
      default:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb';
    }
  };

  const getProfileImageUrl = () => {
    if (!playerData || playerData.level === undefined || playerData.level === null) {
      // URL padrão se não houver um nível definido
      return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
    }
  
    if (playerData.level > 10) {
      // Use a URL para o nível 10 se o nível do jogador for maior que 10
      return 'URL_para_nível_10';
    }
  
    switch (playerData.level) {
      case 1:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.25.55%20-%20A%20realistic%203D%20diorama%20of%20a%20small%20car%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20has%20a%20modern%20design%20w.webp?alt=media&token=48c076c2-0c0b-4903-8f2b-8b5193c6ab49';
      case 2:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.16.55%20-%20A%20more%20advanced%203D%20diorama%20of%20a%20small%20car%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20features%20a%20more%20s.webp?alt=media&token=1bb63ba7-9813-46e5-af96-7d422b9b354e';
      case 3:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.19.12%20-%20A%20refined%2C%20advanced%203D%20diorama%20of%20a%20luxury%20car%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20has%20a%20sleek%20.webp?alt=media&token=ddfa3bd4-4d05-466f-a4be-26dbe966aab9';
      case 4:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.21.21%20-%20An%20ultra-luxurious%203D%20diorama%20of%20a%20supercar%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20architectural%20design%20inclu.webp?alt=media&token=5e469307-8f36-4336-948f-e1137520986b';
      case 5:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.20.51%20-%20A%20luxurious%203D%20diorama%20of%20a%20supercar%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20features%20a%20sleek%2C%20mode.webp?alt=media&token=7ed614c4-d066-4112-bca7-b78837c55899';
      case 6:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.19.46%20-%20A%20slightly%20more%20luxurious%203D%20diorama%20of%20a%20high-end%20car%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20show.webp?alt=media&token=d957946d-00c6-4511-8bd5-6a579c087c6d';
      case 7:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.18.17%20-%20A%20highly%20detailed%203D%20diorama%20of%20a%20luxury%20car%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20dealership%20showcases%20an%20e.webp?alt=media&token=89b34194-5bf8-4fe7-91d2-d9ab06fc8906';
      case 8:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.22.59%20-%20A%20grand%2C%20ultra-luxurious%203D%20diorama%20of%20a%20supercar%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20design%20spans%20across%20.webp?alt=media&token=07cfad1b-4f70-4cf9-9b52-ef2c3dcecaf3';
      case 9:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.21.55%20-%20An%20ultra-luxurious%2C%20wide%203D%20diorama%20of%20a%20supercar%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20architectural%20design.webp?alt=media&token=15c342d5-db74-4476-a9cf-6ea1da6ce3ee';
      case 10:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2013.24.57%20-%20A%20colossal%2C%20ultra-luxurious%203D%20diorama%20of%20a%20flagship%20supercar%20dealership%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20building.webp?alt=media&token=9b1d9bfc-c0b8-4be3-8124-2f0400cdce4b';
        case 11:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 12:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 13:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 14:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 15:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 16:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 17:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 18:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 19:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
        case 20:
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';

      default:
        // URL padrão se o nível não estiver entre 1 e 10
        return 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/perfil%2FDALL%C2%B7E%202024-10-18%2014.00.49%20-%20A%20colossal%2C%20ultra-luxurious%20car%20empire%20spanning%20an%20entire%20island%2C%20fully%20visible%20and%20isolated%20with%20a%20solid%20contrasting%20background.%20The%20island%20features%20.webp?alt=media&token=648266a7-8b2d-4a39-b543-92d1c1ca6185';
    }
  };
  

  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<GoldOutlined style={{ fontSize: '18px', color: 'yellow' }} />} title="Ranking">
        <Link to="/shop">Upgrades</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<InstagramOutlined style={{ fontSize: '18px' }} />} title="Instagram">
  <a href="https://www.instagram.com/luxurygears.app" target="_blank" rel="noopener noreferrer"></a>
</Menu.Item>
<Menu.Item key="8" icon={<WhatsAppOutlined style={{ fontSize: '18px' }} />} title="Suporte">
  <a href="https://api.whatsapp.com/send?phone=5511912192520" target="_blank" rel="noopener noreferrer"></a>
</Menu.Item>

      
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      >
        {/* Seção Esquerda: Logo e Botão de Tutorial */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={getLogoUrl()} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          {/* Botão de Tutorial ao lado da logo */}
          <Button
  type="text"
  icon={<AppstoreOutlined style={{ fontSize: '19px', color: '#fff' }} />}
  onClick={() => navigate('/boasvindas')} // Navega para a tela Tutorial
  title="Tutorial"
  style={{ color: '#fff', marginLeft: '5px' }} // Estilo para o texto
>
  Menu Principal
</Button>
{/*<Button
  type="text"
  icon={<BellOutlined style={{ fontSize: '18px', color: '#fff' }} />}
  onClick={() => navigate('/tutorial')} // Navega para a tela Tutorial
  title="Novidades"
  style={{ color: '#fff', marginLeft: '10px' }} // Estilo para o texto
>
  Novidades
</Button>*/}

        </div>

        {/* Seção Direita: Menu */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {screens.md ? (
            <Menu theme="dark" mode="horizontal" style={{ background: '#080808' }}>
              {renderMenuItems()}
            </Menu>
          ) : (
            <Button
              type="text"
              icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
              onClick={showDrawer}
            />
          )}
        </div>
      </Header>

      {/* Drawer para Navegação Mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical" onClick={closeDrawer}>
        <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<RocketOutlined style={{ fontSize: '18px', color: 'yellow' }} />} title="Ranking">
        <Link to="/shop">Upgrade</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<InstagramOutlined style={{ fontSize: '18px' }} />} title="Instagram">
  <a href="https://www.instagram.com/luxurygears.app" target="_blank" rel="noopener noreferrer"></a>
</Menu.Item>
<Menu.Item key="8" icon={<WhatsAppOutlined style={{ fontSize: '18px' }} />} title="Suporte">
  <a href="https://api.whatsapp.com/send?phone=5511912192520" target="_blank" rel="noopener noreferrer"></a>
</Menu.Item>
          {/* Outros itens do menu */}
        </Menu>
      </Drawer>

      

      {/* Modal de Tutorial */}
      <Modal
        title="Tutorial do Jogo"
        visible={isTutorialModalVisible}
        onOk={handleTutorialModalOk}
        onCancel={handleTutorialModalCancel}
        footer={[
          <Button
            style={{ backgroundColor: '#000', borderColor: '#000' }}
            key="fechar-tutorial"
            type="primary"
            onClick={handleTutorialModalCancel}
          >
            Fechar
          </Button>,
        ]}

        centered
        width={800}
        bodyStyle={{ padding: '0', borderRadius: '8px' }}
        destroyOnClose
      >
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '0',
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/VIDEO_ID" // Substitua VIDEO_ID pelo ID do vídeo do YouTube
            title="Tutorial Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '8px',
            }}
          ></iframe>
        </div>
      </Modal>

      <Content style={{ margin: '0', backgroundColor: '#f0f2f5' }}>
        {/* Seção de Perfil e Métricas */}
        <div
          style={{
            background: '#080808',
            padding: '34px',
            borderBottom: '1px solid #1f1f1f',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          {/* Linha para Perfil, Saldo e Ouro */}
          <Row
            align="middle"
            gutter={[8, 8]}

            justify="right"
            style={{ width: '80%' }}
          >
            {/* Perfil */}
<Col xs={24} sm={24} md={8} lg={6} xl={6}>
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }}
    onClick={() => navigate('/perfil')} // Adicionado para navegação ao clicar no perfil
  >
    <img
  src={getProfileImageUrl()}
  alt="Perfil"
  style={{
    borderRadius: '4px',
    width: '220px',
    height: '220px',
    objectFit: 'cover',
    border: '2px solid #ffffff',
    marginRight: '16px',
    marginBottom: '25px',
  }}
/>

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      
    </div>
    
  </div>
  <Title level={4} style={{ color: '#ffffff', marginBottom: '8px' }}>
        {playerData?.name || 'Minha Loja'}
      </Title>
      <Text style={{ color: '#b3b3b3', fontSize: '18px' }}>
        Nvl: {playerData?.level || '1'}
      </Text>
</Col>


            {/* Saldo em Reais */}
            <Col xs={24} sm={12} md={8} lg={24} xl={8}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 20px',
                  background: '#202020',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  
                  
                  transition: 'background 0.3s',
                  marginBottom: screens.xs ? '26px' : '0', // Espaçamento no mobile
                  marginBottom: screens.xl ? '16px' : '0', // Espaçamento no mobile
                  marginBottom: screens.lg ? '16px' : '0', // Espaçamento no mobile
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Evita que o clique na div redirecione para /colecaopessoal
                  navigate('/extrato');
                }}
                onMouseEnter={(e) => (e.currentTarget.style.background = '#333333')}
                onMouseLeave={(e) => (e.currentTarget.style.background = '#202020')}
              >
                <DollarOutlined style={{ color: '#fff', marginRight: '8px', fontSize: '20px' }} />
                <div>
                  <Text style={{ color: '#ffffff', fontSize: '16px' }}></Text>
                  <Text strong style={{ color: '#ffffff', fontSize: '20px' }}>
                    R${balance.toLocaleString('pt-BR')}
                  </Text>
                </div>
              </div>
              <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', // Adicionado para separar o saldo e o botão
      padding: '10px 20px',
      background: '#202020',
      borderRadius: '8px',
      
    }}
    
  >
    
    <div style={{ display: 'flex', alignItems: 'center',  }}>
      <GoldOutlined style={{ color: '#FFD700', marginRight: '8px', fontSize: '20px' }} />
      <div>
        <Text style={{ color: '#ffffff', fontSize: '16px' }}></Text>
        <Text strong style={{ color: '#ffffff', fontSize: '20px' }}>
          {gold.toLocaleString('pt-BR')}
        </Text>
      </div>
    </div>
    <Button
      type="link"
      onClick={() => navigate('/shop')}
      style={{
        
        borderRadius: '50%',
        width: '36px',
        
        height: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
      }}
    >
      <span style={{ fontSize: '30px', color: '#FFD700' }}>+</span>
    </Button>
  </div>
            </Col>

             
             

          </Row>

          {/* Seção de Métricas */}
          <Row
            gutter={[16, 16]}
            justify="start"
            style={{ marginTop: '24px', width: '100%' }}
          >
            {metrics.totalSales !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Vendidos</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    {metrics.totalSales}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.totalRevenue !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Receita</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    R${Number(metrics.totalRevenue || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.carrosComprados !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Comprados</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    {Number(metrics.carrosComprados || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.totalProfit !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Lucro Total</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    R${Number(metrics.totalProfit || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.showroomValue !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Valor Showroom</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    R${Number(metrics.showroomValue || 0).toLocaleString('pt-BR')}
                  </Text>
                </Card>
              </Col>
            )}
            {metrics.carnumber !== undefined && (
              <Col xs={12} sm={12} md={8} lg={4}>
                <Card
                  size="small"
                  title={<span style={{ color: '#f6f6f6' }}>Carros na Loja</span>}
                  style={{
                    borderRadius: '4px',
                    border: '1px solid #202020',
                    background: '#202020',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  bodyStyle={{ padding: '8px' }}
                >
                  <Text strong style={{ fontSize: '18px', color: '#f6f6f6' }}>
                    {metrics.carnumber}
                  </Text>
                </Card>
              </Col>
            )}
          </Row>
        </div>

        {/* Conteúdo Principal */}
        <div
          style={{
            padding: '24px',
            background: '#f0f2f5',
            minHeight: '360px',
          }}
        >
          <Divider style={{ margin: '24px 0' }} />

          {/* Seção de Conquistas */}
          
          <WarningCard/>
          <AchievementsScreen />
          <OffersSection />
          <OffersSection2 />
          
          
        </div>
      </Content>
    </Layout>
  );
};

export default Dashboard;
