import React, { useState, useEffect } from 'react';
import { Layout, Table, Tag, Select, Switch, Button, Modal, message, Typography, InputNumber, Row, Col } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

// Dados iniciais de funcionários e despesas
const initialEmployees = [
  {
    key: '1',
    name: 'Lucas Fernandes',
    role: 'Lavador de Carros',
    salary: 2500,
    lastTask: 'Lavagem Completa',
    status: 'Ativo',
    actions: ['Aumento Salarial', 'Treinamento', 'Aviso'],
  },
  {
    key: '2',
    name: 'Ana Souza',
    role: 'Gerente de Loja',
    salary: 5000,
    lastTask: 'Relatório Mensal',
    status: 'Ativo',
    actions: ['Aumento Salarial', 'Treinamento', 'Aviso'],
  },
  {
    key: '3',
    name: 'Carlos Lima',
    role: 'Limpeza',
    salary: 1800,
    lastTask: 'Limpeza do Showroom',
    status: 'Ativo',
    actions: ['Aumento Salarial', 'Treinamento', 'Aviso'],
  },
];

const initialExpenses = {
  water: { active: true, cost: 400 },
  electricity: { active: true, cost: 800 },
  cleaning: { active: true, cost: 500 },
  carWash: { active: true, cost: 600 },
  maintenance: { active: true, cost: 1000 },
};

const EmployeeExpenseManagement = () => {
  const [employees, setEmployees] = useState(initialEmployees); // Estado de funcionários
  const [expenses, setExpenses] = useState(initialExpenses); // Estado de despesas
  const [totalSalary, setTotalSalary] = useState(0); // Total de salários
  const [totalExpenses, setTotalExpenses] = useState(0); // Total de despesas fixas
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal de ações
  const [selectedAction, setSelectedAction] = useState(null); // Ação selecionada
  const [currentEmployee, setCurrentEmployee] = useState(null); // Funcionário atual

  // Função para calcular total de salários e despesas
  const calculateTotals = () => {
    const salarySum = employees.reduce((acc, employee) => acc + employee.salary, 0);
    const expenseSum = Object.values(expenses)
      .filter(expense => expense.active)
      .reduce((acc, expense) => acc + expense.cost, 0);
    setTotalSalary(salarySum);
    setTotalExpenses(expenseSum);
  };

  useEffect(() => {
    calculateTotals();
  }, [employees, expenses]);

  // Lidar com ações de funcionários
  const handleAction = (employee, action) => {
    setSelectedAction(action);
    setCurrentEmployee(employee);
    setIsModalVisible(true);
  };

  const confirmAction = () => {
    if (selectedAction === 'Aumento Salarial') {
      const newEmployees = employees.map(emp =>
        emp.key === currentEmployee.key ? { ...emp, salary: emp.salary + 500 } : emp
      );
      setEmployees(newEmployees);
      message.success(`Salário de ${currentEmployee.name} foi aumentado!`);
    } else if (selectedAction === 'Treinamento') {
      message.success(`${currentEmployee.name} foi enviado para treinamento!`);
    } else if (selectedAction === 'Aviso') {
      message.warning(`Um aviso foi enviado para ${currentEmployee.name}.`);
    }
    setIsModalVisible(false);
  };

  // Lidar com toggles de despesas
  const handleToggleExpense = (expenseKey) => {
    const newExpenses = {
      ...expenses,
      [expenseKey]: {
        ...expenses[expenseKey],
        active: !expenses[expenseKey].active,
      },
    };
    setExpenses(newExpenses);
    message.info(`${expenseKey.charAt(0).toUpperCase() + expenseKey.slice(1)} foi ${newExpenses[expenseKey].active ? 'ativado' : 'desativado'}.`);
  };

  // Lidar com o pagamento de salários e despesas
  const handlePaySalaries = () => {
    message.success(`Salários pagos: R$${totalSalary.toLocaleString('pt-BR')}`);
  };

  const handlePayExpenses = () => {
    message.success(`Despesas pagas: R$${totalExpenses.toLocaleString('pt-BR')}`);
  };

  // Colunas da tabela de funcionários
  const employeeColumns = [
    {
      title: 'Funcionário',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Função',
      dataIndex: 'role',
      key: 'role',
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: 'Última Tarefa',
      dataIndex: 'lastTask',
      key: 'lastTask',
      render: (text) => <Tag color="green">{text}</Tag>,
    },
    {
      title: 'Salário',
      dataIndex: 'salary',
      key: 'salary',
      render: (salary) => <Tag color="gold">R${salary.toLocaleString('pt-BR')}</Tag>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag color={status === 'Ativo' ? 'green' : 'red'}>{status}</Tag>,
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (employee) => (
        <Select
          placeholder="Selecionar Ação"
          style={{ width: '100%' }}
          onChange={(value) => handleAction(employee, value)}
        >
          {employee.actions.map((action) => (
            <Option key={action} value={action}>
              {action}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <Layout style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
      <Title level={2}>Gerenciamento de Funcionários e Despesas</Title>

      {/* Seção de Funcionários */}
      <Title level={3}>Funcionários</Title>
      <Table
        columns={employeeColumns}
        dataSource={employees}
        pagination={false}
        bordered
        style={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}
      />

      {/* Seção de Despesas */}
      <Title level={3} style={{ marginTop: '24px' }}>Despesas Fixas</Title>
      <Row gutter={16}>
        {Object.keys(expenses).map((key) => (
          <Col key={key} span={8}>
            <div
              style={{
                backgroundColor: 'white',
                padding: '16px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <Text>{key.charAt(0).toUpperCase() + key.slice(1)}</Text>
              <Switch
                checked={expenses[key].active}
                onChange={() => handleToggleExpense(key)}
              />
              <Tag color="volcano">R${expenses[key].cost.toLocaleString('pt-BR')}</Tag>
            </div>
          </Col>
        ))}
      </Row>

      {/* Total de despesas e salários */}
      <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '10px', marginTop: '24px' }}>
        <Title level={4}>Total de Salários: <Tag color="gold">R${totalSalary.toLocaleString('pt-BR')}</Tag></Title>
        <Title level={4}>Total de Despesas: <Tag color="volcano">R${totalExpenses.toLocaleString('pt-BR')}</Tag></Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          <Button type="primary" icon={<DollarCircleOutlined />} onClick={handlePaySalaries}>
            Pagar Salários
          </Button>
          <Button type="primary" icon={<DollarCircleOutlined />} onClick={handlePayExpenses}>
            Pagar Despesas
          </Button>
        </div>
      </div>

      <Modal
        title={`Confirmar Ação: ${selectedAction}`}
        visible={isModalVisible}
        onOk={confirmAction}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <Text>
          Tem certeza que deseja realizar a ação <strong>{selectedAction}</strong> para{' '}
          <strong>{currentEmployee?.name}</strong>?<br />
          <br />
          {selectedAction === 'Aumento Salarial' && (
            <Text>
              Esta ação aumentará o salário de <strong>{currentEmployee?.name}</strong> em R$ 500.
            </Text>
          )}
          {selectedAction === 'Treinamento' && (
            <Text>
              Enviar <strong>{currentEmployee?.name}</strong> para treinamento melhorará suas habilidades e eficiência.
            </Text>
          )}
          {selectedAction === 'Aviso' && (
            <Text>
              Um aviso será enviado para <strong>{currentEmployee?.name}</strong> devido a desempenho inadequado ou atraso.
            </Text>
          )}
        </Text>
      </Modal>
    </Layout>
  );
};

export default EmployeeExpenseManagement;
