import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Carousel,
  message,
  Menu,
  Select,
  Spin,
  Tooltip,
  Form,
  Input,
  Drawer, // Adicionado para navegação responsiva
  Grid, // Importação do Grid para uso do useBreakpoint
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  BankOutlined,
  CompassOutlined,
  NotificationOutlined,
  FundOutlined,
  StarOutlined,
  DollarOutlined,
  CrownOutlined,
  RocketOutlined,
  ShoppingFilled,
  ShoppingOutlined,
  GoldOutlined,
  MenuOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { getAuth, signOut } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import CarCarousel from './CarCarousel'; 
import FloatingClientCard from './FloatingClientCard';
import HeaderProfileMetrics from './HeaderProfileMetrics';
import VipBanner from './VipBanner';

import {
  collection,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  query,
  where,
  limit,
  startAfter,
  enableIndexedDbPersistence,
} from 'firebase/firestore';

const { Content, Header } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid; // Uso do useBreakpoint

// Habilitar Persistência Offline (Cache Local)
enableIndexedDbPersistence(firestore)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.log('Persistência offline falhou: múltiplas abas abertas.');
    } else if (err.code === 'unimplemented') {
      console.log('Persistência offline não está suportada neste navegador.');
    }
  });

const Market = () => {
  // Estados principais
  const [cars, setCars] = useState([]);
  const [isBuying, setIsBuying] = useState(false); // Novo estado para controlar compras

  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [stores, setStores] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [filters, setFilters] = useState({
    marca: '',
    tipo: '',
    potenciaMax: '',
    kmMax: '',
    anoMax: '',
  });
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [playerSlots, setPlayerSlots] = useState(4); // Número de slots do jogador
  const [balance, setBalance] = useState(0); // Saldo do jogador

  const auth = getAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint(); // Hook para detectar o tamanho da tela

  // Refs para controlar lastVisible e evitar loops de renderização
  const lastVisibleRef = useRef(null);
  const isMounted = useRef(true);
  const debounceTimer = useRef(null);
  const loadingRef = useRef(false);

  // Função para atualizar loading e loadingRef
  const setLoadingState = useCallback((value) => {
    setLoading(value);
    loadingRef.current = value;
  }, []);

  // Função fetchCars sem dependências
  const fetchCars = useCallback(async (currentFilters, isNewQuery = false) => {
    if (loadingRef.current) return;
    setLoadingState(true);
    try {
      let carsQuery = collection(firestore, 'market_cars');
      let constraints = [];
  
      // Aplicar filtros usando 'currentFilters'
      if (currentFilters.marca) {
        constraints.push(where('marca', '==', currentFilters.marca));
      }
      if (currentFilters.tipo) {
        constraints.push(where('tipo', '==', currentFilters.tipo));
      }
  
      // Aplicar filtro de desigualdade
      if (currentFilters.potenciaMax) {
        const potenciaMax = parseInt(currentFilters.potenciaMax, 10);
        if (!isNaN(potenciaMax)) {
          constraints.push(where('potencia', '<=', potenciaMax));
        }
      } else if (currentFilters.kmMax) {
        const kmMax = parseInt(currentFilters.kmMax, 10);
        if (!isNaN(kmMax)) {
          constraints.push(where('km', '<=', kmMax));
        }
      } else if (currentFilters.anoMax) {
        const anoMax = parseInt(currentFilters.anoMax, 10);
        if (!isNaN(anoMax)) {
          constraints.push(where('ano', '<=', anoMax));
        }
      }
  
      constraints.push(limit(12));
  
      if (!isNewQuery && lastVisibleRef.current) {
        constraints.push(startAfter(lastVisibleRef.current));
      } else if (isNewQuery) {
        lastVisibleRef.current = null;
      }
  
      const q = query(carsQuery, ...constraints);
      const carsSnapshot = await getDocs(q);
  
      const carsList = carsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(), // Inclui todos os campos, incluindo 'imagens'
      }));
  
      const lastVisibleDoc = carsSnapshot.docs[carsSnapshot.docs.length - 1];
      lastVisibleRef.current = lastVisibleDoc || null;
  
      setHasMore(carsSnapshot.docs.length === 12);
  
      setCars((prevCars) => (isNewQuery ? carsList : [...prevCars, ...carsList]));
    } catch (error) {
      console.error('Erro ao buscar carros:', error);
      message.error('Erro ao buscar carros.');
    } finally {
      if (isMounted.current) setLoadingState(false);
    }
  }, []); // Sem dependências

  // Efeito para buscar dados iniciais
  useEffect(() => {
    isMounted.current = true;

    const fetchInitialData = async () => {
      setLoadingState(true);
      try {
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado.');
          navigate('/auth');
          return;
        }

        const playerDocRef = doc(firestore, 'players', user.uid);
        const storesCollectionRef = collection(firestore, 'stores');

        const [storesSnapshot, playerDoc] = await Promise.all([
          getDocs(storesCollectionRef),
          getDoc(playerDocRef),
        ]);

        if (storesSnapshot.empty) {
         
          setStores([]);
        } else {
          const storesList = storesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStores(storesList);
        }

        if (playerDoc.exists()) {
          const playerData = playerDoc.data();
          setPlayerSlots(playerData.slots || 4);
          setBalance(playerData.balance || 0);
        } else {
          message.error('Dados do jogador não encontrados.');
        }

        // Chamar fetchCars com os filtros atuais
        await fetchCars(filters, true);
      } catch (error) {
        console.error('Erro ao buscar dados iniciais:', error);
        message.error('Erro ao buscar dados iniciais.');
      } finally {
        if (isMounted.current) setLoadingState(false);
      }
    };

    fetchInitialData();

    return () => {
      isMounted.current = false;
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [auth, navigate, setLoadingState]); // Removido 'fetchCars' das dependências

  // Efeito para buscar carros quando os filtros mudam com debounce
  useEffect(() => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    debounceTimer.current = setTimeout(() => {
      setCars([]);
      lastVisibleRef.current = null;
      setHasMore(true);
      fetchCars(filters, true);
    }, 300); // 300ms de debounce

    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [filters, fetchCars]);

  // Função para carregar mais carros (paginação)
  const fetchMoreCars = useCallback(() => {
    if (!hasMore || loading) return;
    fetchCars(filters, false);
  }, [hasMore, loading, filters, fetchCars]);

  // Função para atualizar os filtros
  const handleFilterChange = useCallback((field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  }, []);

  // Função para resetar os filtros
  const resetFilters = useCallback(() => {
    setFilters({
      marca: '',
      tipo: '',
      potenciaMax: '',
      kmMax: '',
      anoMax: '',
    });
  }, []);

  // Função para abrir o modal ao clicar em um carro
  const handleCarClick = useCallback(async (car) => {
    setLoadingState(true);
    try {
      // Carregamento Sob Demanda: busca os dados completos do carro ao clicar
      const carRef = doc(firestore, 'market_cars', car.id);
      const carSnap = await getDoc(carRef);
      if (carSnap.exists()) {
        const carData = carSnap.data();
        setSelectedCar({ id: carSnap.id, ...carData });
        setModalVisible(true);
      } else {
        message.error('Detalhes do carro não encontrados.');
      }
    } catch (error) {
      console.error('Erro ao buscar detalhes do carro:', error);
      message.error('Erro ao buscar detalhes do carro.');
    } finally {
      setLoadingState(false);
    }
  }, []);

  // Função para fechar o modal
  const closeModal = useCallback(() => {
    setModalVisible(false);
    setSelectedCar(null);
  }, []);

  // Função para comprar um carro
  const handleBuyCar = useCallback(
    async (car) => {
      if (isBuying) return; // Impede múltiplas compras simultâneas
      try {
        setIsBuying(true); // Inicia o processo de compra
  
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado');
          return;
        }
  
        const playerId = user.uid;
        const playerRef = doc(firestore, 'players', playerId);
        const showroomCollectionRef = collection(firestore, 'players', playerId, 'showroom');
  
        const playerSnap = await getDoc(playerRef);
        if (!playerSnap.exists()) {
          message.error('Dados do jogador não encontrados.');
          return;
        }
  
        const playerData = playerSnap.data();
        const currentSlots = playerData.slots || 4;
  
        // Buscar o número de carros atualmente no showroom
        const showroomCarsSnap = await getDocs(showroomCollectionRef);
        const showroomCarsCount = showroomCarsSnap.size;
  
        if (showroomCarsCount >= currentSlots) {
          message.error(
            'Você não tem vagas disponíveis no showroom para comprar esse carro.'
          );
          return;
        }
  
        // Verificar se o jogador tem saldo suficiente
        if (playerData.balance < car.valor) {
          message.error('Saldo insuficiente para comprar este carro.');
          return;
        }
  
        const batch = writeBatch(firestore);
  
        // Adicionar o carro ao showroom (cópia)
        const newCarRef = doc(showroomCollectionRef);
        batch.set(newCarRef, {
          ...car,
          carId: newCarRef.id,
        });
  
        // Deduzir o saldo do jogador
        batch.update(playerRef, {
          balance: playerData.balance - car.valor,
          'metrics.totalPurchases': (playerData.metrics?.totalPurchases || 0) + 1,
          'metrics.totalSpent': (playerData.metrics?.totalSpent || 0) + car.valor,
          'metrics.carrosComprados': (playerData.metrics?.carrosComprados || 0) + 1,
        });
  
        // Adicionar uma transação
        const transactionRef = doc(collection(playerRef, 'transactions'));
        batch.set(transactionRef, {
          carId: car.id,
          marca: car.marca,
          modelo: car.modelo,
          valor: car.valor,
          data: new Date(),
          tipo: 'compra',
        });
  
        await batch.commit();
  
        message.success('Carro comprado com sucesso!');
        closeModal();
  
        // Atualizar o saldo local
        setBalance((prevBalance) => prevBalance - car.valor);
      } catch (error) {
        console.error('Erro ao comprar carro:', error);
        message.error('Erro ao comprar carro.');
      } finally {
        setIsBuying(false); // Finaliza o processo de compra
      }
    },
    [auth, closeModal, isBuying]
  );

  // Funções para controlar a visibilidade do modal de ajuda
  const showHelpModal = useCallback(() => {
    setIsHelpModalVisible(true);
  }, []);

  const handleHelpModalCancel = useCallback(() => {
    setIsHelpModalVisible(false);
  }, []);

  // Estado e funções para o Drawer (menu mobile)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const showDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  // Função para renderizar os itens do menu
  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      
      
      
      
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}> {/* Prevenção de overflow horizontal */}
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        {/* Seção da Esquerda: Logo e Botão "Como jogar" */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px', maxWidth: '100%' }}
            />
            <Button
  type="text"
  icon={<GoldOutlined style={{ fontSize: '24px', color: '#ffcf40' }} />}
  onClick={() => navigate('/shop')} // Navega para a tela loja
  title="Upgrades"
  style={{ color: '#fff', marginLeft: '16px' }} // Estilo para o texto
>
Upgrades
</Button>
          </div>

          {/*<Button
            type="default"
            style={{
              backgroundColor: '#080808',
              color: '#b1b1b1',
              borderColor: '#080808',
              marginLeft: '8px',
              borderRadius: '4px',
              height: '40px',
              fontWeight: '700',
            }}
            onClick={showHelpModal}
          >
            Como jogar?
          </Button>*/}
        </div>

        {/* Menu de Navegação */}
        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px', marginTop: '20px' }}>
            {renderMenuItems()}
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
            className="drawer-toggle-button"
          />
        )}
      </Header>

     {/* Drawer para navegação mobile */}
     <Drawer
          title="Menu de Navegação"
          placement="right"
          onClose={() => setIsDrawerVisible(false)}
          visible={isDrawerVisible}
          className="mobile-menu-drawer"
        >
          <Menu mode="vertical">
            {/* Itens do menu (mesmos do menu desktop) */}
            <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      
     
            
            
            {/* Outros itens do menu */}
          </Menu>
        </Drawer>

      {/* Conteúdo Principal */}
      <Layout>
        <FloatingClientCard />
        <VipBanner/>
        <Content style={{ padding: '16px' }}>
          
          {/* Filtros */}
          <Title level={4} style={{ textAlign: 'left', marginBottom: '16px' }}>
            Filtros de Carros
          </Title>
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6} lg={4}>
                <Form.Item label="Marca" style={{ marginBottom: '8px' }}>
                  <Select
                    placeholder="Selecione a marca"
                    style={{ width: '100%' }}
                    value={filters.marca}
                    onChange={(value) => handleFilterChange('marca', value)}
                  >
                    <Option value="">Todas</Option>
                    {/* Lista de marcas predefinidas */}
                    {[
                      'Acura',
                      'Alfa Romeo',
                      'Aston Martin',
                      'Audi',
                      'Bentley',
                      'BMW',
                      'Bugatti',
                      'BYD',
                      'Cadillac',
                      'Chevrolet',
                      'Chrysler',
                      
                      'Dodge',
                      'Ferrari',
                      'GMC',
                      
                      'Ford',
                      
                      
                      'Jaguar',
                      'Jeep',
                      'Kia',
                      'Koenigsegg',
                      'Lamborghini',
                      'Land Rover',
                      'Lexus',
                      'Maserati',
                      'Mazda',
                      'Mclaren',
                      'Mercedes',
                      
                      
                      'Nissan',
                      'Pagani',
      
                      'Porsche',
                      'Rolls-Royce',
                      'Subaru',
                      
                      'Tesla',
                      
                      'Volkswagen',
                      'Volvo',
                    ].map((marca) => (
                      <Option key={marca} value={marca}>
                        {marca}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4}>
                <Form.Item label="Carroceria" style={{ marginBottom: '8px' }}>
                  <Select
                    placeholder="Selecione o tipo"
                    style={{ width: '100%' }}
                    value={filters.tipo}
                    onChange={(value) => handleFilterChange('tipo', value)}
                  >
                    <Option value="">Todas</Option>
                    {/* Lista de tipos predefinidos */}
                    <Option value="Hatchback">Hatchback</Option>
                    <Option value="Sedan">Sedan</Option>
                    <Option value="SUV">SUV</Option>
                    <Option value="Crossover">Crossover</Option>
                    <Option value="Coupe">Coupe</Option>
                    <Option value="Conversível">Conversível</Option>
                    <Option value="Pickup">Pickup</Option>
                    <Option value="Van">Van</Option>
                    <Option value="Perua">Perua</Option>
                  </Select>
                </Form.Item>
              </Col>
              
              
              
              <Col xs={24} sm={12} md={6} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  style={{ backgroundColor: '#202020', width: '100%' }}
                  type="primary"
                  onClick={resetFilters}
                >
                  Limpar
                </Button>
              </Col>
            </Row>
          </Form>

          <div style={{ margin: '24px 0' }} />

          <Title level={4} style={{ textAlign: 'left', marginBottom: '16px' }}>
            Explorar
          </Title>

          {/* Indicador de carregamento */}
          {loading && (
            <Row justify="center">
              <Spin size="large" />
            </Row>
          )}

          {/* Mensagem quando nenhum carro é encontrado */}
          {!loading && cars.length === 0 && (
            <Row justify="center">
              <Text>Nenhum carro encontrado.</Text>
            </Row>
          )}

          {/* Grid de Carros */}
          <Row gutter={[16, 16]}>
            {cars.map((car) => (
              <Col
                key={car.id}
                xs={12}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                style={{ textAlign: 'center' }}
              >
                <Tooltip
                  title={
                    <div>
                      <p>
                        <strong>Ano:</strong> {car.ano}
                      </p>
                      <p>
                        <strong>Valor:</strong> R${car.valor.toLocaleString('pt-BR')}
                      </p>
                      <p>
                        <strong>Km:</strong> {car.km.toLocaleString('pt-BR')}
                      </p>
                      <p>
                        <strong>Tipo:</strong> {car.tipo}
                      </p>
                      <p>
                        <strong>Potência:</strong> {car.potencia} cv
                      </p>
                    </div>
                  }
                >
                  <div
                    style={{
                      position: 'relative',
                      paddingBottom: '100%',
                      overflow: 'hidden',
                      borderRadius: '4px',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                      cursor: 'pointer',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = 'scale(1.05)';
                      e.currentTarget.style.boxShadow = '0 4px 12px rgba(21, 80, 240, 0.8)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = 'scale(1)';
                      e.currentTarget.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.15)';
                    }}
                    onClick={() => handleCarClick(car)}
                  >
                    {/* Carregamento Sob Demanda da imagem de capa */}
                    <img
                      src={car.imagens[0]}
                      alt={car.modelo}
                      loading="lazy"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        maxWidth: '100%', // Previne overflow
                      }}
                    />
                  </div>
                </Tooltip>
                <Title level={5} style={{ marginTop: '8px' }}>
                  {car.marca} {car.modelo}
                </Title>
                <Text>R$ {car.valor.toLocaleString('pt-BR')}</Text>
              </Col>
            ))}
          </Row>

          {/* Botão para carregar mais carros */}
          {hasMore && !loading && (
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Button type="primary" onClick={fetchMoreCars}>
                Carregar Mais
              </Button>
            </Row>
          )}

          {/* Modal de Compra */}
          {selectedCar && (
            <Modal
              open={modalVisible}
              onCancel={closeModal}
              footer={null}
              width={800}
              centered
            >
              <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col xs={24} sm={24} md={12}>
                  {selectedCar.imagens && selectedCar.imagens.length > 0 ? (
                    <Carousel>
                      {selectedCar.imagens.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt={`${selectedCar.marca} ${selectedCar.modelo}`}
                            loading="lazy"
                            style={{
                              width: '100%',
                              height: 'auto',
                              borderRadius: '4px',
                              objectFit: 'cover',
                              maxWidth: '100%',
                            }}
                          />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '300px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#333',
                      }}
                    >
                      <Typography.Text>Imagem Indisponível</Typography.Text>
                    </div>
                  )}
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Title level={4} style={{ color: '#000' }}>
                    {selectedCar.marca} {selectedCar.modelo}
                  </Title>
                  <Text style={{ color: '#555' }}>Ano: {selectedCar.ano || 'N/A'}</Text>
                  <br />
                  <Text style={{ color: '#555' }}>Km: {selectedCar.km || 'N/A'}</Text>
                  <br />
                  <Text style={{ color: '#555' }}>Tipo: {selectedCar.tipo || 'N/A'}</Text>
                  <br />
                  <Text style={{ color: '#555' }}>
                    Potência: {selectedCar.potencia || 'N/A'} cv
                  </Text>
                  <br />
                  <Text style={{ color: '#555' }}>
                    Valor:{' '}
                    {selectedCar.valor
                      ? `R$ ${selectedCar.valor.toLocaleString('pt-BR')}`
                      : 'Indisponível'}
                  </Text>
                  <br />
                  <Text style={{ color: '#555' }}>
                    *Os valores de km, potência e preço não precisam ser exatos.*
                  </Text>
                  <br />
                  <br />
                  <Button
                    type="primary"
                    onClick={() => handleBuyCar(selectedCar)}
                    style={{
                      marginRight: '8px',
                      backgroundColor: '#1550F0',
                      borderColor: '#1550F0',
                    }}
                    disabled={isBuying} // Desabilita o botão durante a compra
                    loading={isBuying}  // Mostra o indicador de carregamento durante a compra
                  >
                    Comprar
                  </Button>

                  <Button
                    onClick={closeModal}
                    style={{
                      backgroundColor: '#6c757d',
                      color: '#fff',
                      borderColor: '#6c757d',
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Modal>
          )}

          
        </Content>
      </Layout>
    </Layout>
  );
};

export default Market;
