// OffersSection.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Button, Modal, message, Spin, Typography } from 'antd';
import {
  GoldOutlined, // Ícone representando barras de ouro
} from '@ant-design/icons';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto

const { Text } = Typography;

const OffersSection2 = () => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const auth = getAuth();

  /**
   * Função para buscar dados do jogador
   */
  const fetchPlayerData = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerDocRef = doc(firestore, 'players', user.uid);
        const playerDocSnap = await getDoc(playerDocRef);

        if (playerDocSnap.exists()) {
          const data = playerDocSnap.data();
          setPlayerData(data);
        } else {
          message.error('Documento do jogador não encontrado.');
        }
      } else {
        message.error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      message.error('Erro ao buscar dados do jogador.');
    } finally {
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  /**
   * Função para abrir o modal de confirmação
   */
  const showPurchaseConfirmation = (offer) => {
    setSelectedOffer(offer);
    setIsModalVisible(true);
  };

  /**
   * Função para confirmar a compra do pacote
   */
  const handleConfirmPurchase = async () => {
    if (playerData && selectedOffer) {
      const { gold, slots } = playerData; // Campos relevantes
      const { price, extraSlots } = selectedOffer;

      if (gold >= price) { // Verificação de saldo suficiente
        const newGold = gold - price; // Dedução de gold
        const newSlots = slots + extraSlots; // Incremento de slots

        try {
          const playerDocRef = doc(firestore, 'players', auth.currentUser.uid);

          // Atualiza o documento do jogador com o novo gold e slots
          await updateDoc(playerDocRef, {
            gold: newGold,
            slots: newSlots,
            updatedAt: serverTimestamp(), // Campo opcional para rastrear a última atualização
          });

          message.success(`Compra realizada com sucesso! Você agora possui ${newSlots} vagas.`);
          setPlayerData({
            ...playerData,
            gold: newGold,
            slots: newSlots,
          });

          setIsModalVisible(false);
        } catch (error) {
          console.error('Erro ao atualizar o jogador:', error);
          message.error('Erro ao realizar a compra.');
        }
      } else {
        message.error('Saldo de ouro insuficiente.');
      }
    }

    setIsModalVisible(false);
  };

  /**
   * Definir ofertas
   */
  const offers = [
    {
      title: 'Adicione +5 vagas à sua loja',
      description: '',
      price:500, // Preço em barras de ouro
      extraSlots: 5, // Número extra de slots
    },
    
  ];

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin tip="Carregando ofertas..." size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 16]}>
        {offers.map((offer, index) => (
          <Col key={index} xs={24} sm={24} md={24} lg={24}>
            <Card
              title={offer.title}
              bordered={false}
              style={{
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                textAlign: 'center',
              }}
            >
              
              
              <Button
                type="primary"
                icon={<GoldOutlined />}
                style={{
                  width: '100%',
                  backgroundColor: '#d4af37', // Cor dourada
                  borderColor: '#d4af37',
                  display: 'flex',
                  fontSize: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => showPurchaseConfirmation(offer)}
              >
                Adicione por 500 barras de ouro
              </Button>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal de Confirmação */}
      <Modal
        title="Confirmar Compra"
        visible={isModalVisible}
        onOk={handleConfirmPurchase}
        onCancel={() => setIsModalVisible(false)}
        okText="Confirmar"
        cancelText="Cancelar"
        confirmLoading={isProcessing}
      >
        {selectedOffer && (
          <div>
            <p>
              Confirmar a compra de <strong>{selectedOffer.extraSlots}</strong> vagas extras por{' '}
              <strong>{selectedOffer.price.toLocaleString('pt-BR')} barras de ouro</strong>?
            </p>
            <p>Recarregue a página após comprar para ver as atualizações.</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OffersSection2;
