import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Typography, Card, Spin, Alert, Button } from 'antd';
import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import { collection, query, where, orderBy, limit, getDocs, startAfter, Timestamp } from 'firebase/firestore';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Extrato = () => {
  const [transactions, setTransactions] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // Para paginação
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false); // Estado para carregamento adicional
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const pageSize = 20; // Número de transações por página

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const playerId = user.uid;
        const transactionsCollection = collection(firestore, 'players', playerId, 'transactions');

        // Definir a data limite para os últimos 30 dias
        const thirtyDaysAgo = Timestamp.fromDate(moment().subtract(30, 'days').toDate());

        // Consulta com filtros, ordenação e limite
        let transactionsQuery = query(
          transactionsCollection,
          where('data', '>=', thirtyDaysAgo),
          orderBy('data', 'desc'),
          limit(pageSize)
        );

        const transactionsSnapshot = await getDocs(transactionsQuery);

        const transactionsList = transactionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Atualiza o último documento visível para paginação
        const lastVisibleDoc = transactionsSnapshot.docs[transactionsSnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        setTransactions(transactionsList);
      } else {
        console.error('Usuário não autenticado');
        setError('Usuário não autenticado');
      }
    } catch (error) {
      console.error('Erro ao buscar transações: ', error);
      setError('Erro ao buscar transações');
    } finally {
      setLoading(false);
    }
  };

  // Função para carregar mais transações (paginação)
  const fetchMoreTransactions = async () => {
    if (!lastVisible) return;

    try {
      setLoadingMore(true);
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const playerId = user.uid;
        const transactionsCollection = collection(firestore, 'players', playerId, 'transactions');

        const thirtyDaysAgo = Timestamp.fromDate(moment().subtract(30, 'days').toDate());

        let transactionsQuery = query(
          transactionsCollection,
          where('data', '>=', thirtyDaysAgo),
          orderBy('data', 'desc'),
          startAfter(lastVisible),
          limit(pageSize)
        );

        const transactionsSnapshot = await getDocs(transactionsQuery);

        const newTransactions = transactionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Atualiza o último documento visível
        const lastVisibleDoc = transactionsSnapshot.docs[transactionsSnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        // Adiciona as novas transações à lista existente
        setTransactions(prevTransactions => [...prevTransactions, ...newTransactions]);
      }
    } catch (error) {
      console.error('Erro ao carregar mais transações: ', error);
      setError('Erro ao carregar mais transações');
    } finally {
      setLoadingMore(false);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Spin size="large" tip="Carregando transações..." />
      </div>
    );
  }

  if (error) {
    return <Alert message="Erro" description={error} type="error" showIcon />;
  }

  // Dividir transações em vendas e compras
  const vendas = transactions.filter(t => t.tipo === 'venda');
  const compras = transactions.filter(t => t.tipo === 'compra');

  return (
    <Layout style={{ padding: '16px', minHeight: '100vh', background: '#f0f2f5' }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/dashboard')}>
            Voltar
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Title level={3}>Vendas</Title>
          <Row gutter={[16, 16]}>
            {vendas.length > 0 ? (
              vendas.map(transaction => (
                <Col key={transaction.id} xs={24}>
                  <Card
                    style={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '12px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      padding: '16px',
                      minHeight: '100px',
                    }}
                  >
                    <Text strong>{transaction.marca} {transaction.modelo}</Text>
                    <br />
                    <Text style={{ color: 'green', fontWeight: 'bold' }}>
                      +R${(transaction.valor + (transaction.lucro || 0)).toLocaleString('pt-BR')}
                    </Text>
                    <br />
                    <Text>{moment(transaction.data.toDate()).format('DD/MM/YYYY')}</Text>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={24}>
                <Alert message="Nenhuma venda encontrada." type="info" showIcon />
              </Col>
            )}
          </Row>
        </Col>

        <Col xs={24} md={12}>
          <Title level={3}>Compras</Title>
          <Row gutter={[16, 16]}>
            {compras.length > 0 ? (
              compras.map(transaction => (
                <Col key={transaction.id} xs={24}>
                  <Card
                    style={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: '12px',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      padding: '16px',
                      minHeight: '100px',
                    }}
                  >
                    <Text strong>{transaction.marca} {transaction.modelo}</Text>
                    <br />
                    <Text style={{ color: 'red', fontWeight: 'bold' }}>
                      -R${Math.abs(transaction.valor).toLocaleString('pt-BR')}
                    </Text>
                    <br />
                    <Text>{moment(transaction.data.toDate()).format('DD/MM/YYYY')}</Text>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={24}>
                <Alert message="Nenhuma compra encontrada." type="info" showIcon />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {/* Botão para carregar mais transações */}
      {lastVisible && (
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Button
            type="primary"
            onClick={fetchMoreTransactions}
            loading={loadingMore}
            disabled={loadingMore}
            style={{ borderRadius: '4px' }}
          >
            {loadingMore ? 'Carregando...' : 'Carregar Mais'}
          </Button>
        </Row>
      )}
    </Layout>
  );
};

export default Extrato;
