// Shop.jsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Menu,
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Modal,
  Drawer,
  Grid,
  message,
  Tooltip,
  Card,
  Spin,
} from 'antd';
import {
  DollarOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  CrownOutlined,
  LogoutOutlined,
  GoldOutlined,
  MenuOutlined,
  RocketOutlined,
  CheckCircleOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import OffersSection from './OffersSection';
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  increment,
} from 'firebase/firestore';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto
import styled from 'styled-components';
import { motion } from 'framer-motion';
import OffersSection2 from './OffersSection2';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

/**
 * Styled Components
 */

// Layout principal estilizado
const StyledLayout = styled(Layout)`
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #f0f2f5;
`;

// Cabeçalho estilizado
const StyledHeader = styled(Header)`
  background: #080808;
  padding: 0 16px; /* Ajustado para 16px para coincidir com PlayerRankingScreen.jsx */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
`;

// Logo estilizado
const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    height: 40px; /* Ajustado para 40px para melhor alinhamento */
    margin-right: 10px;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

// Botão "Gold Mine" estilizado
const GoldButton = styled(Button)`
  background-color: #faad14;
  border-color: #faad14;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-left: 10px;

  &:hover,
  &:focus {
    background-color: #d48806;
    border-color: #d48806;
    color: #fff;
  }
`;

// Drawer estilizado (menu mobile)
const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 20px;
  }
`;

// Conteúdo principal estilizado
const StyledContent = styled(Content)`
  margin-top: 100px;
  padding: 50px;
  background: #f0f2f5;
  min-height: calc(100vh - 160px);
`;

// Rodapé estilizado
const StyledFooter = styled(Footer)`
  text-align: center;
  background: #080808;
  color: #fff;
  padding: 20px;
`;

// Card de Preços estilizado com animação e melhor espaçamento
const PricingCardStyled = styled(Card)`
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  }
`;

// Imagem de Mineração estilizada
const MiningImage = styled.img`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
`;

// Botão Primário estilizado
const PrimaryButton = styled(Button)`
  width: 100%;
  background-color: #faad14;
  border-color: #faad14;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-top: 20px;
  padding: 10px 0;
  font-size: 16px;

  &:hover,
  &:focus {
    background-color: #d48806;
    border-color: #d48806;
    color: #fff;
  }

  &:disabled {
    background-color: #d3d3d3;
    border-color: #d3d3d3;
    color: #fff;
  }
`;

// Botão de Coleta de Ouro estilizado
const CollectButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? '#d3d3d3' : '#52c41a')};
  border-color: ${(props) => (props.disabled ? '#d3d3d3' : '#52c41a')};
  color: #fff;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-top: 20px;
  padding: 10px 0;
  font-size: 16px;

  &:hover,
  &:focus {
    background-color: ${(props) => (props.disabled ? '#d3d3d3' : '#389e0d')};
    border-color: ${(props) => (props.disabled ? '#d3d3d3' : '#389e0d')};
    color: #fff;
  }
`;

// Botão de Upgrade estilizado
const UpgradeButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? '#d3d3d3' : '#faad14')};
  border-color: ${(props) => (props.disabled ? '#d3d3d3' : '#faad14')};
  color: #fff;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-top: 20px;
  padding: 10px 0;
  font-size: 16px;

  &:hover,
  &:focus {
    background-color: ${(props) => (props.disabled ? '#d3d3d3' : '#d48806')};
    border-color: ${(props) => (props.disabled ? '#d3d3d3' : '#d48806')};
    color: #fff;
  }
`;

/**
 * Componente Principal
 */
const Shop = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const auth = getAuth();

  // Estados
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isMiningCompanyOwned, setIsMiningCompanyOwned] = useState(false);
  const [miningCompanyLevel, setMiningCompanyLevel] = useState(1);
  const [miningCompanyGoldPerDay, setMiningCompanyGoldPerDay] = useState(0);
  const [miningCompanyLastCollected, setMiningCompanyLastCollected] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [hoveredMining, setHoveredMining] = useState(false);

  // URLs de Compra - atualize conforme necessário
  const purchaseUrls = {
    package1: 'https://pay.kirvano.com/9fe53f42-d1ad-4bb9-91b4-d3a7f802277c',
    package2: 'https://pay.kirvano.com/911f779e-1111-4f5b-a30b-81c05d8583bd',
    package3: 'https://pay.kirvano.com/c7f88b13-2072-4909-a9b0-905cb6a89663',
  };

  const logoUrl =
    'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb';

  // Const para definir ouro diário por nível de mineração
  const miningLevels = {
    1: 500,
    2: 1500,
    3: 3500,
    4: 5500,
    5: 7900,
    6: 10000,
    7: 12000,
    8: 14500,
    9: 17700,
    10: 20000,
    // Adicione mais níveis conforme necessário
  };

  // Função para mostrar o Modal de Ajuda
  const showHelpModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleHelpModalOk = () => {
    setIsHelpModalVisible(false);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalVisible(false);
  };

  // Funções para controlar o Drawer (menu mobile)
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  // Função para renderizar os itens do menu
  const renderMenuItems = () => (
    <>
     <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
      
    </>
  );

  /**
   * Função para buscar dados do jogador
   */
  const fetchPlayerData = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerDocRef = doc(firestore, 'players', user.uid);
        const playerDocSnap = await getDoc(playerDocRef);

        if (playerDocSnap.exists()) {
          const data = playerDocSnap.data();
          data.balance = typeof data.balance === 'number' ? data.balance : 0;
          data.gold = typeof data.gold === 'number' ? data.gold : 0;

          // Dados da Empresa de Mineração com base no campo 'miner'
          if (data.miner) {
            setIsMiningCompanyOwned(true);
            setMiningCompanyLevel(data.minerlevel || 1);
            setMiningCompanyGoldPerDay(data.miningCompany?.goldPerDay || miningLevels[data.minerlevel || 1]);
            setMiningCompanyLastCollected(
              data.miningCompany?.lastCollected
                ? data.miningCompany.lastCollected.toDate()
                : null
            );
          } else {
            setIsMiningCompanyOwned(false);
          }

          setPlayerData(data);
        } else {
          message.error('Documento do jogador não encontrado.');
        }
      } else {
        message.error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      message.error('Erro ao buscar dados do jogador.');
    }
  }, [auth, miningLevels]);

  useEffect(() => {
    fetchPlayerData();
  }, [fetchPlayerData]);

  /**
   * Dados dos Pacotes de Preços
   */
  const pricingPackages = [
    {
      name: 'Promoção de Ano Novo',
      price: '2,99',
      features: [
        '5.000 Ouro',
        '5.000.000 em Dinheiro',
      ],
      purchaseUrl: purchaseUrls.package1,
      icon: <GoldOutlined style={{ fontSize: '32px', color: '#FAAD14' }} />,
    },
    {
      name: 'Billion Pack',
      price: '14,90',
      features: [
        '60.000 Ouro',
        '20.000.000 em Dinheiro',
        'Mina de Ouro Nível 5 (+3.500 ouro/dia)',
      ],
      purchaseUrl: purchaseUrls.package2,
      icon: <GoldOutlined style={{ fontSize: '32px', color: '#FAAD14' }} />,
    },
    {
      name: 'Senna Pack',
      price: '19,30',
      features: [
        '150.000 Ouro',
        '40.000.000 em Dinheiro',
        'Mina de Ouro Nível 10 (+20.000 ouro/dia)',
        
      ],
      purchaseUrl: purchaseUrls.package3,
      icon: <GoldOutlined style={{ fontSize: '32px', color: '#FAAD14' }} />,
    },
  ];

  /**
   * Função para lidar com a compra de Pacotes de Ouro
   */
  const handleGoldBarPurchase = (url) => {
    window.open(url, '_blank', 'noopener noreferrer');
  };

  /**
   * Função para obter dados do nível de Mineração
   */
  const getMiningLevelData = (level) => {
    const baseCostMoney = 10000000; // R$10.000.000 para o nível 1
    const goldPerDay = miningLevels[level] || 500; // Ouro gerado por dia com base no nível

    const costMultiplier = 2.0; // Multiplicador de custo por nível

    return {
      costMoney: Math.floor(baseCostMoney * Math.pow(costMultiplier, level - 1)),
      goldPerDay: goldPerDay,
    };
  };

  /**
   * Função para verificar se a coleta de ouro está desabilitada
   */
  const isGoldCollectionDisabled = () => {
    if (!miningCompanyLastCollected) return false;
    const today = new Date();
    const lastCollectedDate = new Date(miningCompanyLastCollected);
    return (
      today.getFullYear() === lastCollectedDate.getFullYear() &&
      today.getMonth() === lastCollectedDate.getMonth() &&
      today.getDate() === lastCollectedDate.getDate()
    );
  };

  /**
   * Função para coletar ouro da Empresa de Mineração
   */
  const handleCollectGold = async () => {
    if (isGoldCollectionDisabled()) {
      message.info('Você já coletou ouro hoje.');
      return;
    }

    try {
      const uid = auth.currentUser.uid;
      const playerDocRef = doc(firestore, 'players', uid);

      await updateDoc(playerDocRef, {
        gold: increment(miningCompanyGoldPerDay),
        'miningCompany.lastCollected': serverTimestamp(),
      });

      setPlayerData((prevPlayerData) => ({
        ...prevPlayerData,
        gold: prevPlayerData.gold + miningCompanyGoldPerDay,
        miningCompany: {
          ...prevPlayerData.miningCompany,
          lastCollected: new Date(),
        },
      }));

      setMiningCompanyLastCollected(new Date());

      message.success(`Você coletou com sucesso ${miningCompanyGoldPerDay} ouro!`);
    } catch (error) {
      console.error('Erro ao coletar ouro:', error);
      message.error('Erro ao coletar ouro.');
    }
  };

  /**
   * Função para verificar se o jogador pode atualizar a Empresa de Mineração
   */
  const canUpgradeMining = () => {
    if (!playerData) return false;
    const nextLevel = miningCompanyLevel + 1;
    const { costMoney } = getMiningLevelData(nextLevel);
    return playerData.balance >= costMoney;
  };

  /**
   * Função para atualizar a Empresa de Mineração
   */
  const handleUpgradeMining = async () => {
    if (!playerData) return;

    const nextLevel = miningCompanyLevel + 1;
    const { costMoney, goldPerDay } = getMiningLevelData(nextLevel);

    if (playerData.balance < costMoney) {
      message.error('Saldo insuficiente para realizar a atualização.');
      return;
    }

    try {
      const uid = auth.currentUser.uid;
      const playerDocRef = doc(firestore, 'players', uid);

      await updateDoc(playerDocRef, {
        balance: increment(-costMoney),
        minerlevel: increment(1), // Atualiza o nível da mina
        'miningCompany.level': increment(1),
        'miningCompany.goldPerDay': goldPerDay,
        'miningCompany.updatedAt': serverTimestamp(),
      });

      setPlayerData((prevPlayerData) => ({
        ...prevPlayerData,
        balance: prevPlayerData.balance - costMoney,
        minerlevel: (prevPlayerData.minerlevel || 1) + 1, // Atualiza localmente
        miningCompany: {
          ...prevPlayerData.miningCompany,
          level: prevPlayerData.miningCompany.level + 1,
          goldPerDay: goldPerDay,
        },
      }));

      setMiningCompanyLevel(nextLevel);
      setMiningCompanyGoldPerDay(goldPerDay);

      message.success(`Mina de Ouro atualizada para o nível ${nextLevel}!`);
    } catch (error) {
      console.error('Erro ao atualizar a mina de ouro:', error);
      message.error('Erro ao atualizar a mina de ouro.');
    }
  };

  /**
   * Função para comprar a Empresa de Mineração
   */
  const handlePurchaseMining = async () => {
    try {
      const uid = auth.currentUser.uid;
      const playerDocRef = doc(firestore, 'players', uid);
      const playerDocSnap = await getDoc(playerDocRef);

      if (!playerDocSnap.exists()) {
        message.error('Jogador não encontrado!');
        return;
      }

      const currentPlayerData = playerDocSnap.data();

      const initialCostMoney = 1000000000; // R$500.000.000
      const initialGoldPerDay = miningLevels[1]; // Ouro gerado por dia no nível 1

      if (currentPlayerData.balance < initialCostMoney) {
        message.error('Saldo insuficiente para comprar a Mina de Ouro.');
        return;
      }

      // Se o campo 'miner' não existir, criar
      if (!currentPlayerData.hasOwnProperty('miner')) {
        await updateDoc(playerDocRef, {
          miner: true,
          minerlevel: 1, // Inicializa o nível da mina
          balance: increment(-initialCostMoney),
          miningCompany: {
            level: 1,
            goldPerDay: initialGoldPerDay,
            lastCollected: null,
            updatedAt: serverTimestamp(),
          },
        });
      } else {
        // Se 'miner' existir mas for falso
        if (!currentPlayerData.miner) {
          await updateDoc(playerDocRef, {
            miner: true,
            minerlevel: 1, // Inicializa o nível da mina
            balance: increment(-initialCostMoney),
            miningCompany: {
              level: 1,
              goldPerDay: initialGoldPerDay,
              lastCollected: null,
              updatedAt: serverTimestamp(),
            },
          });
        } else {
          message.error('Você já possui uma Mina de Ouro.');
          return;
        }
      }

      // Atualiza o estado local
      setPlayerData({
        ...currentPlayerData,
        balance: currentPlayerData.balance - initialCostMoney,
        miner: true,
        minerlevel: 1,
        miningCompany: {
          level: 1,
          goldPerDay: initialGoldPerDay,
          lastCollected: null,
        },
      });

      setIsMiningCompanyOwned(true);
      setMiningCompanyLevel(1);
      setMiningCompanyGoldPerDay(initialGoldPerDay);
      setMiningCompanyLastCollected(null);

      message.success('Empresa de Mineração comprada com sucesso usando Dinheiro Virtual!');
    } catch (error) {
      console.error('Erro ao comprar a Empresa de Mineração:', error);
      message.error('Erro ao comprar a Empresa de Mineração.');
    }
  };

  return (
    <StyledLayout>
      {/* Cabeçalho */}
      <StyledHeader>
        {/* Logo */}
        <Logo onClick={() => navigate('/')}>
          <img src={logoUrl} alt="Logo" />
          
        </Logo>

        {/* Menu de Navegação */}
        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ lineHeight: '64px', background: '#080808' }}>
            {renderMenuItems()}
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
          />
        )}
      </StyledHeader>

      {/* Drawer para Navegação Mobile */}
      <StyledDrawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
      >
        <Menu mode="vertical" selectable={false}>
          {renderMenuItems()}
        </Menu>
      </StyledDrawer>

      {/* Conteúdo Principal */}
      <Layout>
        <StyledContent>
          {/* Seção da Empresa de Mineração */}
          <div style={{ marginBottom: '60px' }}>
            <Title
              level={2}
              style={{
                textAlign: 'center',
                color: '#333',
                marginBottom: '40px',
                fontWeight: '700',
              }}
            >
              <span style={{ color: '#FAAD14' }}>Empresa de Mineração</span>
            </Title>

            {isMiningCompanyOwned ? (
              // Empresa de Mineração Possuída
              <Card
                style={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  background: '#fff',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                }}
                hoverable
              >
                <Row gutter={[32, 32]}>
                  <Col xs={24} md={12}>
                    <MiningImage
                      src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Media%2FDALL%C2%B7E%202024-10-31%2012.50.00%20-%20A%20highly%20detailed%203D%20isometric%20diorama%20of%20an%20advanced%20gold%20mining%20facility%20with%20a%20strong%20industrial%20aesthetic.%20The%20scene%20includes%20a%20large%20amount%20of%20vi.webp?alt=media&token=13cf7864-7a59-4054-a3ee-7716b90d6276"
                      alt="Mineração"
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Title level={4} style={{ color: '#333' }}>
                      Nível da Mina: {miningCompanyLevel}
                    </Title>
                    <Text style={{ fontSize: '16px', color: '#555' }}>
                      Ouro gerado por dia:{' '}
                      <strong style={{ color: '#FAAD14' }}>
                        {miningCompanyGoldPerDay}
                      </strong>
                    </Text>
                    <br />
                    <Text style={{ fontSize: '16px', color: '#555' }}>
                      Última coleta:{' '}
                      <strong style={{ color: '#FAAD14' }}>
                        {miningCompanyLastCollected
                          ? miningCompanyLastCollected.toLocaleDateString('pt-BR')
                          : 'Nunca'}
                      </strong>
                    </Text>
                    <br />
                    <CollectButton
                      type="primary"
                      disabled={isGoldCollectionDisabled()}
                      onClick={handleCollectGold}
                      icon={
                        isGoldCollectionDisabled() ? (
                          <CloseCircleOutlined />
                        ) : (
                          <CheckCircleOutlined />
                        )
                      }
                    >
                      {isGoldCollectionDisabled()
                        ? 'Já coletou hoje'
                        : 'Coletar Ouro'}
                    </CollectButton>
                    <Divider />

                    <Title level={4} style={{ color: '#333' }}>
                      Atualizações
                    </Title>
                    <Tooltip
                      title={
                        canUpgradeMining()
                          ? 'Clique para atualizar'
                          : 'Saldo insuficiente para atualizar'
                      }
                    >
                      <UpgradeButton
                        type="primary"
                        disabled={!canUpgradeMining()}
                        onClick={handleUpgradeMining}
                        icon={
                          canUpgradeMining() ? (
                            <RocketOutlined />
                          ) : (
                            <CloseCircleOutlined />
                          )
                        }
                      >
                        {canUpgradeMining()
                          ? `Atualizar para o Nível ${miningCompanyLevel + 1} - R$${getMiningLevelData(
                              miningCompanyLevel + 1
                            ).costMoney.toLocaleString('pt-BR')}`
                          : 'Atualização Indisponível'}
                      </UpgradeButton>
                    </Tooltip>
                  </Col>
                </Row>
              </Card>
            ) : (
              // Empresa de Mineração Não Possuída
              <Row justify="center">
                <Col xs={24} sm={20} md={16} lg={12}>
                  <Card
                    style={{
                      borderRadius: '16px',
                      overflow: 'hidden',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                      background: '#fff',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    }}
                    hoverable
                  >
                    <Row gutter={[32, 32]} align="middle">
                      <Col xs={24} md={12}>
                        <MiningImage
                          src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Media%2FDALL%C2%B7E%202024-10-31%2012.50.00%20-%20A%20highly%20detailed%203D%20isometric%20diorama%20of%20an%20advanced%20gold%20mining%20facility%20with%20a%20strong%20industrial%20aesthetic.%20The%20scene%20includes%20a%20large%20amount%20of%20vi.webp?alt=media&token=13cf7864-7a59-4054-a3ee-7716b90d6276"
                          alt="Compra de Mineração"
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <Text
                          style={{
                            fontSize: '18px',
                            color: '#555',
                            marginBottom: '20px',
                            display: 'block',
                          }}
                        >
                          Desbloqueie para gerar ouro gratuito todos os dias!
                        </Text>
                        <Title
                          level={3}
                          style={{ color: '#333', marginBottom: '20px' }}
                        >
                          R$1 bilhão
                        </Title>
                        <GoldButton
                          type="primary"
                          icon={<DollarOutlined />}
                          onClick={handlePurchaseMining}
                        >
                          Comprar Empresa
                        </GoldButton>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </div>

          {/* Seção de Preços */}
          <div style={{ marginBottom: '60px' }}>
            <Title
              level={2}
              style={{
                textAlign: 'center',
                color: '#333',
                marginBottom: '40px',
                fontWeight: '700',
              }}
            >
              Impulsione Sua Loja
            </Title>
            <Row gutter={[32, 32]} justify="center">
              {pricingPackages.map((pkg, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  <PricingCardStyled
                    as={motion.div}
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2, duration: 0.5 }}
                  >
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                      {pkg.icon}
                      <Title level={3} style={{ marginTop: '10px', fontWeight: '600' }}>
                        {pkg.name}
                      </Title>
                      <Text style={{ fontSize: '24px', color: '#333', fontWeight: '500' }}>
                        R$ {pkg.price}
                      </Text>
                    </div>
                    <Divider />
                    <ul style={{ listStyleType: 'none', padding: 0, marginBottom: '20px' }}>
                      {pkg.features.map((feature, idx) => (
                        <li
                          key={idx}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                            color: '#555',
                            fontSize: '16px',
                          }}
                        >
                          <CheckCircleOutlined
                            style={{ color: '#52c41a', marginRight: '8px' }}
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <PrimaryButton
                      type="primary"
                      onClick={() => handleGoldBarPurchase(pkg.purchaseUrl)}
                    >
                      Comprar
                    </PrimaryButton>
                  </PricingCardStyled>
                </Col>
              ))}
            </Row>
          </div>
        </StyledContent>
        <OffersSection/>
        <OffersSection2/>

        {/* Rodapé */}
        <StyledFooter>
          <Row justify="space-between" align="middle">
            <Col>
              <Text>© 2024 Luxury Gears. Todos os direitos reservados.</Text>
            </Col>
            <Col>
              <a
                href="https://instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#fff', marginRight: '15px' }}
              >
                <InstagramOutlined style={{ fontSize: '24px' }} />
              </a>
              <a
                href="https://wa.me/5511912192520"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#fff' }}
              >
                <WhatsAppOutlined style={{ fontSize: '24px' }} />
              </a>
            </Col>
          </Row>
        </StyledFooter>
      </Layout>

      {/* Modal de Ajuda */}
      <Modal
        title="Ajuda"
        visible={isHelpModalVisible}
        onOk={handleHelpModalOk}
        onCancel={handleHelpModalCancel}
        okText="OK"
        cancelText="Cancelar"
        centered
      >
        <p>Aqui você pode encontrar informações úteis sobre como usar a loja.</p>
        <ul>
          <li>Como comprar pacotes de ouro.</li>
          <li>Informações sobre a Empresa de Mineração.</li>
          <li>Como contatar o suporte.</li>
        </ul>
      </Modal>

      {/* Estilos Inline para Animações e Responsividade */}
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
            }
            70% {
              transform: scale(1.05);
              box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
            }
            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
            }
          }

          /* Destaque para o botão de resgate */
          .redeem-button:hover {
            background-color: #218838 !important;
          }

          .purchase-button:hover {
            background-color: #218838 !important;
          }

          /* Personalizando indicadores do carrossel para preto */
          .ant-carousel .slick-dots li button {
            background-color: black; /* Cor dos indicadores inativos */
          }

          .ant-carousel .slick-dots li.slick-active button {
            background-color: black; /* Cor dos indicadores ativos */
          }

          @media (min-width: 768px) {
            .content-wrapper {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              padding: 40px;
              gap: 40px;
              flex-wrap: wrap;
              flex-direction: row;
            }

            .cards-row {
              display: flex;
              justify-content: center;
              gap: 40px;
              flex-wrap: wrap;
            }

            .logo {
              max-width: 200px;
              width: 100%;
              margin-bottom: 20px;
            }
          }

          @media (max-width: 767px) {
            .content-wrapper {
              padding: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              gap: 20px;
              height: auto;
            }

            .cards-row {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }

            .logo {
              max-width: 150px;
              width: 100%;
              margin-bottom: 20px;
            }
          }

          /* Estilo para a linha destacada */
          .highlight-row {
            background-color: #e6f7ff !important;
          }

          /* Estilo de destaque para domingo */
          .sunday-highlight {
            margin-top: 16px;
            padding: 12px;
            background-color: #fffbe6;
            border: 1px solid #ffe58f;
            border-radius: 4px;
            animation: highlight 2s infinite;
          }

          @keyframes highlight {
            0% { background-color: #fffbe6; }
            50% { background-color: #fff1b8; }
            100% { background-color: #fffbe6; }
          }

          /* Estilo especial para a tabela no domingo */
          .sunday-table {
            border: 2px solid #faad14;
            animation: tablePulse 3s infinite;
          }

          @keyframes tablePulse {
            0% { box-shadow: 0 0 10px #faad14; }
            50% { box-shadow: 0 0 20px #faad14; }
            100% { box-shadow: 0 0 10px #faad14; }
          }

          /* Estilos para o Card de Nova Recompensa */
          .reward-card {
            /* Se você quiser adicionar animações ou estilos específicos, faça aqui */
          }
        `}
      </style>
    </StyledLayout>
  );
};

export default Shop;
