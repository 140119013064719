// GlobalAchievementsScreen.jsx
import React, { useEffect, useState } from 'react';
import {
  Card,
  List,
  Typography,
  message,
  Avatar,
  Spin,
  Progress,
  Button,
  Tooltip,
} from 'antd';
import {
  TrophyOutlined,
  DollarOutlined,
  CarOutlined,
  ShoppingCartOutlined,
  SmileOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';

const { Title, Text } = Typography;

// Definição das conquistas globais
const globalAchievements = [
  {
    id: 1,
    title: 'Primeira Venda',
    description: 'Realize sua primeira venda no jogo.',
    metrics: { totalSales: 1 },
    reward: { type: 'money', amount: 100 },
    icon: <ShoppingCartOutlined style={{ fontSize: '24px', color: '#1890ff' }} />,
  },
  {
    id: 2,
    title: 'Lucro Inicial',
    description: 'Alcance um lucro total de R$ 10.000.',
    metrics: { totalProfit: 10000 },
    reward: { type: 'money', amount: 500 },
    icon: <DollarOutlined style={{ fontSize: '24px', color: '#faad14' }} />,
  },
  {
    id: 3,
    title: 'Cliente Fiel',
    description: 'Adquira 100 clientes.',
    metrics: { totalCustomers: 100 },
    reward: { type: 'car', amount: 1 },
    icon: <CarOutlined style={{ fontSize: '24px', color: '#52c41a' }} />,
  },
  {
    id: 4,
    title: 'Satisfação Máxima',
    description: 'Mantenha 95% de satisfação dos clientes.',
    metrics: { customerSatisfaction: 95 },
    reward: { type: 'money', amount: 1000 },
    icon: <SmileOutlined style={{ fontSize: '24px', color: '#722ed1' }} />,
  },
  {
    id: 5,
    title: 'Vendas Express',
    description: 'Realize 50 vendas em uma semana.',
    metrics: { weeklySales: 50 },
    reward: { type: 'car', amount: 2 },
    icon: <ShoppingCartOutlined style={{ fontSize: '24px', color: '#1890ff' }} />,
  },
  {
    id: 6,
    title: 'Top Performer',
    description: 'Seja um dos 10 melhores jogadores do ranking.',
    metrics: { rankingPosition: 10 },
    reward: { type: 'money', amount: 2000 },
    icon: <StarOutlined style={{ fontSize: '24px', color: '#f5222d' }} />,
  },
  // Adicione mais conquistas conforme necessário
];

const GlobalAchievementsScreen = () => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [achievements, setAchievements] = useState([]);
  const auth = getAuth();

  useEffect(() => {
    const fetchPlayerData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const playerDoc = await getDoc(doc(firestore, 'players', user.uid));
          if (playerDoc.exists()) {
            setPlayerData(playerDoc.data());
            setLoading(false);
          } else {
            message.error('Não foi possível carregar os dados do jogador.');
            setLoading(false);
          }
        } catch (error) {
          message.error('Erro ao buscar dados do jogador.');
          console.error('Erro ao buscar dados do jogador:', error);
          setLoading(false);
        }
      } else {
        message.error('Usuário não autenticado.');
        setLoading(false);
      }
    };
    fetchPlayerData();
  }, [auth]);

  useEffect(() => {
    if (playerData) {
      const updatedAchievements = globalAchievements.map((achievement) => {
        let progress = 0;
        const metrics = achievement.metrics;
        const keys = Object.keys(metrics);
        keys.forEach((key) => {
          if (playerData.metrics && playerData.metrics[key] !== undefined) {
            if (key === 'rankingPosition') {
              // Para ranking, uma posição menor é melhor
              progress = playerData.metrics.rankingPosition <= metrics[key] ? 100 : 0;
            } else {
              const current = playerData.metrics[key];
              const required = metrics[key];
              progress = Math.max(progress, Math.min(100, (current / required) * 100));
            }
          }
        });
        const completed = progress === 100;
        const rewardClaimed =
          playerData.achievements &&
          playerData.achievements[achievement.id] &&
          playerData.achievements[achievement.id].rewardClaimed;
        return { ...achievement, progress, completed, rewardClaimed };
      });
      setAchievements(updatedAchievements);
    }
  }, [playerData]);

  // Função para reivindicar a recompensa de uma conquista
  const claimReward = async (achievement) => {
    if (achievement.completed && !achievement.rewardClaimed) {
      try {
        // Lógica para adicionar a recompensa ao jogador
        if (achievement.reward.type === 'money') {
          const playerRef = doc(firestore, 'players', auth.currentUser.uid);
          await setDoc(
            playerRef,
            {
              balance: (playerData.balance || 0) + achievement.reward.amount,
              [`achievements.${achievement.id}.rewardClaimed`]: true,
            },
            { merge: true }
          );
          message.success(
            `Recompensa de R$ ${achievement.reward.amount.toLocaleString(
              'pt-BR'
            )} reivindicada com sucesso!`
          );
        } else if (achievement.reward.type === 'car') {
          const playerRef = doc(firestore, 'players', auth.currentUser.uid);
          await setDoc(
            playerRef,
            {
              cars: [...(playerData.cars || []), `Carro ${achievement.reward.amount}`],
              [`achievements.${achievement.id}.rewardClaimed`]: true,
            },
            { merge: true }
          );
          message.success(
            `${achievement.reward.amount} Carro(s) adicionado(s) ao seu inventário!`
          );
        }

        // Atualiza o estado local para refletir a recompensa reivindicada
        setAchievements((prevAchievements) =>
          prevAchievements.map((ach) =>
            ach.id === achievement.id ? { ...ach, rewardClaimed: true } : ach
          )
        );

        // Atualiza o estado do jogador
        setPlayerData((prevData) => ({
          ...prevData,
          balance:
            achievement.reward.type === 'money'
              ? (prevData.balance || 0) + achievement.reward.amount
              : prevData.balance,
          cars:
            achievement.reward.type === 'car'
              ? [...(prevData.cars || []), `Carro ${achievement.reward.amount}`]
              : prevData.cars,
          achievements: {
            ...prevData.achievements,
            [achievement.id]: { rewardClaimed: true },
          },
        }));
      } catch (error) {
        message.error('Erro ao reivindicar a recompensa.');
        console.error('Erro ao reivindicar a recompensa:', error);
      }
    }
  };

  return (
    <div
      style={{
        padding: '24px',
        backgroundColor: '#f0f2f5',
        minHeight: '100vh',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <Card
        title={<Title level={3}>Conquistas Globais</Title>}
        style={{
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff',
        }}
      >
        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <Spin size="large" tip="Carregando Conquistas..." />
          </div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={achievements}
            renderItem={(achievement) => (
              <List.Item
                style={{
                  backgroundColor: achievement.completed ? '#f6ffed' : '#ffffff',
                  borderRadius: '8px',
                  padding: '16px',
                  marginBottom: '16px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Tooltip title={achievement.title}>
                      <Avatar
                        size={64}
                        icon={achievement.icon}
                        style={{
                          backgroundColor: '#fafafa',
                          border: '1px solid #f0f0f0',
                        }}
                      />
                    </Tooltip>
                  }
                  title={
                    <Title level={5} style={{ marginBottom: 0 }}>
                      {achievement.title}
                    </Title>
                  }
                  description={<Text type="secondary">{achievement.description}</Text>}
                />
                <div style={{ flex: 1, marginLeft: '24px' }}>
                  <Progress
                    percent={achievement.progress}
                    status={achievement.completed ? 'success' : 'active'}
                    strokeColor={
                      achievement.completed
                        ? '#52c41a'
                        : achievement.progress > 50
                        ? '#faad14'
                        : '#1890ff'
                    }
                    style={{ marginBottom: '8px' }}
                  />
                  <div style={{ marginBottom: '8px' }}>
                    <Text strong>Recompensa:</Text>{' '}
                    {achievement.reward.type === 'money' ? (
                      <span>
                        <DollarOutlined style={{ color: '#52c41a', marginRight: '4px' }} />
                        R$ {achievement.reward.amount.toLocaleString('pt-BR')}
                      </span>
                    ) : (
                      <span>
                        <CarOutlined style={{ color: '#1890ff', marginRight: '4px' }} />
                        {achievement.reward.amount} Carro(s)
                      </span>
                    )}
                  </div>
                  {achievement.completed && !achievement.rewardClaimed && (
                    <Button
                      type="primary"
                      icon={<TrophyOutlined />}
                      onClick={() => claimReward(achievement)}
                      block
                    >
                      Reivindicar Recompensa
                    </Button>
                  )}
                  {achievement.completed && achievement.rewardClaimed && (
                    <Button type="default" disabled block>
                      Recompensa Reivindicada
                    </Button>
                  )}
                </div>
              </List.Item>
            )}
          />
        )}
      </Card>
    </div>
  );
};

export default GlobalAchievementsScreen;
