import React from 'react';
import { Modal, Button, Carousel, Typography, Spin } from 'antd';

const { Title, Text } = Typography;

const CarDetailModal = ({
  visible,
  onCancel,
  car,
  loading,
  currentIndex,
  setCurrentIndex,
  confirmPurchase,
}) => {
  if (!car) return null;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
      bodyStyle={{ padding: '24px' }}
      centered
    >
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" tip="Carregando detalhes do carro..." />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <Carousel afterChange={setCurrentIndex}>
              {car.imagens?.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt="Car"
                    style={{
                      width: '100%',
                      height: '310px',
                      borderRadius: '5px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              ))}
            </Carousel>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '8px 0' }}>
              {car.imagens?.map((_, index) => (
                <div
                  key={index}
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: index === currentIndex ? '#1550f0' : '#e0e0e0',
                    margin: '0 4px',
                  }}
                />
              ))}
            </div>
          </div>
          <div style={{ flex: 1, paddingLeft: '24px' }}>
            <Title level={2}>R${car.valor.toLocaleString('pt-BR')}</Title>
            <Title level={3}>{car.marca}</Title>
            <Text>{car.modelo}</Text>
            <div style={{ margin: '16px 0' }}>
              <Text>Ano: {car.ano}</Text><br />
              <Text>Km: {car.km}</Text><br />
              <Text>Potência: {car.potencia}</Text><br />
              <Text>Tipo: {car.tipo}</Text>
            </div>
            <Button type="primary" block onClick={confirmPurchase}>
              Comprar
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CarDetailModal;
