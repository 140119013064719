// BonusCard.jsx
import React from 'react';
import { Card, Button, message,Typography } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto
import { getAuth } from 'firebase/auth';

const { Title, Text } = Typography;

// Styled Components para personalização
const StyledCard = styled(Card)`
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  }
`;

const StyledButton = styled(Button)`
  background-color: #faad14;
  border-color: #faad14;
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: #d48806;
    border-color: #d48806;
    color: #fff;
  }

  &:disabled {
    background-color: #d3d3d3;
    border-color: #d3d3d3;
    color: #fff;
  }

  .anticon {
    margin-right: 8px;
  }
`;

const BonusCard = () => {
  const auth = getAuth();

  const handleActivateBonus = async () => {
    const user = auth.currentUser;
    if (!user) {
      message.error('Usuário não autenticado.');
      return;
    }

    const playerDocRef = doc(firestore, 'players', user.uid);

    try {
      await updateDoc(playerDocRef, {
        miner: true,
        minerlevel: 3,
      });

      message.success('Bônus ativado! Mina de mineração atualizada para nível 3.');
      
      // Opcional: Atualizar o estado local se estiver sendo gerenciado no componente pai
      // Por exemplo, você pode usar um callback ou contexto para atualizar o estado
    } catch (error) {
      console.error('Erro ao ativar bônus:', error);
      message.error('Erro ao ativar bônus.');
    }
  };

  return (
    <StyledCard>
      <Title level={3} style={{ color: '#333' }}>Bônus Especial</Title>
      <Text style={{ fontSize: '16px', color: '#555' }}>
        Ative este bônus para desbloquear a Mina de Mineração e alcançar o nível 3!
      </Text>
      <StyledButton
        type="primary"
        onClick={handleActivateBonus}
        icon={<UnlockOutlined />}
      >
        Ativar Bônus
      </StyledButton>
    </StyledCard>
  );
};

export default BonusCard;
