// src/components/VipMarket.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Carousel,
  message,
  Menu,
  Select,
  Spin,
  Tooltip,
  Form,
  Drawer,
  Grid,
  Card,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  DollarOutlined,
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  CrownOutlined,
  RocketOutlined,
  GoldOutlined,
  MenuOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import FloatingClientCard from './FloatingClientCard';
import LuxuryCarDetail from './LuxuryCarDetail'; // Componente para detalhes luxuosos

import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  limit,
  startAfter,
  runTransaction,
} from 'firebase/firestore';

const { Content, Header } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

const VipMarket = () => {
  // Estados principais
  const [cars, setCars] = useState([]);
  const [isBuying, setIsBuying] = useState(false); // Controle de compras

  const [stores, setStores] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [filters, setFilters] = useState({
    marca: '',
    tipo: '',
    potenciaMax: '',
    kmMax: '',
    anoMax: '',
  });
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [playerSlots, setPlayerSlots] = useState(4); // Número de slots do jogador
  const [gold, setGold] = useState(0); // Saldo de ouro do jogador

  const auth = getAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint(); // Hook para detectar o tamanho da tela

  // Refs para controlar lastVisible e evitar loops de renderização
  const lastVisibleRef = useRef(null);
  const isMounted = useRef(true);
  const debounceTimer = useRef(null);
  const loadingRef = useRef(false);

  // Função para atualizar loading e loadingRef
  const setLoadingState = useCallback((value) => {
    setLoading(value);
    loadingRef.current = value;
  }, []);

  // Função fetchCars sem dependências
  const fetchCars = useCallback(
    async (currentFilters, isNewQuery = false) => {
      if (loadingRef.current) return;
      setLoadingState(true);
      try {
        let carsQuery = collection(firestore, 'vipcars'); // Coleção VIP
        let constraints = [];

        // Aplicar filtros usando 'currentFilters'
        if (currentFilters.marca) {
          constraints.push(where('marca', '==', currentFilters.marca));
        }
        if (currentFilters.tipo) {
          constraints.push(where('tipo', '==', currentFilters.tipo));
        }

        // Aplicar filtros de desigualdade
        if (currentFilters.potenciaMax) {
          const potenciaMax = parseInt(currentFilters.potenciaMax, 10);
          if (!isNaN(potenciaMax)) {
            constraints.push(where('potencia', '<=', potenciaMax));
          }
        }
        if (currentFilters.kmMax) {
          const kmMax = parseInt(currentFilters.kmMax, 10);
          if (!isNaN(kmMax)) {
            constraints.push(where('km', '<=', kmMax));
          }
        }
        if (currentFilters.anoMax) {
          const anoMax = parseInt(currentFilters.anoMax, 10);
          if (!isNaN(anoMax)) {
            constraints.push(where('ano', '<=', anoMax));
          }
        }

        constraints.push(limit(12));

        if (!isNewQuery && lastVisibleRef.current) {
          constraints.push(startAfter(lastVisibleRef.current));
        } else if (isNewQuery) {
          lastVisibleRef.current = null;
        }

        const q = query(carsQuery, ...constraints);
        const carsSnapshot = await getDocs(q);

        const carsList = carsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // Inclui todos os campos, incluindo 'imagens' e 'units'
        }));

        const lastVisibleDoc = carsSnapshot.docs[carsSnapshot.docs.length - 1];
        lastVisibleRef.current = lastVisibleDoc || null;

        setHasMore(carsSnapshot.docs.length === 12);

        setCars((prevCars) => (isNewQuery ? carsList : [...prevCars, ...carsList]));
      } catch (error) {
        console.error('Erro ao buscar carros VIP:', error);
        message.error('Erro ao buscar carros VIP.');
      } finally {
        if (isMounted.current) setLoadingState(false);
      }
    },
    [setLoadingState]
  );

  // Efeito para buscar dados iniciais
  useEffect(() => {
    isMounted.current = true;

    const fetchInitialData = async () => {
      setLoadingState(true);
      try {
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado.');
          navigate('/auth');
          return;
        }

        const playerDocRef = doc(firestore, 'players', user.uid);
        const storesCollectionRef = collection(firestore, 'stores');

        const [storesSnapshot, playerDoc] = await Promise.all([
          getDocs(storesCollectionRef),
          getDoc(playerDocRef),
        ]);

        if (storesSnapshot.empty) {
         
          setStores([]);
        } else {
          const storesList = storesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStores(storesList);
        }

        if (playerDoc.exists()) {
          const playerData = playerDoc.data();
          setPlayerSlots(playerData.slots || 4);
          setGold(playerData.gold || 0); // Usar 'gold' consistentemente
        } else {
          message.error('Dados do jogador não encontrados.');
        }

        // Chamar fetchCars com os filtros atuais
        await fetchCars(filters, true);
      } catch (error) {
        console.error('Erro ao buscar dados iniciais:', error);
        message.error('Erro ao buscar dados iniciais.');
      } finally {
        if (isMounted.current) setLoadingState(false);
      }
    };

    fetchInitialData();

    return () => {
      isMounted.current = false;
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [auth, navigate, setLoadingState, fetchCars, filters]);

  // Efeito para buscar carros quando os filtros mudam com debounce
  useEffect(() => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current);

    debounceTimer.current = setTimeout(() => {
      setCars([]);
      lastVisibleRef.current = null;
      setHasMore(true);
      fetchCars(filters, true);
    }, 300); // 300ms de debounce

    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [filters, fetchCars]);

  // Função para carregar mais carros (paginação)
  const fetchMoreCars = useCallback(() => {
    if (!hasMore || loading) return;
    fetchCars(filters, false);
  }, [hasMore, loading, filters, fetchCars]);

  // Função para atualizar os filtros
  const handleFilterChange = useCallback((field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  }, []);

  // Função para resetar os filtros
  const resetFilters = useCallback(() => {
    setFilters({
      marca: '',
      tipo: '',
      potenciaMax: '',
      kmMax: '',
      anoMax: '',
    });
  }, []);

  // Função para abrir o modal ao clicar em um carro
  const handleCarClick = useCallback(
    async (car) => {
      if (car.units <= 0) {
        message.warning('Este carro VIP não está disponível para compra.');
        return;
      }

      setLoadingState(true);
      try {
        // Carregamento Sob Demanda: busca os dados completos do carro ao clicar
        const carRef = doc(firestore, 'vipcars', car.id);
        const carSnap = await getDoc(carRef);
        if (carSnap.exists()) {
          const carData = carSnap.data();
          setSelectedCar({ id: carSnap.id, ...carData });
          setModalVisible(true);
        } else {
          message.error('Detalhes do carro VIP não encontrados.');
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do carro VIP:', error);
        message.error('Erro ao buscar detalhes do carro VIP.');
      } finally {
        setLoadingState(false);
      }
    },
    [setLoadingState]
  );

  // Função para fechar o modal
  const closeModal = useCallback(() => {
    setModalVisible(false);
    setSelectedCar(null);
  }, []);

  // Função para comprar um carro VIP
  const handleBuyCar = useCallback(
    async (car) => {
      if (isBuying) return; // Impede múltiplas compras simultâneas
      try {
        // Verifica se há unidades disponíveis
        if (car.units <= 0) {
          message.error('Este carro VIP não está mais disponível para compra.');
          closeModal();
          return;
        }

        setIsBuying(true); // Inicia o processo de compra
        console.log(`Usuário ${auth.currentUser.uid} iniciando compra do carro VIP: ${car.id}`);

        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado');
          return;
        }

        const playerId = user.uid;
        const playerRef = doc(firestore, 'players', playerId);
        const showroomCollectionRef = collection(firestore, 'players', playerId, 'showroom');

        const playerSnap = await getDoc(playerRef);
        if (!playerSnap.exists()) {
          message.error('Dados do jogador não encontrados.');
          return;
        }

        const playerData = playerSnap.data();
        const currentSlots = playerData.slots || 4;

        // Buscar o número de carros atualmente no showroom
        const showroomCarsSnap = await getDocs(showroomCollectionRef);
        const showroomCarsCount = showroomCarsSnap.size;

        if (showroomCarsCount >= currentSlots) {
          message.error('Você não tem vagas disponíveis no showroom para comprar esse carro VIP.');
          return;
        }

        // Verificar se o jogador tem saldo suficiente em 'gold'
        if (playerData.gold < car.goldvalue) { // Usar 'gold' consistentemente
          message.error('Saldo de ouro insuficiente para comprar este carro VIP.');
          return;
        }

        console.log(`Iniciando transação para comprar o carro VIP: ${car.id}`);

        // Iniciar uma transação para garantir a consistência dos dados
        await runTransaction(firestore, async (transaction) => {
          const carRef = doc(firestore, 'vipcars', car.id);
          const freshCarSnap = await transaction.get(carRef);

          if (!freshCarSnap.exists()) {
            throw new Error('Carro VIP não encontrado.');
          }

          const freshCarData = freshCarSnap.data();
          console.log(`Unidades disponíveis antes da compra: ${freshCarData.units}`);

          if (freshCarData.units <= 0) {
            throw new Error('Este carro VIP não está mais disponível para compra.');
          }

          // Atualizar as unidades disponíveis
          transaction.update(carRef, {
            units: freshCarData.units - 1,
          });
          console.log(`Unidades disponíveis após a compra: ${freshCarData.units - 1}`);

          // Adicionar o carro ao showroom do jogador
          const newCarRef = doc(showroomCollectionRef);
          transaction.set(newCarRef, {
            ...car,
            carId: newCarRef.id,
          });
          console.log(`Carro VIP adicionado ao showroom: ${newCarRef.id}`);

          // Deduzir o saldo de ouro do jogador
          transaction.update(playerRef, {
            gold: playerData.gold - car.goldvalue, // Atualizar 'gold'
            'metrics.totalPurchases': (playerData.metrics?.totalPurchases || 0) + 1,
            'metrics.totalSpent': (playerData.metrics?.totalSpent || 0) + car.goldvalue,
            'metrics.carrosComprados': (playerData.metrics?.carrosComprados || 0) + 1,
          });
          console.log(`Saldo de ouro atualizado: ${playerData.gold - car.goldvalue}`);

          // Adicionar uma transação
          const transactionRef = doc(collection(playerRef, 'transactions'));
          transaction.set(transactionRef, {
            carId: car.id,
            marca: car.marca,
            modelo: car.modelo,
            valor: car.goldvalue,
            data: new Date(),
            tipo: 'compra_vip',
          });
          console.log(`Transação registrada: ${transactionRef.id}`);
        });

        message.success('Carro VIP comprado com sucesso!');
        closeModal();

        // Atualizar o saldo local de ouro
        setGold((prevGold) => prevGold - car.goldvalue);

        // Atualizar o estado de unidades
        setCars((prevCars) =>
          prevCars.map((c) =>
            c.id === car.id ? { ...c, units: c.units - 1 } : c
          )
        );
      } catch (error) {
        console.error('Erro ao comprar carro VIP:', error);
        message.error('Erro ao comprar carro VIP: ' + error.message);
      } finally {
        setIsBuying(false); // Finaliza o processo de compra
      }
    },
    [auth, closeModal, isBuying]
  );

  // Estado e funções para o Drawer (menu mobile)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const showDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerVisible(false);
  }, []);

  // Função para renderizar os itens do menu
  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />} title="Dashboard">
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />} title="Showroom">
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />} title="Mercado">
        <Link to="/market">Mercado</Link>
      </Menu.Item>

      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />} title="Coleção Pessoal">
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />} title="Ranking">
        <Link to="/ranking">Rank</Link>
      </Menu.Item>

      <Menu.Item key="7" icon={<InstagramOutlined style={{ fontSize: '18px' }} />} title="Instagram">
        <a href="https://www.instagram.com/luxurygears.app" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
      </Menu.Item>
      <Menu.Item key="8" icon={<WhatsAppOutlined style={{ fontSize: '18px' }} />} title="Suporte">
        <a href="https://api.whatsapp.com/send?phone=5511912192520" target="_blank" rel="noopener noreferrer">
          Suporte
        </a>
      </Menu.Item>
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}> {/* Prevenção de overflow horizontal */}
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
        }}
      >
        {/* Seção da Esquerda: Botão "Voltar" e Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Botão "Voltar" */}
          <Button
            type="text"
            icon={<ArrowLeftOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={() => navigate('/market')}
            style={{ marginRight: '16px' }}
            title="Voltar"
          />

          {/* Logo */}
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px', maxWidth: '100%' }}
            />
            <Button
              type="text"
              icon={<RocketOutlined style={{ fontSize: '24px', color: '#ffcf40' }} />}
              onClick={() => navigate('/shop')} // Navega para a tela loja
              title="Upgrade"
              style={{ color: '#fff', marginLeft: '16px' }} // Estilo para o texto
            >
              Upgrade
            </Button>
          </div>
        </div>

        {/* Menu de Navegação */}
        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px', marginTop: '20px' }}>
            {renderMenuItems()}
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
            className="drawer-toggle-button"
          />
        )}
      </Header>

      {/* Drawer para navegação mobile */}
      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">
          {renderMenuItems()}
        </Menu>
      </Drawer>

      {/* Conteúdo Principal */}
      <Layout>
        {/* Novo Card de Recompensa Semanal */}
        
        <FloatingClientCard />
        <Content style={{ padding: '16px' }}>
          
          {/* Filtros */}
          <Title level={4} style={{ textAlign: 'left', marginBottom: '16px' }}>
            
          </Title>
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6} lg={4}>
                <Form.Item label="Marca" style={{ marginBottom: '8px' }}>
                  <Select
                    placeholder="Selecione a marca"
                    style={{ width: '100%' }}
                    value={filters.marca}
                    onChange={(value) => handleFilterChange('marca', value)}
                  >
                    <Option value="">Todas</Option>
                    {/* Lista de marcas predefinidas */}
                    {[
                      'Acura',
                      'Alfa Romeo',
                      'Aston Martin',
                      'Audi',
                      'Bentley',
                      'BMW',
                      'Bugatti',
                      'BYD',
                      'Cadillac',
                      'Chevrolet',
                      'Chrysler',
                      'Dodge',
                      'Ferrari',
                      'GMC',
                      'Ford',
                      'Jaguar',
                      'Jeep',
                      'Kia',
                      'Koenigsegg',
                      'Lamborghini',
                      'Land Rover',
                      'Lexus',
                      'Maserati',
                      'Mazda',
                      'Mclaren',
                      'Mercedes',
                      'Nissan',
                      'Pagani',
                      'Porsche',
                      'Rolls-Royce',
                      'Subaru',
                      'Tesla',
                      'Volkswagen',
                      'Volvo',
                    ].map((marca) => (
                      <Option key={marca} value={marca}>
                        {marca}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              
              
              <Col xs={24} sm={12} md={6} lg={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  style={{ backgroundColor: '#202020', width: '100%' }}
                  type="primary"
                  onClick={resetFilters}
                >
                  Limpar
                </Button>
              </Col>
            </Row>
          </Form>

          <div style={{ margin: '24px 0' }} />

          <Title level={4} style={{ textAlign: 'left', marginBottom: '16px' }}>
            Explorar Carros VIP
          </Title>

          {/* Indicador de carregamento */}
          {loading && (
            <Row justify="center">
              <Spin size="large" />
            </Row>
          )}

          {/* Mensagem quando nenhum carro é encontrado */}
          {!loading && cars.length === 0 && (
            <Row justify="center">
              <Text>Nenhum carro VIP encontrado.</Text>
            </Row>
          )}

          {/* Grid de Carros VIP */}
          <Row gutter={[16, 16]}>
            {cars.map((car) => (
              <Col
                key={car.id}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={6}
                style={{ textAlign: 'center' }}
              >
                <Tooltip
                  title={
                    <div>
                      <p>
                        <strong>Ano:</strong> {car.ano}
                      </p>
                      <p>
                        <strong>Valor:</strong> {car.goldvalue.toLocaleString('pt-BR')} Ouro
                      </p>
                      <p>
                        <strong>Km:</strong> {car.km.toLocaleString('pt-BR')}
                      </p>
                      <p>
                        <strong>Tipo:</strong> {car.tipo}
                      </p>
                      <p>
                        <strong>Potência:</strong> {car.potencia} cv
                      </p>
                      <p>
                        <strong>Unidades Fabricadas:</strong> {car.units}
                      </p>
                    </div>
                  }
                >
                  <div
                    style={{
                      position: 'relative',
                      paddingBottom: '100%',
                      overflow: 'hidden',
                      borderRadius: '4px',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                      cursor: car.units > 0 ? 'pointer' : 'not-allowed',
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      opacity: car.units > 0 ? 1 : 0.6, // Diminuir a opacidade se não disponível
                    }}
                    onMouseEnter={(e) => {
                      if (car.units > 0) {
                        e.currentTarget.style.transform = 'scale(1.05)';
                        e.currentTarget.style.boxShadow = '0 4px 12px rgba(21, 80, 240, 0.8)';
                      }
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = 'scale(1)';
                      e.currentTarget.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.15)';
                    }}
                    onClick={() => {
                      if (car.units > 0) {
                        handleCarClick(car);
                      } else {
                        message.warning('Este carro VIP não está mais disponível para compra.');
                      }
                    }}
                  >
                    {/* Carregamento Sob Demanda da imagem de capa */}
                    <img
                      src={car.imagens[0]}
                      alt={car.modelo}
                      loading="lazy"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        maxWidth: '100%', // Previne overflow
                      }}
                    />
                  </div>
                </Tooltip>
                <Title level={5} style={{ marginTop: '8px' }}>
                  {car.marca} {car.modelo}
                </Title>
                <Text>💰 {car.goldvalue.toLocaleString('pt-BR')} Barras de Ouro</Text>
              </Col>
            ))}
          </Row>

          {/* Botão para carregar mais carros */}
          {hasMore && !loading && (
            <Row justify="center" style={{ marginTop: '20px' }}>
              <Button type="primary" onClick={fetchMoreCars}>
                Carregar Mais
              </Button>
            </Row>
          )}

          {/* Modal de Compra */}
          {selectedCar && (
            <Modal
              visible={modalVisible}
              onCancel={closeModal}
              footer={null}
              width={800}
              centered
              destroyOnClose
            >
              {/* Utilizando o componente LuxuryCarDetail para os detalhes */}
              <LuxuryCarDetail car={selectedCar} onBuy={handleBuyCar} isBuying={isBuying} />
            </Modal>
          )}

        </Content>
      </Layout>
    </Layout>
  );
};

export default VipMarket;
