import React, { useState, useEffect } from 'react';
import { Typography, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const VipBanner = () => {
  const navigate = useNavigate();
  const [responsiveStyles, setResponsiveStyles] = useState({
    bannerHeight: '150px',
    titleFontSize: '1.8rem',
    subtitleFontSize: '1rem',
  });

  // Atualiza os estilos responsivos dinamicamente
  useEffect(() => {
    const updateStyles = () => {
      const width = window.innerWidth;
      let bannerHeight = '150px';
      let titleFontSize = '1.8rem';
      let subtitleFontSize = '1rem';

      if (width <= 480) {
        bannerHeight = '120px';
        titleFontSize = '1.2rem';
        subtitleFontSize = '0.8rem';
      } else if (width <= 768) {
        bannerHeight = '140px';
        titleFontSize = '1.5rem';
        subtitleFontSize = '0.9rem';
      }

      setResponsiveStyles({ bannerHeight, titleFontSize, subtitleFontSize });
    };

    updateStyles(); // Chama inicialmente
    window.addEventListener('resize', updateStyles); // Adiciona listener

    return () => {
      window.removeEventListener('resize', updateStyles); // Remove listener na limpeza
    };
  }, []);

  const handleClick = () => {
    navigate('/vipmarket');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleClick();
    }
  };

  const bannerStyle = {
    position: 'relative',
    width: '100%',
    height: responsiveStyles.bannerHeight,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '12px',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    margin: '10px 0', // Espaçamento vertical entre os banners
    backgroundImage:
      'url("https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Brands%2FBugatti%2FChiron%20Super%20Sport%2F2022-Bugatti-Chiron-Super-Sport-009-1080.jpg?alt=media&token=c12ffe90-8d5c-4202-8acc-998262c4423f")', // URL do primeiro banner
  };

  const secondBannerStyle = {
    ...bannerStyle,
    backgroundImage:
      'url("https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Brands%2FMclaren%2FW1%2FScreenshot_20241006_092342_YouTube.jpg?alt=media&token=5ee61d7a-e2a9-40ee-9ae9-a3c7e2306401")', // URL do segundo banner
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)', // Sobreposição para melhorar a legibilidade do texto
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 16px',
  };

  const titleStyle = {
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: responsiveStyles.titleFontSize,
    marginBottom: '8px', // Espaço entre o título e o subtítulo
  };

  const subtitleStyle = {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: responsiveStyles.subtitleFontSize,
  };

  return (
    <Row gutter={[16, 16]} justify="center">
      {/* Primeiro Banner */}
      <Col xs={24} sm={24} md={12}>
        <div
          style={bannerStyle}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          onKeyPress={handleKeyPress}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.02)';
            e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.3)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.15)';
          }}
        >
          <div style={overlayStyle}>
            <Title level={2} style={titleStyle}>
              Mercado VIP
            </Title>
            <Text style={subtitleStyle}>
              Monte sua coleção exclusiva
            </Text>
          </div>
        </div>
      </Col>

      {/* Segundo Banner 
      <Col xs={24} sm={24} md={12}>
        <div
          style={secondBannerStyle}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          onKeyPress={handleKeyPress}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.02)';
            e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.3)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.15)';
          }}
        >
          <div style={overlayStyle}>
            <Title level={2} style={titleStyle}>
              Leilão - Em breve
            </Title>
            <Text style={subtitleStyle}>
              Fique atento para participar
            </Text>
          </div>
        </div>
      </Col>*/}
    </Row>
  );
};

export default VipBanner;
