// src/OffersSection.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Spin, message, Tooltip, Alert, Modal, Button } from 'antd';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { firestore } from './firebaseConfig'; // Certifique-se de que este caminho está correto
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { GoldOutlined } from '@ant-design/icons';

/**
 * Styled Components para OffersSection
 */

// Container principal das ofertas
const OffersContainer = styled.div`
  padding: 48px 24px;
  background-color: #f0f2f5;
  min-height: 100vh;
  box-sizing: border-box;
`;

// Cabeçalho das ofertas
const OffersHeader = styled.div`
  text-align: center;
  margin-bottom: 48px;
`;

// Título das ofertas
const OffersTitle = styled.h2`
  font-size: 36px;
  color: #333333;
  margin-bottom: 16px;
  font-weight: 700;
`;

// Subtítulo das ofertas
const OffersSubtitle = styled.p`
  font-size: 20px;
  color: #555555;
`;

// Container de loading
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

// Container de erro
const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

/**
 * OfferCard Component
 * 
 * Representa cada oferta individual com animações e estilização aprimorada.
 * 
 * @param {Object} props - Props passadas para o componente
 * @param {Object} props.offer - Detalhes da oferta
 * @param {Function} props.onPurchase - Função para lidar com a compra
 */
const OfferCard = React.memo(({ offer, onPurchase }) => {
  return (
    <StyledCard
      whileHover={{ scale: 1.02 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      aria-label={`Oferta ${offer.title}`}
    >
      <CardHeader>
        <GoldOutlined style={{ fontSize: '32px', color: '#FFD700' }} />
        <h3 style={{ margin: '16px 0 0 0', color: '#333333' }}>{offer.title}</h3>
      </CardHeader>
      <CardBody>
        <Price>{offer.price.toLocaleString('pt-BR')} barras</Price>
        <FeatureList>
          {offer.features.map((feature, index) => (
            <FeatureItem key={index}>
              <GoldOutlined />
              {feature}
            </FeatureItem>
          ))}
        </FeatureList>
        <Tooltip title="Multiplique seu lucro temporariamente">
          <BuyButton
            type="primary"
            icon={<GoldOutlined />}
            onClick={() => onPurchase(offer)}
            aria-label={`Comprar ${offer.title}`}
          >
            Multiplicar meu lucro
          </BuyButton>
        </Tooltip>
      </CardBody>
    </StyledCard>
  );
});

/**
 * Styled Components para OfferCard
 */

// Cartão estilizado com animações
const StyledCard = styled(motion.div)`
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

// Cabeçalho do cartão
const CardHeader = styled.div`
  background: #f6f6f6;
  padding: 16px;
  text-align: center;
`;

// Corpo do cartão
const CardBody = styled.div`
  padding: 24px;
  text-align: center;
`;

// Preço da oferta
const Price = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 16px 0;
`;

// Lista de características
const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 24px;
`;

// Item da lista de características
const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #555555;

  svg {
    color: #FFD700;
    margin-right: 8px;
  }
`;

// Botão de compra estilizado
const BuyButton = styled(Button)`
  width: 100%;
  background-color: #080808;
  border-color: #080808;
  color: #ffffff;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover, &:focus {
    background-color: #333333;
    border-color: #333333;
    color: #ffffff;
  }
`;

/**
 * ConfirmationModal Component
 * 
 * Lida com o modal de confirmação de compra com animações e informações detalhadas.
 * 
 * @param {Object} props - Props passadas para o componente
 * @param {boolean} props.visible - Visibilidade do modal
 * @param {Function} props.onConfirm - Função para lidar com a confirmação
 * @param {Function} props.onCancel - Função para lidar com o cancelamento
 * @param {Object} props.offer - Detalhes da oferta selecionada
 */
const ConfirmationModal = ({ visible, onConfirm, onCancel, offer }) => {
  return (
    <StyledModal
      initial={{ scale: 0.8, opacity: 0 }}
      animate={visible ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
      transition={{ duration: 0.3 }}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
    >
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={null}
        centered
        destroyOnClose
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <ModalHeader>
          <h2 id="confirm-modal-title">Confirmar Compra</h2>
        </ModalHeader>
        <ModalBody>
          {offer && (
            <div>
              <p id="confirm-modal-description">
                Você deseja comprar o turbo <strong>{offer.title}</strong> por{' '}
                <strong>{offer.price.toLocaleString('pt-BR')} barras de ouro</strong>?
              </p>
              <p>
                Com esse turbo temporário, seu lucro será multiplicado por <strong>{offer.profitMultiplier}x</strong> por{' '}
                <strong>{offer.duration / 60} hora(s)</strong>.
              </p>
              <ConfirmButton
                type="primary"
                onClick={onConfirm}
                aria-label="Confirmar Compra"
                loading={false}
              >
                Confirmar
              </ConfirmButton>
              <CancelButton
                onClick={onCancel}
                aria-label="Cancelar Compra"
              >
                Cancelar
              </CancelButton>
            </div>
          )}
        </ModalBody>
      </Modal>
    </StyledModal>
  );
};

/**
 * Styled Components para ConfirmationModal
 */

// Modal estilizado com animações
const StyledModal = styled(motion.div)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
`;

// Cabeçalho do modal
const ModalHeader = styled.div`
  background-color: #080808;
  color: #ffffff;
  padding: 16px;
  text-align: center;
`;

// Corpo do modal
const ModalBody = styled.div`
  padding: 24px;
  text-align: center;
`;

// Botão de confirmação estilizado
const ConfirmButton = styled(Button)`
  background-color: #FFD700;
  border-color: #FFD700;
  color: #ffffff;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover, &:focus {
    background-color: #FFC300;
    border-color: #FFC300;
    color: #ffffff;
  }
`;

// Botão de cancelamento estilizado
const CancelButton = styled(Button)`
  margin-left: 16px;
  background-color: #cccccc;
  border-color: #cccccc;
  color: #333333;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover, &:focus {
    background-color: #b3b3b3;
    border-color: #b3b3b3;
    color: #ffffff;
  }
`;

/**
 * OffersSection Component
 * 
 * Componente principal que gerencia a exibição das ofertas e o processo de compra com confirmação.
 */
const OffersSection = () => {
  // Estado para armazenar dados do jogador
  const [playerData, setPlayerData] = useState(null);
  // Estado para controlar o carregamento
  const [loading, setLoading] = useState(true);
  // Estado para controlar a visibilidade do modal de confirmação
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Estado para armazenar a oferta selecionada
  const [selectedOffer, setSelectedOffer] = useState(null);
  // Estado para controlar o processamento da compra
  const [isProcessing, setIsProcessing] = useState(false);
  const auth = getAuth();

  /**
   * Função para buscar dados do jogador no Firestore
   */
  const fetchPlayerData = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const playerDocRef = doc(firestore, 'players', user.uid);
        const playerDocSnap = await getDoc(playerDocRef);

        if (playerDocSnap.exists()) {
          const data = playerDocSnap.data();
          setPlayerData(data);
          console.log('Dados do Jogador:', data); // Log para depuração

          // Verifica se há um buff ativo
          if (data.buffActive && data.lastbufftime && data.selectedOffer) {
            const now = new Date();
            const buffStartTime = data.lastbufftime.toDate();
            const buffDuration = data.selectedOffer.duration; // em minutos
            const buffEndTime = new Date(buffStartTime.getTime() + buffDuration * 60000);

            console.log('Hora de Início do Buff:', buffStartTime);
            console.log('Duração do Buff (minutos):', buffDuration);
            console.log('Hora de Término do Buff:', buffEndTime);
            console.log('Hora Atual:', now);

            if (now >= buffEndTime) {
              // Buff expirou, reseta os valores
              await updateDoc(playerDocRef, {
                profit: data.originalProfit || data.profit / data.selectedOffer.profitMultiplier,
                buffActive: false,
                selectedOffer: null,
                lastbufftime: null,
                originalProfit: null,
              });

              setPlayerData({
                ...data,
                profit: data.originalProfit || data.profit / data.selectedOffer.profitMultiplier,
                buffActive: false,
                selectedOffer: null,
                lastbufftime: null,
                originalProfit: null,
              });

              message.info('O turbo expirou e o lucro foi restaurado ao normal.');
            } else {
              // Buff ainda está ativo
              setPlayerData(data);
            }
          } else {
            // Sem buff ativo
            setPlayerData(data);
          }
        } else {
          message.error('Documento do jogador não encontrado.');
        }
      } else {
        message.error('Usuário não autenticado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do jogador:', error);
      message.error('Erro ao buscar dados do jogador.');
    } finally {
      setLoading(false);
    }
  }, [auth]);

  /**
   * useEffect Hook
   * 
   * Busca os dados do jogador ao montar o componente e configura um intervalo para verificar a expiração do buff.
   */
  useEffect(() => {
    fetchPlayerData();

    // Verifica a cada minuto se o buff expirou
    const interval = setInterval(() => {
      fetchPlayerData();
    }, 60000); // 1 minuto

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar
  }, [fetchPlayerData]);

  /**
   * Função para exibir o modal de confirmação de compra
   * 
   * @param {Object} offer - A oferta que está sendo comprada
   */
  const showPurchaseConfirmation = (offer) => {
    if (playerData.buffActive) {
      message.error('Você já tem um turbo ativo. Aguarde ele expirar antes de comprar outro.');
      return;
    }
    setSelectedOffer(offer);
    setIsModalVisible(true);
  };

  /**
   * Função para lidar com a confirmação da compra
   */
  const handleConfirmPurchase = async () => {
    if (playerData && selectedOffer) {
      const { gold } = playerData; // Usando 'gold' em vez de 'balance'
      const { price, profitMultiplier, duration } = selectedOffer;

      if (gold >= price) { // Verificação usando 'gold'
        const newGold = gold - price; // Dedução de 'gold'

        try {
          setIsProcessing(true);
          const playerDocRef = doc(firestore, 'players', auth.currentUser.uid);

          // Salva o lucro original antes de aplicar o buff
          const originalProfit = playerData.originalProfit || playerData.profit;

          // Atualiza o documento do jogador com o novo gold, status do buff e timestamps
          await updateDoc(playerDocRef, {
            gold: newGold, // Atualiza 'gold' no Firestore
            profit: originalProfit * profitMultiplier,
            buffActive: true, // Define que o buff está ativo
            selectedOffer: selectedOffer, // Salva a oferta selecionada
            lastbufftime: Timestamp.now(), // Registra o tempo de compra do buff
            originalProfit: originalProfit, // Armazena o lucro original
          });

          message.success('Turbo adquirido com sucesso!');
          setPlayerData({
            ...playerData,
            gold: newGold, // Atualiza o estado local com o novo 'gold'
            profit: originalProfit * profitMultiplier,
            buffActive: true,
            selectedOffer: selectedOffer,
            lastbufftime: Timestamp.now(), // Atualiza o estado local com o timestamp atual
            originalProfit: originalProfit,
          });

          setIsModalVisible(false);
        } catch (error) {
          console.error('Erro ao atualizar o jogador:', error);
          message.error('Erro ao realizar a compra.');
        } finally {
          setIsProcessing(false);
        }
      } else {
        message.error('Saldo de ouro insuficiente.'); // Mensagem ajustada para ouro
      }
    }

    setIsModalVisible(false);
  };

  /**
   * Define as ofertas disponíveis
   */
  const offers = [
    {
      title: 'Lucro 2x por 1 hora',
      description: 'Multiplique seu lucro por 2x durante 1 hora.',
      price: 1000,
      profitMultiplier: 2,
      duration: 60, // em minutos
      features: [
        'Multiplicação de 2x no lucro',
        'Duração de 1 hora',
        'Aplicável em todas as operações',
      ],
    },
    {
      title: 'Lucro 3x por 1 hora',
      description: 'Multiplique seu lucro por 3x durante 1 hora.',
      price: 2500,
      profitMultiplier: 3,
      duration: 60, // em minutos
      features: [
        'Multiplicação de 3x no lucro',
        'Duração de 1 hora',
        'Aplicável em todas as operações',
      ],
    },
    {
      title: 'Lucro 10x por 3 horas',
      description: 'Multiplique seu lucro por 10x durante 3 horas.',
      price: 10000,
      profitMultiplier: 10,
      duration: 180, // em minutos
      features: [
        'Multiplicação de 10x no lucro',
        'Duração de 3 horas',
        'Aplicável em todas as operações',
      ],
    },
    {
      title: 'Lucro 10x por 7 dias',
      description: 'Multiplique seu lucro por 10x durante 7 dias.',
      price: 100000,
      profitMultiplier: 20,
      duration: 180, // em minutos
      features: [
        'Multiplicação de 10x no lucro',
        'Duração de 7 dias',
        'Aplicável em todas as operações',
      ],
    },
  ];

  /**
   * Renderiza o estado de carregamento
   */
  if (loading) {
    return (
      <OffersContainer>
        <LoadingContainer>
          <Spin tip="Carregando ofertas..." size="large" />
        </LoadingContainer>
      </OffersContainer>
    );
  }

  /**
   * Renderiza o estado de erro
   */
  if (!playerData) {
    return (
      <OffersContainer>
        <ErrorContainer>
          <Alert message="Erro" description="Erro ao carregar as ofertas." type="error" showIcon />
        </ErrorContainer>
      </OffersContainer>
    );
  }

  return (
    <OffersContainer>
      {/* Cabeçalho das Ofertas */}
      <OffersHeader>
        <OffersTitle>Multiplicador de Lucros</OffersTitle>
        
      </OffersHeader>

      {/* Grid de Ofertas com Animações */}
      <Row gutter={[24, 24]} justify="center">
        {offers.map((offer, index) => (
          <Col key={index} xs={24} sm={12} md={8}>
            <OfferCard offer={offer} onPurchase={showPurchaseConfirmation} />
          </Col>
        ))}
      </Row>

      {/* Modal de Confirmação */}
      <ConfirmationModal
        visible={isModalVisible}
        onConfirm={handleConfirmPurchase}
        onCancel={() => setIsModalVisible(false)}
        offer={selectedOffer}
      />
    </OffersContainer>
  );
};

export default OffersSection;
