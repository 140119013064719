// src/components/CarImportMap.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Polyline,
} from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid'; // Para gerar IDs únicos

// URL do ícone do avião
const AIRPLANE_ICON_URL =
  'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fairplane.png?alt=media&token=4d2143f4-f149-4d80-93a3-9d2073e2e2c2';

// Coordenadas de diferentes origens e destinos
const FLIGHT_PLANS = [
  {
    origin: { lat: 48.8566, lng: 2.3522 }, // Paris
    destination: { lat: -23.5505, lng: -46.6333 }, // São Paulo
  },
  {
    origin: { lat: 40.7128, lng: -74.006 }, // Nova York
    destination: { lat: 34.0522, lng: -118.2437 }, // Los Angeles
  },
  {
    origin: { lat: 35.6895, lng: 139.6917 }, // Tóquio
    destination: { lat: 51.5074, lng: -0.1278 }, // Londres
  },
  {
    origin: { lat: 55.7558, lng: 37.6173 }, // Moscou
    destination: { lat: 34.6937, lng: 135.5023 }, // Osaka
  },
  {
    origin: { lat: -33.8688, lng: 151.2093 }, // Sydney
    destination: { lat: 1.3521, lng: 103.8198 }, // Singapura
  },
];

// Estilo do contêiner do mapa
const mapContainerStyle = {
  width: '100%',
  height: '600px',
};

// Opções de configuração do mapa
const options = {
  disableDefaultUI: false,
  zoomControl: true,
};

// Chave da Google Maps API (substitua pela sua chave)
const GOOGLE_MAPS_API_KEY =
  'AIzaSyAJsztuM1SQPtmLsx-LaJwgML_zCZaltR8'; // Substitua pela sua chave válida

// Seu Map ID criado no Google Cloud Console
const MAP_ID = 'SEU_MAP_ID_AQUI'; // Substitua pelo seu Map ID

// Função para calcular a distância entre dois pontos usando a fórmula de Haversine
const haversineDistance = (coords1, coords2) => {
  const toRad = (x) => (x * Math.PI) / 180;

  const lat1 = coords1.lat;
  const lon1 = coords1.lng;

  const lat2 = coords2.lat;
  const lon2 = coords2.lng;

  const R = 6371; // Raio da Terra em km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

// Função para gerar pontos intermediários entre origem e destino
const generateRoute = (start, end, numPoints) => {
  const latStep = (end.lat - start.lat) / numPoints;
  const lngStep = (end.lng - start.lng) / numPoints;

  const route = [];
  for (let i = 0; i <= numPoints; i++) {
    route.push({
      lat: start.lat + latStep * i,
      lng: start.lng + lngStep * i,
    });
  }
  return route;
};

// Estrutura de um voo
const createFlight = (origin, destination) => {
  const flightDurationMinutes = 20; // Duração de 20 minutos
  const numPoints = flightDurationMinutes * 60; // 1 ponto por segundo
  const route = generateRoute(origin, destination, numPoints);

  return {
    id: uuidv4(),
    origin,
    destination,
    route,
    currentIndex: 0,
    position: origin,
    elapsedTime: 0, // Em segundos
    distanceTravelled: 0, // Em km
    eta: flightDurationMinutes, // Em minutos
    status: 'in-progress', // in-progress, completed
    marker: null, // Referência para o AdvancedMarkerElement
  };
};

// Componente para representar cada marcador de voo
const FlightMarker = ({ flight, updateMarker }) => {
  const markerRef = useRef(null);

  useEffect(() => {
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.marker &&
      window.google.maps.marker.AdvancedMarkerElement &&
      flight.position
    ) {
      // Cria um elemento DOM para o conteúdo do marcador
      const contentDiv = document.createElement('div');
      const img = document.createElement('img');
      img.src = AIRPLANE_ICON_URL;
      img.alt = 'Avião de Carga';
      img.style.width = '50px';
      img.style.height = '50px';
      contentDiv.appendChild(img);

      // Cria o AdvancedMarkerElement
      const advancedMarker = new window.google.maps.marker.AdvancedMarkerElement({
        position: flight.position,
        map: null, // Será adicionado ao mapa posteriormente
        title: `Voo ${flight.id.slice(0, 8)}`,
        content: contentDiv,
      });

      // Atualiza a posição do marcador
      advancedMarker.position = flight.position;

      // Atualiza o estado do marcador no componente pai
      updateMarker(flight.id, advancedMarker);

      // Referência ao marcador
      markerRef.current = advancedMarker;

      // Limpa o marcador ao desmontar
      return () => {
        advancedMarker.setMap(null);
      };
    }
  }, [flight.position, flight.id, updateMarker]);

  // Atualiza a posição do marcador sempre que a posição do voo muda
  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.position = flight.position;
    }
  }, [flight.position]);

  return null; // Este componente não renderiza nada diretamente
};

// Componente Principal
const CarImportMap = () => {
  // Carregamento da Google Maps API com a biblioteca 'marker' para AdvancedMarkerElement
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['marker'], // Necessário para AdvancedMarkerElement
  });

  // Estados para gerenciar a lista de voos
  const [flights, setFlights] = useState([]);

  // Referência para o mapa
  const mapRef = useRef(null);

  // Função para lidar com o carregamento do mapa
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;

    // Inicializar todos os voos
    const initialFlights = FLIGHT_PLANS.map((plan) =>
      createFlight(plan.origin, plan.destination)
    );
    setFlights(initialFlights);
  }, []);

  // Função para atualizar a posição de todos os voos
  const updateFlights = useCallback(() => {
    setFlights((prevFlights) =>
      prevFlights.map((flight) => {
        if (flight.status !== 'in-progress') return flight;

        const newElapsedTime = flight.elapsedTime + 1; // Incrementa 1 segundo

        if (newElapsedTime >= 20 * 60) {
          // 20 minutos em segundos
          return {
            ...flight,
            elapsedTime: newElapsedTime,
            eta: 0,
            status: 'completed',
          };
        }

        const newIndex = flight.currentIndex + 1;
        const newPosition =
          flight.route[newIndex] || flight.route[flight.route.length - 1];
        const distance = haversineDistance(flight.position, newPosition);
        const newDistanceTravelled = flight.distanceTravelled + distance;
        const newEta = Math.max(20 - Math.floor(newElapsedTime / 60), 0);

        return {
          ...flight,
          currentIndex: newIndex,
          position: newPosition,
          elapsedTime: newElapsedTime,
          distanceTravelled: newDistanceTravelled,
          eta: newEta,
        };
      })
    );
  }, []);

  // Interval para atualizar os voos a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      updateFlights();
    }, 1000);

    return () => clearInterval(interval);
  }, [updateFlights]);

  // Função para atualizar o marcador de um voo específico
  const updateMarker = useCallback((flightId, marker) => {
    setFlights((prevFlights) =>
      prevFlights.map((flight) =>
        flight.id === flightId ? { ...flight, marker } : flight
      )
    );

    // Adicionar o marcador ao mapa
    if (marker && mapRef.current) {
      marker.map = mapRef.current;
    }
  }, []);

  /**
   * Estilo do painel de métricas
   */
  const metricsStyle = {
    marginTop: '20px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    maxWidth: '800px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    boxSizing: 'border-box',
  };

  /**
   * Estilo para cada item de métrica na lista
   */
  const metricsItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  };

  /**
   * Estilo para o cabeçalho da lista
   */
  const listHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    borderRadius: '5px',
    marginBottom: '10px',
  };

  /**
   * Estilo para o container da lista
   */
  const listContainerStyle = {
    maxHeight: '300px',
    overflowY: 'auto',
  };

  /**
   * Estilo para o container do mapa e da lista
   */
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  /**
   * Estilo para o título da lista
   */
  const titleStyle = {
    marginBottom: '10px',
    textAlign: 'center',
    fontSize: '24px',
    color: '#333',
  };

  /**
   * Estilo para o container geral
   */
  const generalContainerStyle = {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  /**
   * Estilo para responsividade
   */
  const responsiveStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  };

  /**
   * Renderização condicional para erros e carregamento
   */
  if (loadError) return <div>Erro ao carregar o mapa.</div>;
  if (!isLoaded) return <div>Carregando o mapa...</div>;

  /**
   * Renderização do componente
   */
  return (
    <div style={generalContainerStyle}>
      <h1 style={{ textAlign: 'center' }}>Importação de Bugatti</h1>
      <div style={responsiveStyle}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={FLIGHT_PLANS[0].origin} // Usar a primeira origem como centro
          zoom={3}
          options={{ ...options, mapId: MAP_ID }} // Integração do Map ID
          onLoad={onMapLoad}
        >
          {/* Marcadores de Origem */}
          {flights.map((flight) => (
            <Marker
              key={`${flight.id}-origin`}
              position={flight.origin}
              label="Origem"
              icon={{
                url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
              }}
            />
          ))}

          {/* Marcadores de Destino */}
          {flights.map((flight) => (
            <Marker
              key={`${flight.id}-destination`}
              position={flight.destination}
              label="Destino"
              icon={{
                url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
              }}
            />
          ))}

          {/* Trajetos dos Voos */}
          {flights.map((flight) => (
            <Polyline
              key={`${flight.id}-polyline`}
              path={flight.route.slice(0, flight.currentIndex + 1)}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          ))}

          {/* Marcadores dos Aviões */}
          {flights.map((flight) => (
            <FlightMarker
              key={flight.id}
              flight={flight}
              updateMarker={updateMarker}
            />
          ))}
        </GoogleMap>

        {/* Painel de Métricas */}
        <div style={metricsStyle}>
          <h2 style={titleStyle}>Voos em Curso</h2>
          <div style={listContainerStyle}>
            <div style={listHeaderStyle}>
              <span><strong>ID do Voo</strong></span>
              <span><strong>Origem</strong></span>
              <span><strong>Destino</strong></span>
              <span><strong>Tempo Restante</strong></span>
            </div>
            {flights.map((flight) => (
              <div key={flight.id} style={metricsItemStyle}>
                <span>Voo {flight.id.slice(0, 8)}</span>
                <span>
                  {flight.origin.lat.toFixed(4)}, {flight.origin.lng.toFixed(4)}
                </span>
                <span>
                  {flight.destination.lat.toFixed(4)}, {flight.destination.lng.toFixed(4)}
                </span>
                <span>{flight.eta} min</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Exportação do Componente
export default CarImportMap;
