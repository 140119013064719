import React, { useState } from 'react';
import { Layout, Table, Tag, Button, Modal, Select, Typography, message, Menu, Switch } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined, HomeOutlined, CarOutlined, ShopOutlined, LogoutOutlined, ShoppingOutlined, VideoCameraOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

// Dados de teste de clientes
const initialClients = [
  {
    key: '1',
    name: 'João Silva',
    potentialSpending: 'R$ 1.200.000',
    lastPurchase: '2024-08-10',
    lastInteraction: '2024-08-12',
    trustLevel: 3,
    actions: ['Oferecer Novo Carro', 'Enviar Ofertas Especiais', 'Enviar Mensagem'],
    interestedCars: {
      1: ['Carro A', 'Carro B'],
      2: ['Carro C', 'Carro D'],
      3: ['Carro E', 'Carro F'],
      4: ['Carro G', 'Carro H'],
      5: ['Carro I', 'Carro J'],
    },
  },
  {
    key: '2',
    name: 'Maria Souza',
    potentialSpending: 'R$ 800.000',
    lastPurchase: '2024-08-08',
    lastInteraction: '2024-08-09',
    trustLevel: 4,
    actions: ['Oferecer Novo Carro', 'Enviar Ofertas Especiais', 'Enviar Mensagem'],
    interestedCars: {
      1: ['Carro K', 'Carro L'],
      2: ['Carro M', 'Carro N'],
      3: ['Carro O', 'Carro P'],
      4: ['Carro Q', 'Carro R'],
      5: ['Carro S', 'Carro T'],
    },
  },
  {
    key: '3',
    name: 'Carlos Pereira',
    potentialSpending: 'R$ 2.000.000',
    lastPurchase: '2024-08-15',
    lastInteraction: '2024-08-17',
    trustLevel: 5,
    actions: ['Oferecer Novo Carro', 'Enviar Ofertas Especiais', 'Enviar Mensagem'],
    interestedCars: {
      1: ['Carro U', 'Carro V'],
      2: ['Carro W', 'Carro X'],
      3: ['Carro Y', 'Carro Z'],
      4: ['Carro AA', 'Carro AB'],
      5: ['Carro AC', 'Carro AD'],
    },
  },
];

// Component principal
const VIPClientManagement = () => {
  const [clients, setClients] = useState(initialClients); // Dados de clientes
  const [selectedAction, setSelectedAction] = useState(null); // Ação selecionada
  const [isModalVisible, setIsModalVisible] = useState(false); // Controle de visibilidade do modal
  const [currentClient, setCurrentClient] = useState(null); // Cliente atual para ação
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false); // Modal de informações
  const [benefitMessage, setBenefitMessage] = useState(''); // Mensagem de benefício da ação
  const [toggledClients, setToggledClients] = useState({}); // Armazenar o estado dos toggles

  const location = useLocation();

  // Ações disponíveis e benefícios
  const actionBenefits = {
    'Oferecer Novo Carro': 'Ao oferecer um novo carro, você aumenta o nível de confiança do cliente.',
    'Enviar Ofertas Especiais': 'Enviar ofertas especiais aumenta a satisfação do cliente.',
    'Enviar Mensagem': 'Manter contato com o cliente melhora o relacionamento e fidelidade.',
  };

  // Abrir o modal de confirmação da ação
  const handleAction = (client, action) => {
    setSelectedAction(action);
    setCurrentClient(client);
    setBenefitMessage(actionBenefits[action]);
    setIsModalVisible(true);
  };

  // Confirmar a ação
  const confirmAction = () => {
    message.success(`Ação '${selectedAction}' realizada com sucesso para ${currentClient.name}!`);
    setIsModalVisible(false);
  };

  // Abrir o modal de informações do cliente
  const handleClientClick = (client) => {
    setCurrentClient(client);
    setIsInfoModalVisible(true);
  };

  // Toggle switch
  const handleToggle = (clientKey, checked) => {
    setToggledClients({
      ...toggledClients,
      [clientKey]: checked,
    });
    message.info(
      `${checked ? 'Ativado' : 'Desativado'} o serviço especial para ${clients.find((client) => client.key === clientKey).name}`
    );
  };

  // Colunas da tabela
  const columns = [
    {
      title: 'Cliente VIP',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a
          onClick={() => handleClientClick(record)}
          style={{ cursor: 'pointer', color: '#1890ff', fontWeight: 'bold' }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Gasto Potencial',
      dataIndex: 'potentialSpending',
      key: 'potentialSpending',
      render: (text) => <Tag color="gold">{text}</Tag>,
    },
    {
      title: 'CAMPO LIVRE',
      dataIndex: 'lastPurchase',
      key: 'lastPurchase',
      render: (text) => <Tag color="green">{text}</Tag>,
    },
    {
      title: 'CAMPO LIVRE',
      dataIndex: 'lastInteraction',
      key: 'lastInteraction',
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: 'Confiança',
      dataIndex: 'trustLevel',
      key: 'trustLevel',
      render: (trustLevel) => (
        <Tag color={trustLevel >= 4 ? 'green' : 'orange'}>{trustLevel}</Tag>
      ),
    },
    {
      title: 'Serviço Especial',
      key: 'specialService',
      render: (client) => (
        <Switch
          checked={toggledClients[client.key] || false}
          onChange={(checked) => handleToggle(client.key, checked)}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (client) => (
        <Select
          placeholder="Selecionar Ação"
          style={{ width: '100%' }}
          onChange={(value) => handleAction(client, value)}
        >
          {client.actions.map((action) => (
            <Option key={action} value={action}>
              {action}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      {/* Sidebar */}
      <Sider width={250} style={{ background: '#040611', overflow: 'hidden' }}>
        <div className="logo" style={{ height: '80px', margin: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/LGlogo.png?alt=media&token=654f4181-620b-4528-8b66-3064165c818a"
            alt="Logo"
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} style={{ background: '#040611', borderRadius: '10px' }}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<CarOutlined />}>
            <Link to="/showroom">Showroom</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ShopOutlined />}>
            <Link to="/market">Mercado</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<CarOutlined />}>
            <Link to="/colecaopessoal">Coleção Pessoal</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<ShoppingOutlined />}>
            <Link to="/shop">Loja</Link>
          </Menu.Item>
          <Menu.Item key="8" icon={<VideoCameraOutlined />}>
            <Link to="/youtube">YouTube</Link>
          </Menu.Item>
          <Menu.Item key="10" icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Content style={{ padding: '24px' }}>
          <div style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
            <Title level={2}>Gerenciamento de Clientes VIP</Title>
            <Table
              columns={columns}
              dataSource={clients}
              pagination={false}
              bordered
              style={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}
            />

            {/* Modal de confirmação de ação */}
            <Modal
              title={`Confirmar Ação: ${selectedAction}`}
              visible={isModalVisible}
              onOk={confirmAction}
              onCancel={() => setIsModalVisible(false)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <Text>
                Tem certeza que deseja realizar a ação <strong>{selectedAction}</strong> para{' '}
                <strong>{currentClient?.name}</strong>?<br />
                <br />
                Benefício: <strong>{benefitMessage}</strong>
              </Text>
            </Modal>

            {/* Modal de informações do cliente */}
            <Modal
              title={`Informações de ${currentClient?.name}`}
              visible={isInfoModalVisible}
              onOk={() => setIsInfoModalVisible(false)}
              onCancel={() => setIsInfoModalVisible(false)}
              okText="Fechar"
              cancelText="Cancelar"
            >
              <Text>
                <strong>Nome:</strong> {currentClient?.name}<br />
                <strong>Gasto Potencial:</strong> {currentClient?.potentialSpending}<br />
                <strong>Última Compra:</strong> {currentClient?.lastPurchase}<br />
                <strong>Última Interação:</strong> {currentClient?.lastInteraction}<br />
                <strong>Carros de Interesse:</strong> <br />
                {currentClient &&
                  currentClient.interestedCars[currentClient.trustLevel].map((car, index) => (
                    <Tag key={index} color="blue">
                      {car}
                    </Tag>
                  ))}
              </Text>
            </Modal>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default VIPClientManagement;