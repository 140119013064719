import React, { useState, useEffect } from 'react';
import { Layout, Switch, message, Card, Typography } from 'antd';
import { firestore } from './firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const { Title, Paragraph } = Typography;

const AccessControl = () => {
  const [accessEnabled, setAccessEnabled] = useState(true);

  useEffect(() => {
    // Buscar o estado atual de acesso do Firestore
    const fetchAccessState = async () => {
      const docRef = doc(firestore, 'config', 'access'); // Supondo que você tenha uma coleção 'config'
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAccessEnabled(docSnap.data().enabled);
      }
    };

    fetchAccessState();
  }, []);

  const handleToggle = async (checked) => {
    setAccessEnabled(checked);

    // Atualizar o estado no Firestore
    const docRef = doc(firestore, 'config', 'access');
    await updateDoc(docRef, { enabled: checked });

    if (checked) {
      message.success('Acesso habilitado.');
    } else {
      message.warning('Acesso desabilitado. Usuários não poderão acessar o jogo.');
    }
  };

  return (
    <Layout style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
      <Card style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '16px' }}>
          Controle de Acesso
        </Title>
        <Paragraph style={{ textAlign: 'center', marginBottom: '24px' }}>
          Use o interruptor abaixo para habilitar ou desabilitar o acesso ao jogo.
        </Paragraph>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Switch
            checked={accessEnabled}
            onChange={handleToggle}
            checkedChildren="Acesso Habilitado"
            unCheckedChildren="Acesso Desabilitado"
            style={{ width: 200 }}
          />
        </div>
      </Card>
    </Layout>
  );
};

export default AccessControl;
