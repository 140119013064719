// src/AchievementsScreen.jsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  Card,
  Progress,
  Row,
  Col,
  Typography,
  message,
  Spin,
  Alert,
  Space,
  Button,
  Statistic
} from 'antd';
import {
  ShoppingCartOutlined,
  DollarOutlined,
  LineChartOutlined,
  TrophyOutlined,
  GiftOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const { Title, Text } = Typography;

// Regras de nível
const levelRules = [
  // Níveis 1-10: Aumento de 15%
  { level: 2, salesRequired: 10,     totalProfitRequired: 100_000,            revenueRequired: 2_000_000 },
  { level: 3, salesRequired: 15,     totalProfitRequired: 160_000,            revenueRequired: 3_400_000 },
  { level: 4, salesRequired: 23,     totalProfitRequired: 256_000,            revenueRequired: 5_780_000 },
  { level: 5, salesRequired: 35,     totalProfitRequired: 409_600,            revenueRequired: 9_826_000 },
  { level: 6, salesRequired: 53,     totalProfitRequired: 655_360,            revenueRequired: 16_704_200 },
  { level: 7, salesRequired: 80,     totalProfitRequired: 1_048_576,          revenueRequired: 28_397_140 },
  { level: 8, salesRequired: 120,    totalProfitRequired: 1_677_722,          revenueRequired: 48_275_000 },
  { level: 9, salesRequired: 180,    totalProfitRequired: 2_684_355,          revenueRequired: 82_067_500 },
  { level: 10, salesRequired: 270,   totalProfitRequired: 4_294_968,          revenueRequired: 139_514_750 },
  { level: 11, salesRequired: 405,   totalProfitRequired: 6_871_949,          revenueRequired: 237_175_000 },
  { level: 12, salesRequired: 608,   totalProfitRequired: 10_995_118,         revenueRequired: 403_197_500 },
  { level: 13, salesRequired: 912,   totalProfitRequired: 17_592_189,         revenueRequired: 685_435_750 },
  { level: 14, salesRequired: 1_368, totalProfitRequired: 28_147_502,         revenueRequired: 1_165_240_775 },
  { level: 15, salesRequired: 2_052, totalProfitRequired: 45_036_003,         revenueRequired: 1_980_909_319 },
  { level: 16, salesRequired: 3_078, totalProfitRequired: 72_057_604,         revenueRequired: 3_367_545_842 },
  { level: 17, salesRequired: 4_617, totalProfitRequired: 115_292_166,        revenueRequired: 5_724_827_931 },
  { level: 18, salesRequired: 6_926, totalProfitRequired: 184_467_466,        revenueRequired: 9_732_207_483 },
  { level: 19, salesRequired: 10_389,totalProfitRequired: 295_147_946,        revenueRequired: 16_544_752_721 },
  { level: 20, salesRequired: 15_584,totalProfitRequired: 472_236_713,        revenueRequired: 28_126_079_626 },
  { level: 21, salesRequired: 23_376,totalProfitRequired: 755_578_741,        revenueRequired: 47_814_335_365 },
  { level: 22, salesRequired: 35_064,totalProfitRequired: 1_208_925_986,      revenueRequired: 81_284_370_120 },
  { level: 23, salesRequired: 52_596,totalProfitRequired: 1_934_281_578,      revenueRequired: 138_182_629_204 },
  { level: 24, salesRequired: 78_894,totalProfitRequired: 3_094_850_525,      revenueRequired: 234_910_469_646 },
  { level: 25, salesRequired: 118_341,totalProfitRequired: 4_951_760_840,     revenueRequired: 399_347_798_398 },
  { level: 26, salesRequired: 177_512,totalProfitRequired: 7_922_817_344,     revenueRequired: 678_891_257_276 },
  { level: 27, salesRequired: 266_268,totalProfitRequired: 12_684_507_750,    revenueRequired: 1_154_115_137_369 },
  { level: 28, salesRequired: 399_402,totalProfitRequired: 20_295_212_401,    revenueRequired: 1_962_995_733_527 },
  { level: 29, salesRequired: 599_103,totalProfitRequired: 32_472_339_841,    revenueRequired: 3_336_092_746_996 },
  { level: 30, salesRequired: 898_655,totalProfitRequired: 51_955_743_746,    revenueRequired: 5_671_357_669_893 },
];

// Recompensas monetárias
const monetaryRewards = {
  1: 240000,
  2: 1200000,
  3: 1920000,
  4: 2400000,
  5: 2880000,
  6: 3360000,
  7: 3840000,
  8: 4320000,
  9: 4800000,
  10: 7200000,
  11: 8400000,
  12: 10800000,
  13: 13200000,
  14: 15600000,
  15: 18000000,
  16: 20400000,
  17: 22800000,
  18: 25200000,
  19: 27600000,
  20: 30000000,
  21: 36000000,
  22: 42000000,
  23: 48000000,
  24: 54000000,
  25: 60000000,
  26: 66000000,
  27: 72000000,
  28: 78000000,
  29: 84000000,
  30: 90000000,
  31: 96000000,
  32: 102000000,
  33: 108000000,
  34: 114000000,
  35: 120000000,
  36: 126000000,
  37: 132000000,
  38: 138000000,
  39: 144000000,
  40: 150000000,
  41: 156000000,
  42: 162000000,
  43: 168000000,
  44: 174000000,
  45: 180000000,
  46: 186000000,
  47: 192000000,
  48: 198000000,
  49: 204000000,
  50: 210000000,
};

// Styled Components para animações e design sofisticado
const AnimatedCard = styled(motion.div)`
  border-radius: 16px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  padding: 40px 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const RewardCard = styled(Card)`
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const ModalBodyContent = styled.div`
  text-align: center;
`;

const AchievementsScreen = () => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  const fetchPlayerData = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const playerDoc = await getDoc(doc(firestore, 'players', user.uid));
        if (playerDoc.exists()) {
          setPlayerData(playerDoc.data());
          setLoading(false);
        } else {
          message.error('Não foi possível carregar os dados do jogador.');
          setLoading(false);
        }
      } catch (error) {
        console.error('Erro ao buscar dados do jogador:', error);
        message.error('Erro ao buscar dados do jogador.');
        setLoading(false);
      }
    } else {
      navigate('/auth');
    }
  }, [auth, navigate]);

  useEffect(() => {
    fetchPlayerData();
    const interval = setInterval(() => {
      fetchPlayerData();
    }, 60000); // 1 minuto
    return () => clearInterval(interval);
  }, [fetchPlayerData]);

  // Função para obter a percentagem de progresso
  const getProgressForMetric = (current, required) => {
    if (required === 0) return 100; // Evita divisão por zero
    return Math.min(100, Math.ceil((current / required) * 100));
  };

  // Função para renderizar cada métrica
  const renderMetricCard = (icon, title, current, required, suffix) => (
    <AnimatedCard
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}
    >
      <Card>
        <Space direction="horizontal" size="middle">
          {icon}
          <div>
            <Text strong style={{ fontSize: '16px', color: '#333333' }}>
              {title}
            </Text>
            <Progress
              percent={getProgressForMetric(current, required)}
              status="active"
              strokeColor="#1890ff"
              showInfo={false}
              style={{ marginTop: '10px' }}
            />
            <Statistic
              value={Math.ceil(current || 0)} // Arredonda para cima
              suffix={` / ${required}${suffix}`}
              valueStyle={{ fontSize: '14px', color: '#555555' }}
            />
          </div>
        </Space>
      </Card>
    </AnimatedCard>
  );

  // Função para renderizar todas as métricas
  const renderAllMetrics = () => {
    if (!playerData || !playerData.level) return null;

    const currentLevel = playerData.level;
    const nextLevelRule = levelRules.find((rule) => rule.level === currentLevel + 1);

    if (!nextLevelRule) {
      return (
        <AnimatedCard
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <Card>
            <Title level={4} style={{ color: '#1890ff' }}>
              Parabéns! Você atingiu o nível máximo.
            </Title>
          </Card>
        </AnimatedCard>
      );
    }

    const metrics = [
      {
        icon: <ShoppingCartOutlined style={{ fontSize: '24px', color: '#1890ff' }} />,
        title: 'Total de Vendas',
        current: playerData.metrics?.totalSales || 0,
        required: nextLevelRule.salesRequired,
        suffix: ' vendas',
      },
      {
        icon: <DollarOutlined style={{ fontSize: '24px', color: '#52c41a' }} />,
        title: 'Receita Total',
        current: playerData.metrics?.totalRevenue || 0,
        required: nextLevelRule.revenueRequired,
        suffix: ' R$',
      },
      {
        icon: <LineChartOutlined style={{ fontSize: '24px', color: '#faad14' }} />,
        title: 'Lucro Total',
        current: playerData.metrics?.totalProfit || 0,
        required: nextLevelRule.totalProfitRequired,
        suffix: ' R$',
      },
    ];

    return (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {},
          visible: {
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
      >
        <Row gutter={[24, 24]}>
          {metrics.map((metric, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
              {renderMetricCard(
                metric.icon,
                metric.title,
                metric.current,
                metric.required,
                metric.suffix
              )}
            </Col>
          ))}
        </Row>
      </motion.div>
    );
  };

  // Função para verificar e atualizar o nível do jogador e conceder recompensas
  const checkAndUpdateLevel = async () => {
    if (!playerData || !playerData.level) return;

    const currentLevel = playerData.level;
    const nextLevelRule = levelRules.find((rule) => rule.level === currentLevel + 1);

    if (!nextLevelRule) return; // Jogador está no nível máximo

    const metrics = playerData.metrics || {};

    const salesProgress = metrics.totalSales >= nextLevelRule.salesRequired;
    const profitProgress = metrics.totalProfit >= nextLevelRule.totalProfitRequired;
    const revenueProgress = metrics.totalRevenue >= nextLevelRule.revenueRequired;

    // Verifica se o jogador atingiu todos os requisitos
    if (salesProgress && profitProgress && revenueProgress) {
      try {
        // Atualiza o nível do jogador no Firestore
        const user = auth.currentUser;
        if (!user) {
          message.error('Usuário não autenticado');
          return;
        }
        const playerRef = doc(firestore, 'players', user.uid);

        // Adiciona a recompensa monetária ao saldo do jogador, arredondando para cima
        const rewardAmount = monetaryRewards[currentLevel + 1] || 0;
        const newBalance = Math.ceil((playerData.balance || 0) + rewardAmount);

        await setDoc(
          playerRef,
          {
            level: currentLevel + 1,
            balance: newBalance, // Saldo arredondado para cima
          },
          { merge: true }
        );

        // Atualiza o estado local para refletir o novo nível e saldo
        setPlayerData((prevData) => ({
          ...prevData,
          level: currentLevel + 1,
          balance: newBalance,
        }));

        message.success(
          `Parabéns! Você atingiu o nível ${currentLevel + 1} e recebeu R$${rewardAmount
            .toLocaleString('pt-BR')
            .replace(',', '.')}`
        );
      } catch (error) {
        message.error('Erro ao atualizar o nível do jogador');
        console.error('Erro ao atualizar o nível do jogador:', error);
      }
    }
  };

  useEffect(() => {
    if (playerData) {
      checkAndUpdateLevel(); // Verifica e atualiza o nível
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  // Função para renderizar as recompensas
  const renderRewards = () => {
    if (!playerData || !playerData.level) return null;

    const currentLevel = playerData.level;
    const nextLevelRule = levelRules.find((rule) => rule.level === currentLevel + 1);
    const rewards = monetaryRewards[currentLevel + 1];

    if (!rewards) return null; // Sem prêmios para este nível

    return (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Title level={4} style={{ color: '#080808' }}>
            Recompensa do Próximo Nível
          </Title>
        </Col>
        <Col xs={24}>
          <RewardCard>
            <Space direction="horizontal" size="middle">
              <GiftOutlined style={{ fontSize: '24px', color: '#faad14' }} />
              <div>
                <Text>
                  <strong>Recompensa:</strong> R$
                  {Math.ceil(rewards).toLocaleString('pt-BR')}
                </Text>
              </div>
            </Space>
          </RewardCard>
        </Col>
      </Row>
    );
  };

  // Função para renderizar o conteúdo principal
  const renderContent = () => {
    if (loading) {
      return (
        <Spin tip="Carregando dados..." size="large">
          <div style={{ minHeight: '200px' }} />
        </Spin>
      );
    }

    if (!playerData) {
      return (
        <Alert
          message="Erro"
          description="Dados do jogador não disponíveis."
          type="error"
          showIcon
        />
      );
    }

    return (
      <>
        {renderRewards()}
        <Title level={4} style={{ color: '#080808', marginBottom: '20px' }}>
          Progresso para o Próximo Nível
        </Title>
        {renderAllMetrics()}
      </>
    );
  };

  return (
    <Container>
      {renderContent()}
    </Container>
  );
};

export default AchievementsScreen;
