// PlayerRankingScreen.jsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  Layout,
  Table,
  Typography,
  message,
  Avatar,
  Menu,
  Button,
  Drawer,
  Grid,
} from 'antd';
import {
  CarOutlined,
  ShopOutlined,
  UserOutlined,
  CrownOutlined,
  DollarOutlined,
  MenuOutlined,
  GoldOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from './firebaseConfig';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const PlayerRankingScreen = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  // Carrega os dados de todos os jogadores, filtra somente grupo >= 300 e ordena por totalSales
  const fetchPlayers = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) {
      message.error('Usuário não autenticado.');
      setLoading(false);
      return;
    }

    setCurrentUserId(user.uid);

    try {
      const snapshot = await getDocs(collection(firestore, 'players'));
      const allData = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          key: doc.id,
          id: doc.id,
          name: data.name || 'Jogador Desconhecido',
          avatarUrl: data.avatarUrl || null,
          group: data.group || 1,
          totalSales: data.metrics?.totalSales ?? 0,
          totalProfit: data.metrics?.totalProfit ?? 0,
        };
      });

      // Filtrar apenas jogadores com group >= 300
      const filtered = allData.filter(player => player.group >= 2000);
      // Ordenar por totalSales (decrescente)
      filtered.sort((a, b) => b.totalSales - a.totalSales);

      setPlayers(filtered);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao carregar jogadores:', error);
      message.error('Erro ao carregar dados dos jogadores.');
      setLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchPlayers();
  }, [fetchPlayers]);

  // Renderização do menu
  const renderMenuItems = () => (
    <>
      <Menu.Item key="1" icon={<DollarOutlined style={{ fontSize: '18px' }} />}>
        <Link to="/dashboard">Painel</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<ShopOutlined style={{ fontSize: '18px' }} />}>
        <Link to="/showroom">Minha loja</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<CarOutlined style={{ fontSize: '18px' }} />}>
        <Link to="/market">Mercado</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<UserOutlined style={{ fontSize: '18px' }} />}>
        <Link to="/colecaopessoal">Coleção</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<CrownOutlined style={{ fontSize: '18px' }} />}>
        <Link to="/ranking">Rank</Link>
      </Menu.Item>
    </>
  );

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  // Definição das colunas da tabela
  const columns = [
    {
      title: 'Posição',
      dataIndex: 'position',
      key: 'position',
      render: (text, record, index) => (
        <Text
          strong
          style={{
            color: record.id === currentUserId ? '#1890ff' : 'inherit',
          }}
        >
          {index + 1}
        </Text>
      ),
      width: 100,
      align: 'center',
    },
    {
      title: 'Jogador',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={record.avatarUrl}
            icon={!record.avatarUrl && <UserOutlined />}
            style={{ marginRight: '10px' }}
          />
          {record.group === 2 && <CrownOutlined style={{ color: '#fadb14', marginRight: '5px' }} />}
          <Text
            strong
            style={{
              color: record.id === currentUserId ? '#1890ff' : 'inherit',
            }}
          >
            {text}
          </Text>
        </div>
      ),
      width: 250,
    },
    {
      title: 'Total de Vendas',
      dataIndex: 'totalSales',
      key: 'totalSales',
      sorter: (a, b) => a.totalSales - b.totalSales,
      align: 'right',
      width: 200,
    },
    {
      title: 'Lucro Total (R$)',
      dataIndex: 'totalProfit',
      key: 'totalProfit',
      sorter: (a, b) => a.totalProfit - b.totalProfit,
      render: (value) => (
        <Text>{value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
      ),
      align: 'right',
      width: 200,
    }
  ];

  // DataSource com position recalculada após filtragem e ordenação
  const dataSource = players.map((player, index) => ({
    ...player,
    position: index + 1,
  }));

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      {/* Topbar */}
      <Header
        style={{
          background: '#080808',
          padding: '0 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* Seção da Esquerda: Logo */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaElements%2Fnewlogo%2Fl3.png?alt=media&token=9ed9f4be-cb74-4725-9c29-3bbe998268fb"
              alt="Logo"
              style={{ height: '40px', marginRight: '20px', maxWidth: '100%' }}
            />
            <Button
              type="text"
              icon={<GoldOutlined style={{ fontSize: '24px', color: '#ffcf40' }} />}
              onClick={() => navigate('/shop')}
              title="Upgrade"
              style={{ color: '#fff', marginLeft: '16px' }}
            >
              Upgrades
            </Button>
          </div>
        </div>

        {screens.md ? (
          <Menu theme="dark" mode="horizontal" style={{ background: '#080808', lineHeight: '64px' }}>
            {renderMenuItems()}
          </Menu>
        ) : (
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '24px', color: '#fff' }} />}
            onClick={showDrawer}
            className="drawer-toggle-button"
          />
        )}
      </Header>

      <Drawer
        title="Menu de Navegação"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        className="mobile-menu-drawer"
      >
        <Menu mode="vertical">
          {renderMenuItems()}
        </Menu>
      </Drawer>

      <Layout>
        <Content
          style={{
            margin: '24px 16px 0',
            padding: 24,
            background: '#f0f2f5',
            minHeight: 360,
            boxSizing: 'border-box',
          }}
        >
          <Title level={3}>Ranking de Jogadores (Grupo 300+)</Title>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 10 }}
            loading={loading}
            bordered
            scroll={{ x: 'max-content' }}
            locale={{
              emptyText: 'Nenhum dado disponível.',
            }}
            rowClassName={(record) => (record.id === currentUserId ? 'highlight-row' : '')}
          />
        </Content>
      </Layout>

      <style>
        {`
          .highlight-row {
            background-color: #e6f7ff !important;
          }
        `}
      </style>
    </Layout>
  );
};

export default PlayerRankingScreen;
