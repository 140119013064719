// AdminScreen.js

import React, { useState, useEffect } from 'react';
import {
  Layout,
  Table,
  Button,
  message,
  Typography,
  Row,
  Col,
  Spin,
} from 'antd';
import { getAuth, signOut } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined, WhatsAppOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const { Title } = Typography;

const AdminScreen = () => {
  const [activePlayers, setActivePlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const listenToPlayers = () => {
      const playersCollection = collection(firestore, 'players');
      const q = query(playersCollection, where('group', '==', 2000));

      const unsubscribePlayers = onSnapshot(
        q,
        (querySnapshot) => {
          const active = [];
          querySnapshot.forEach((docSnap) => {
            const player = { id: docSnap.id, ...docSnap.data() };
            active.push(player);
          });

          setActivePlayers(active);
          setLoading(false);
        },
        (err) => {
          console.error('Erro ao escutar jogadores:', err);
          message.error('Ocorreu um erro ao carregar os jogadores. Tente novamente mais tarde.');
          setError(true);
          setLoading(false);
        }
      );

      return unsubscribePlayers;
    };

    const unsubscribePlayers = listenToPlayers();

    return () => {
      unsubscribePlayers && unsubscribePlayers();
    };
  }, []);

  const markAsAttended = async (playerId) => {
    try {
      const playerDocRef = doc(firestore, 'players', playerId);
      await updateDoc(playerDocRef, {
        atendido: true,
      });
      message.success('Jogador marcado como atendido.');
    } catch (err) {
      console.error('Erro ao marcar jogador como atendido:', err);
      message.error('Erro ao marcar jogador como atendido. Tente novamente.');
    }
  };

  const openWhatsApp = (number) => {
    if (!number) {
      message.error('Número de WhatsApp não disponível.');
      return;
    }
    const cleanedNumber = number.replace(/\D/g, '');
    const whatsappLink = `https://wa.me/55${cleanedNumber}`;
    window.open(whatsappLink, '_blank');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      message.success('Desconectado com sucesso!');
      navigate('/auth');
    } catch (err) {
      console.error('Erro ao desconectar:', err);
      message.error('Erro ao desconectar. Tente novamente.');
    }
  };

  const activeColumns = [
    {
      title: 'Nome do Jogador',
      dataIndex: 'nameplayer',
      key: 'nameplayer',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'WhatsApp',
      key: 'whatsapp',
      render: (_, record) => (
        <Button
          type="primary"
          icon={<WhatsAppOutlined />}
          onClick={() => openWhatsApp(record.whatsapp)}
          style={{ backgroundColor: '#25D366', borderColor: '#25D366' }}
        >
          Chat
        </Button>
      ),
    },
    {
      title: 'Atender',
      key: 'atender',
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => markAsAttended(record.id)}
        >
          Atendido
        </Button>
      ),
    },
  ];

  const renderActiveTable = () => (
    <Table
      columns={activeColumns}
      dataSource={activePlayers}
      rowKey="id"
      loading={loading}
      pagination={{ pageSize: 10 }}
      bordered
      style={{ backgroundColor: '#fff', borderRadius: '8px' }}
      title={() => <Title level={5}>Jogadores do Grupo 2000</Title>}
    />
  );

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <Header
        style={{
          backgroundColor: '#fff',
          padding: '0 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0 2px 8px #f0f1f2',
        }}
      >
        <Title level={3} style={{ margin: 0 }}>
          Dashboard Admin
        </Title>
        <Button
          type="text"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          style={{ fontSize: '16px' }}
        >
          Logout
        </Button>
      </Header>

      <Content style={{ margin: '24px', overflow: 'auto' }}>
        {loading ? (
          <Spin size="large" style={{ display: 'block', margin: '100px auto' }} />
        ) : error ? (
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography.Text type="danger">
              Ocorreu um erro ao carregar os jogadores. Tente novamente mais tarde.
            </Typography.Text>
          </div>
        ) : (
          <Row justify="center" style={{ marginBottom: '24px' }}>
            <Col xs={24} sm={24} md={22} lg={20} xl={18}>
              {activePlayers.length > 0 ? (
                renderActiveTable()
              ) : (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <Typography.Text>
                    Nenhum jogador ativo encontrado no grupo 2000.
                  </Typography.Text>
                </div>
              )}
            </Col>
          </Row>
        )}
      </Content>
    </Layout>
  );
};

export default AdminScreen;
