// src/services/applySubscriptionBenefits.js

import { firestore } from './firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

/**
 * Função para aplicar benefícios de assinatura mensalmente ao jogador.
 * @param {string} playerId - UID do jogador no Firestore.
 */
const applySubscriptionBenefits = async (playerId) => {
  console.log(`applySubscriptionBenefits chamada para playerId: ${playerId}`); // Log Inicial

  try {
    // Referência ao documento do jogador usando o UID
    const playerDocRef = doc(firestore, 'players', playerId);
    const playerSnapshot = await getDoc(playerDocRef);

    if (!playerSnapshot.exists()) {
      console.error('Jogador não encontrado.');
      return;
    }

    const playerData = playerSnapshot.data();

    // Log para depuração: Exibir a estrutura do campo subscription
    console.log('Dados de Subscription:', playerData.subscription);

    // Verifica se a assinatura está ativa
    const isSubscriptionActive =
      playerData.subscription && playerData.subscription.active === true;

    if (!isSubscriptionActive) {
      console.log('A assinatura do jogador não está ativa.');
      return;
    }

    // Obtém a data atual e ajusta para o fuso horário de São Paulo (UTC-3)
    const currentDate = new Date();
    const saoPauloOffset = -3; // São Paulo está em UTC-3
    const saoPauloDate = new Date(
      currentDate.getTime() + saoPauloOffset * 60 * 60 * 1000
    );

    const lastBenefitsDateStr = playerData.subscription.benefits?.lastBenefitsDate;
    const lastBenefitsDate = lastBenefitsDateStr
      ? new Date(lastBenefitsDateStr)
      : null;

    // Validação da data da última entrega de benefícios
    const currentMonth = saoPauloDate.getMonth();
    const currentYear = saoPauloDate.getFullYear();
    const lastBenefitsMonth = lastBenefitsDate
      ? lastBenefitsDate.getMonth()
      : -1;
    const lastBenefitsYear = lastBenefitsDate
      ? lastBenefitsDate.getFullYear()
      : -1;

    const hasReceivedBenefitsThisMonth =
      lastBenefitsMonth === currentMonth &&
      lastBenefitsYear === currentYear;

    if (hasReceivedBenefitsThisMonth) {
      console.log('Os benefícios já foram entregues este mês.');
      return;
    }

    // Determina o tipo de assinatura
    const subscriptionType = playerData.subscription.benefits?.type;
    if (!subscriptionType || subscriptionType === 'None') {
      console.log(
        'Tipo de assinatura inválido ou inexistente: Nenhum benefício a ser aplicado.'
      );
      return;
    }

    // Obtém os benefícios predefinidos com base no tipo de assinatura
    const presetBenefits =
      playerData.subscription.presetBenefits[subscriptionType];
    if (!presetBenefits) {
      console.error(
        `Benefícios predefinidos não encontrados para o tipo: ${subscriptionType}`
      );
      return;
    }

    // Calcula os novos valores com base nos benefícios predefinidos
    const newBalance = playerData.balance + presetBenefits.balanceBonus;
    const newSlots = playerData.slots + presetBenefits.extraSlots;
    const newProfit = presetBenefits.profit; // Atualiza diretamente o campo 'profit'
    const newDiscount =
      'discount' in presetBenefits
        ? presetBenefits.discount
        : playerData.discount; // Mantém o valor atual se não houver desconto
    const newDropAmount =
      'dropAmount' in presetBenefits
        ? presetBenefits.dropAmount
        : playerData.dropAmount; // Mantém o valor atual se não houver dropAmount

    // Prepara os campos a serem atualizados no Firestore
    const updateFields = {
      balance: newBalance,
      slots: newSlots,
      profit: newProfit, // Atualiza diretamente o campo 'profit'
      discount: newDiscount,
      dropAmount: newDropAmount,
      'subscription.benefits.lastBenefitsDate': saoPauloDate.toISOString(),
    };

    // Adiciona campos adicionais se existirem no presetBenefits
    if ('accessLuxuryClub' in presetBenefits) {
      updateFields.accessLuxuryClub = presetBenefits.accessLuxuryClub;
    }
    if ('earlyAccess' in presetBenefits) {
      updateFields.earlyAccess = presetBenefits.earlyAccess;
    }
    if ('supportPriority' in presetBenefits) {
      updateFields.supportPriority = presetBenefits.supportPriority;
    }

    // Atualiza o documento do jogador no Firestore
    await updateDoc(playerDocRef, updateFields);

    console.log('Benefícios aplicados com sucesso!');
  } catch (error) {
    console.error('Erro ao aplicar os benefícios da assinatura:', error);
  }
};

export default applySubscriptionBenefits;
