// CarCarousel.js
import React, { useState } from 'react';
import { Modal, Button, Typography, Row, Col, Card, Badge, Grid } from 'antd';
import { Link } from 'react-router-dom';

const { Paragraph, Link: AntLink, Title } = Typography;
const { useBreakpoint } = Grid;

const CarCarousel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const screens = useBreakpoint(); // Hook para detectar o tamanho da tela

  // Array interno de dados dos carros
  const cars = [
    {
      id: '1',
      title: 'Porsche 918 Spyder',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2FPorsche%20918%2F2015-Porsche-918-Spyder-005-1440.jpg?alt=media&token=1258fd3a-0e56-4708-ac32-968fd841e92b',
      realPrice: 9.90,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      
    },
    {
      id: '2',
      title: 'Nissan GTR R34',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F1999-Nissan-Skyline-GT-R-V-spec-001-1080.jpg?alt=media&token=7e04efac-28f6-48c9-8aac-7c5234f2b45c',
      realPrice: 3.50,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      
    },
    {
      id: '3',
      title: 'GTR R34, Supra, ',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F1999-Nissan-Skyline-GT-R-V-spec-001-1080.jpg?alt=media&token=7e04efac-28f6-48c9-8aac-7c5234f2b45c',
      realPrice: 9.90,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      
    },
    {
      id: '4',
      title: 'Polestar 1',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/MediaDLC%2F2020-Polestar-1-001-1080.jpg?alt=media&token=3be132a7-5da3-45f8-ae76-1c0797149827',
      realPrice: 3,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      
    },
    {
      id: '6',
      title: 'Frota de Uno da Firma',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/grandgarage-52123.appspot.com/o/Media%2F9p0cb096f8daz3g8gc6dbeye6.jpg?alt=media&token=949ef210-7128-4831-a8de-84cb100efb74',
      realPrice: 5,
      pixKey: 'kevnmotors@gmail.com',
      whatsappNumber: '+5511912192520',
      
    },
  ];

  // Função para lidar com o clique no card
  const handleCardClick = (car) => {
    setSelectedCar(car);
    setIsModalVisible(true);
  };

  // Função para fechar o modal
  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedCar(null);
  };

  // Função para definir a cor da badge
  const getBadgeColor = (unitsLeft) => {
    // Lógica condicional para definir a cor padrão
    if (unitsLeft <= 1) return '#FF4D4F'; // Vermelho
    if (unitsLeft <= 3) return '#1890FF'; // Azul
    return '#52C41A'; // Verde
  };

  // Função para renderizar os itens do grid de acordo com o tamanho da tela
  const getColProps = () => {
    if (screens.xl) {
      return { xs: 24, sm: 12, md: 8, lg: 6, xl: 4 };
    } else if (screens.lg) {
      return { xs: 24, sm: 12, md: 8, lg: 6 };
    } else if (screens.md) {
      return { xs: 24, sm: 12, md: 8 };
    } else if (screens.sm) {
      return { xs: 24, sm: 12 };
    } else {
      return { xs: 24 };
    }
  };

  return (
    <div style={{ padding: '20px', boxSizing: 'border-box', width: '100%' }}>
      {/* Título Responsivo */}
      <Title
        level={screens.xs ? 4 : 3}
        style={{
          textAlign: 'left',
          marginBottom: '20px',
          color: '#333333',
          fontSize: screens.xs ? '1.2em' : '1.5em',
        }}
      >
        Para colecionadores
      </Title>
      
      {/* Grid Responsivo de Carros */}
      <Row gutter={[16, 16]}>
        {cars.map((car) => (
          <Col
            key={car.id}
            {...getColProps()}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Badge
              
              style={{
                backgroundColor: getBadgeColor(car.unitsLeft),
                color: '#fff',
                fontWeight: 'bold',
                position: 'absolute',
                borderRadius: '2px',
                right: '5px',
                top: '5px',
                zIndex: 1,
                padding: '0 4px',
              }}
            >
              <Card
                hoverable
                cover={
                  <img
                    alt={car.title}
                    src={car.imageUrl}
                    style={{
                      height: screens.xs ? '150px' : '200px',
                      objectFit: 'cover',
                      width: '100%',
                    }}
                  />
                }
                onClick={() => handleCardClick(car)}
                style={{
                  width: '100%',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  position: 'relative', // Necessário para a badge ficar em cima
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                }}
              >
                <Card.Meta
                  title={
                    <Title
                      level={screens.xs ? 5 : 4}
                      style={{ margin: 0, color: '#333333', fontSize: screens.xs ? '1em' : '1.2em' }}
                    >
                      {car.title}
                    </Title>
                  }
                  description={
                    <div>
                      <Typography.Text
                        strong
                        style={{ color: '#333333', fontSize: screens.xs ? '1em' : '1.2em' }}
                      >
                       
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ color: '#777777', fontSize: screens.xs ? '0.9em' : '1em' }}>
                        PIX: R$ {car.realPrice.toLocaleString('pt-BR')}
                      </Typography.Text>
                    </div>
                  }
                />
              </Card>
            </Badge>
          </Col>
        ))}
      </Row>

      {/* Modal Informativo Responsivo */}
      <Modal
        title="Informações de Compra"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="fechar" type="primary" onClick={handleModalClose}>
            Fechar
          </Button>,
        ]}
        centered
        width={screens.xs ? '90%' : '600px'} // Ajuste de largura baseado no tamanho da tela
      >
        {selectedCar && (
          <div>
            <Paragraph>
              O sistema de compras automáticas está em desenvolvimento. Para comprar este carro, faça o PIX para{' '}
              <strong>{selectedCar.pixKey || 'SUA_CHAVE_PIX_AQUI'}</strong> e envie o comprovante para o{' '}
              <AntLink
                href={`https://wa.me/${selectedCar.whatsappNumber || 'SEU_NUMERO_WHATSAPP_AQUI'}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </AntLink>.
            </Paragraph>
            <Paragraph>
              <AntLink
                href={`https://wa.me/${selectedCar.whatsappNumber || 'SEU_NUMERO_WHATSAPP_AQUI'}?text=Olá,%20gostaria%20de%20comprar%20o%20carro%20${encodeURIComponent(
                  selectedCar.title || 'Selecionado'
                )}.`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui para enviar o comprovante.
              </AntLink>
            </Paragraph>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CarCarousel;
